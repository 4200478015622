import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateReport } from '../../../redux/actions/admin/reports';
import { getStudents } from '../../../redux/actions/admin/student';
import { getBatches } from '../../../redux/actions/admin/batch';
import { getCourses } from '../../../redux/actions/admin/course';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Form from "../../../components/form";

const ReportCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    type: '',
    dateRange: { from: '', to: '' },
    attendanceType: '',
    courseId: '',
    batchId: '',
    studentId: ''
  });

  // Fetch initial data
  useEffect(() => {
    dispatch(getStudents({ limit: 500 }));
    dispatch(getCourses({ limit: 50 }));
  }, [dispatch]);

  // Fetch batches when course is selected
  useEffect(() => {
    if (formData.courseId) {
      dispatch(getBatches({ courseId: formData.courseId, limit: 50 }));
    }
  }, [formData.courseId, dispatch]);

  // Get data from store
  const students = useSelector((state) => state.student.value.data);
  const courses = useSelector((state) => state.course.value.data);
  const batches = useSelector((state) => state.batch.value.data);

  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  });

  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  });

  const fields = [
    {
      id: "type",
      name: "type",
      label: "Report Type",
      type: "dropdown",
      placeHolder: "Select Report Type",
      list: [
        { id: 'attendance', name: 'Attendance' },
        { id: 'academics', name: 'Academics' },
        { id: 'overall', name: 'Overall' }
      ],
      toSelect: "id"
    },
    // Only show these fields if type is attendance
    ...(formData.type === 'attendance' ? [
      {
        id: "dateRange",
        name: "dateRange",
        label: "Date Range",
        type: "daterange"
      },
      {
        id: "attendanceType",
        name: "attendanceType",
        label: "Attendance Type",
        type: "dropdown",
        placeHolder: "Select Type",
        list: [
          { id: 'course', name: 'Course Wise' },
          { id: 'student', name: 'Student Wise' }
        ],
        toSelect: "id"
      },
      // Show course and batch dropdowns if course wise is selected
      ...(formData.attendanceType === 'course' ? [
        {
          id: "courseId",
          name: "courseId",
          label: "Course",
          placeHolder: courses.rows?.length ? "Select Course" : "No courses available",
          type: "dropdown",
          list: courses.rows || [],
          toSelect: "id",
          selectedvalue: "course",
          required: true,
          disabled: !courses.rows?.length
        },
        // Only show batch dropdown if course is selected
        ...(formData.courseId ? [{
          id: "batchId",
          name: "batchId",
          label: "Batch",
          placeHolder: batches.rows?.length ? "Select Batch" : "No batches available",
          type: "dropdown",
          list: batches.rows || [],
          toSelect: "id",
          selectedvalue: "batch",
          disabled: !batches.rows?.length
        }] : [])
      ] : []),
      // Show students dropdown only if student wise is selected
      ...(formData.attendanceType === 'student' ? [{
        id: "studentId",
        name: "studentId",
        label: "Student",
        type: "dropdown",
        placeHolder: "Select Student",
        list: students?.rows || [],
        toSelect: "id",
        selectedvalue: "student",
        toShow: ['admission', 'name']
      }] : [])
    ] : [])
  ];

  const handleSubmit = async () => {
    try {
      await dispatch(generateReport(formData));
      notifySuccess("Report generation started");
      // navigate('/admin/reports');
    } catch (error) {
      notifyError(error.message || "Failed to generate report");
    }
  };

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
          <h2 className="text-2xl font-bold mb-6">Generate Report</h2>
          
          <Form
            fields={fields}
            formData={formData}
            onChange={(newFormData) => setFormData(newFormData)}
          />

          <div className='flex justify-end mt-6'>
            <button
              onClick={() => navigate('/admin/reports')}
              className="mr-4 border-2 text-brand-500 linear rounded-full bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-gray-100"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70"
            >
              Generate Report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCreate; 