import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getExams } from '../../../../redux/actions/admin/exam';
import { getExamMasters } from '../../../../redux/actions/admin/exammaster';
import { getClasses, getStudentsForClass } from "../../../../redux/actions/admin/classRoom";

import { getResults, addResult, updateResult, deleteResult } from '../../../../redux/actions/admin/result';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';

// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getStudents } from '../../../../redux/actions/admin/student';
import * as XLSX from "xlsx";

import {
    FiTrendingUp,
    FiX,
    FiLayers,
    FiSearch,
    FiUnlock,
    FiEdit2,
    FiTrash,
    FiSettings,
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/shadcn/table";

import DialogCustom from "../../../../components/dialog";
import Form from "../../../../components/form";

export const Result = (props) => {
    // const history = useHistory();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({});
    const [importFormData, setImportFormData] = useState({});
    const [publishFieldsFormData, setPublishFieldsFormData] = useState({ classes: [] });
    const [totalStudents, setTotalStudents] = useState(0);
    // const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
    const [isPublishDialogOpen, setIsPublishDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExams({ limit: 250 }))
        dispatch(getStudents({ limit: 500 }))
        dispatch(getResults({}))
        dispatch(getExamMasters({ limit: 250 }))
        dispatch(getClasses({ limit: 250 }))
        // setFormData({})
    }, [dispatch])



    const results = useSelector((results) => results.result.value.data);
    const exams = useSelector((resultes) => resultes.exam.value.data);
    const students = useSelector((students) => students.student.value.data);
    const exammasters = useSelector((exammasters) => exammasters.exammaster.value.data);
    const classes = useSelector((classes) => classes.classRoom.value.data);

    const [file, setFile] = useState(null);
    const [importedResults, setImportedResults] = useState([])
    const [jsonData, setJsonData] = useState("");

    const handleDownload = () => {
        // Define column headers for the Excel template
        const data = [
            { registerNumber: "", TotalScore: "", ObtainedScore: "" } // Empty values for the template
        ];

        // Convert the JSON data to a worksheet (just headers, no data)
        const worksheet = XLSX.utils.json_to_sheet(data, { skipHeader: false });

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

        // Generate XLSX file and trigger download
        XLSX.writeFile(workbook, "Template.xlsx");
    };

    const handleConvert = () => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                setJsonData(() => {
                    //   console.log("Converted JSON data ", JSON.stringify(json, null, 2));
                    setImportedResults(JSON.parse(JSON.stringify(json, null, 2)))
                    return JSON.stringify(json, null, 2);
                });
            };
            reader.readAsBinaryString(file);
        }
    };

    // const handleFileUpload = (e) => {
    //     console.log("uploaded file")
    //     setFile(e.target.files[0]);
    // };

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    };

    useEffect(() => {
        if (file) {
            const reader = new FileReader();

            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(sheet);

                // Verify the columns
                const necessaryColumns = ['registerNumber', 'TotalScore', 'ObtainedScore'];
                const importedColumns = Object.keys(json[0]);
                const isValid = necessaryColumns.every(column => importedColumns.includes(column));

                if (isValid) {
                    notifySuccess("Imported successfully");
                    handleConvert();
                } else {
                    notifyError("Columns mismatch");
                    setFile(null);
                }
            };

            reader.readAsArrayBuffer(file);
        }
    }, [file]);

    const handleActionButtonClick = (resultId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (resultId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }
        if (action == "import") {
            setIsImportDialogOpen(true)
            return 0
        }
        if (action == "publish") {
            setIsPublishDialogOpen(true)
            return 0
        }

        const selectedResult = results.rows.find((result) => result.id === resultId);
        setFormData(selectedResult)
    };

    useEffect(() => {
        dispatch(getResults(filter));
    }, [filter]);

    useEffect(() => {
        publishFieldsFormData.classes.forEach(classId => {
            dispatch(getStudentsForClass(classId.value))
            console.log(classes, "classStudents")
        });
    }, [publishFieldsFormData.classes]);

    const handleCrudResult = async () => {
        try {
            setIsDialogOpen(false)
            setIsImportDialogOpen(false)
            if (formData.id) await dispatch(updateResult(formData));
            if (!formData.id) await dispatch(addResult(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getResults({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const importResults = async () => {
        try {
            // console.log("-------------")
            // console.log("will import ", importedResults.length)
            // console.log("for exam ", importFormData)
            await dispatch(addResult({
                isBulk: true,
                results: importedResults,
                examId: importFormData.examId
            }));

            setFile(null)
            setImportFormData({})
            notifySuccess("Success")

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteResult(formData.id));
            // setFormData({});
            notifySuccess("Delete result successfully")
            await dispatch(getResults({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const importFields = [
        {
            id: "examId",
            name: "examId",
            label: "Exam",
            placeHolder: "Select Exam",
            type: "dropdown",
            list: exams.rows,
            toSelect: "id",
            selectedvalue: "exam",
        }
    ];

    const publishResultFields = [
        {
            id: "examMasterId",
            name: "examMasterId",
            label: "Exam Master",
            placeHolder: "Select Exam Master",
            type: "dropdown",
            list: exammasters.rows,
            toSelect: "id",
            selectedvalue: "exammaster",
        },
        {
            id: "classes",
            name: "classes",
            label: "Select Classes",
            type: "multiselect",
            toSelect: ["id"],
            options: classes.rows,
        },
    ];

    const fields = [
        {
            id: "studentId",
            name: "studentId",
            label: "Student",
            placeHolder: "Select Student",
            type: "dropdown",
            list: students.rows,
            toSelect: "id",
            selectedvalue: "student",
            toShow: ['admission', 'name']
        },
        {
            id: "examId",
            name: "examId",
            label: "Exam",
            placeHolder: "Select Exam",
            type: "dropdown",
            list: exams.rows,
            toSelect: "id",
            selectedvalue: "exam",
        },
        {
            id: "remarks",
            name: "remarks",
            label: "Remarks",
            placeholder: "Test Remarks",
            type: "text",
        },
        {
            id: "obtainedScore",
            name: "obtainedScore",
            label: "Obtained Score",
            placeholder: "Enter Obtained Score",
            type: "number",
        }
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={results.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={results.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={results.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>


                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between items-center'>
                                    {/* <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium  text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div> */}
                                    <DropdownSelect
                                        list={[
                                            { id: "all", name: "All" },
                                            ...exams.rows,
                                        ]}
                                        triggerStyles="bg-lightPrimary !rounded-full !w-[200px]"
                                        // listStyles="text-sm text-red-700"
                                        placeHolder={"Filter by Exam"}
                                        toSelect={"id"}
                                        onChange={(value) => {
                                            if (value == "All") {
                                                setFilter({ ...filter, ["examId"]: null })
                                                return
                                            }
                                            setFilter({ ...filter, ["examId"]: value })
                                        }}
                                    />
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick("", "publish")}
                                        className="mr-4 border-2 text-white linear rounded-full bg-orange-500 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Publish Result
                                    </button>
                                    <button
                                        onClick={() => handleActionButtonClick("", "import")}
                                        className="mr-4 border-2 text-white linear rounded-full bg-yellow-500 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Import Results
                                    </button>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Result
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {results.rows.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-1/6"> Student </TableHead>
                                        <TableHead className="w-1/3">  Exam </TableHead>
                                        <TableHead className="w-1/5"> Marks </TableHead>
                                        <TableHead className="w-1/3"> Remarks </TableHead>
                                        <TableHead className="w-1/3 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {results.rows.map((result) => (
                                        <TableRow key={result.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {result?.student?.admission?.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {result?.exam?.name + " - " + result?.exam?.exammaster?.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {result.obtainedScore + "/" + result.exam.maxScore + ` | (${(result.obtainedScore / result.exam.maxScore) * 100}%)`} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <p>{result.remarks}</p>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => handleActionButtonClick(result.id, "edit")}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    {/* <div
                                                        onClick={() => {
                                                            navigate(`/admin/permission/${result.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiSettings className='h-4 w-4 text-white' />
                                                    </div> */}
                                                    <div
                                                        onClick={() => handleActionButtonClick(result.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={results.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {results.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Results Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Result
                            </button>
                        </div>
                    }

                    <DialogCustom

                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle={formData.id ? "Edit Result" : "Add" + " Result"}
                        dialogWidth="w-1/2"
                        dialogDesc={(formData.id ? "Update" : "Add") + " the necessary fields and save."}

                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudResult}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + " Result"}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Result"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this result ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                    <DialogCustom

                        open={isImportDialogOpen}
                        onOpenChange={setIsImportDialogOpen}
                        dialogTitle="Import Result"
                        dialogWidth="w-1/2"
                        dialogDesc="Bulk import results from an XLS file"
                        content={
                            <div className='flex flex-col'>
                                <Form
                                    fields={importFields}
                                    formData={importFormData}
                                    onChange={(newFormData) => setImportFormData(newFormData)}
                                />
                                <div className="relative">
                                    <input
                                        type="file"
                                        accept=".xls,.xlsx"
                                        onChange={handleFileUpload}
                                        className="appearance-none opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                    />
                                    <div className="border-2 text-white linear rounded-lg bg-brand-700 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Upload File
                                    </div>
                                    {
                                        file &&
                                        <p className='text-center mt-5'> {file?.name + " - " + importedResults.length + " results will be imported"}  </p>
                                    }
                                </div>
                                <div className='flex justify-between mt-5'>
                                    <button
                                        onClick={() => {
                                            setIsDeleteDialogOpen(false);
                                        }}
                                        className="mr-2 text-white linear rounded-full border-2 border-white bg-red-700 px-6 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                        Cancel
                                    </button>
                                    <div>
                                        <button
                                            onClick={handleDownload}
                                            className="mr-2 text-white linear rounded-full border-2 border-white bg-yellow-500 px-6 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                            Sample Sheet
                                        </button>
                                        <button
                                            onClick={() => {
                                                importResults()
                                            }}
                                            className="mr-2 text-white linear rounded-full border-2 border-white bg-brand-700 px-6 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                            Import
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                    <DialogCustom

                        open={isPublishDialogOpen}
                        onOpenChange={setIsPublishDialogOpen}
                        dialogTitle="Publish Result"
                        dialogWidth="w-1/2"
                        dialogDesc="Publish/Send Result though whatsapp/mobile app"
                        content={
                            <div className='flex flex-col'>
                                <Form
                                    fields={publishResultFields}
                                    formData={publishFieldsFormData}
                                    onChange={(newFormData) => setPublishFieldsFormData(newFormData)}
                                />
                                <div className='flex justify-between mt-5'>
                                    <button
                                        onClick={() => {
                                            setIsDeleteDialogOpen(false);
                                        }}
                                        className="mr-2 text-white linear rounded-full border-2 border-white bg-red-700 px-6 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                        Cancel
                                    </button>
                                    <div>
                                        <button
                                            onClick={handleDownload}
                                            className="mr-2 text-white linear rounded-full border-2 border-white bg-yellow-500 px-6 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                            Sample Sheet
                                        </button>
                                        <button
                                            onClick={() => {
                                                importResults()
                                            }}
                                            className="mr-2 text-white linear rounded-full border-2 border-white bg-brand-700 px-6 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                            Import
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Result