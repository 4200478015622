import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import InputField from "components/fields/InputField";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { checkEntity, login, requestOtp } from '../../../redux/actions/auth';
import { getOpenSetting } from '../../../redux/actions/admin/setting';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((auth) => auth.auth.value.isLoggedIn);
  const status = useSelector((auth) => auth.auth.value.status);
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [businessLogo, setBusinessLogo] = useState("");
  const [validation, setValidation] = useState({ valid: true, error: "" });
  const [institutionCode, setInstitutionCode] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const [useOtp, setUseOtp] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const instituteCode = searchParams.get("code");
  const [isMobile, setIsMobile] = useState(false); // New state to track if identifier is mobile number or username
  const [otpTimer, setOtpTimer] = useState(0);


  const handleInstitutionCodeSubmit = async () => {
    const action = await dispatch(checkEntity({ code: instituteCode || institutionCode }));
    if (action.payload.auth) {
      notifySuccess("Valid institute code");
    } else {
      notifyError("Invalid institute code");
    }

    const apicall = await dispatch(getOpenSetting(instituteCode || institutionCode));
    setBusinessLogo(apicall.payload.profile);
    if (action.payload.auth) {
      setValidation({ valid: true, error: "" });
      return setShowLogin(true);
    }
    notifyError(action.payload.message);
  };

  useEffect(() => {
    validate(identifier, password);
  }, [identifier, password]);

  const validate = (identifier, password) => {
    if (identifier === '' && password === '') {
      return;
    }

    const mobileNumberRegex = /^[0-9]{10}$/;
    const otpRegex = /^[0-9]{6}$/;
    const usernameRegex = /^[a-zA-Z0-9_-]{3,16}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;

    if (mobileNumberRegex.test(identifier)) {
      setUseOtp(true);
      setOtp("");
      setOtpRequested(false);
    } else if (usernameRegex.test(identifier)) {
      setUseOtp(false);
      // setOtp(identifier);
      // setOtpRequested(true);
    }
    // else if (!passwordRegex.test(password)) {
    //   setValidation({ valid: false, error: "Invalid password. Must contain at least one letter and one number, and be 8-20 characters long." });
    // } 
    else {
      setValidation({ valid: true, error: "" });
    }
  };


  const handleLogin = async (resendOTP) => {
    // if(resendOTP) setOtpRequested(false);
    if (!validation.valid) return notifyError(validation.error);
    console.log(otpRequested, "otpRequested")
    console.log(useOtp, "useOtp")
    console.log(resendOTP, "resendOTP")
    const resendPriority = resendOTP == true ? true : !otpRequested && resendOTP
    // return 0
    if (useOtp && resendOTP) {
      if (resendPriority) {
        console.log("will try to request OTP")
        const otpCall = await dispatch(requestOtp({ mobile: identifier, code: institutionCode }));
        if (otpCall.payload.auth) {
          setOtpRequested(true);
          setOtpTimer(30); // Set OTP timer to 30 seconds
          notifySuccess("OTP sent successfully!");
        } else {
          notifyError("Failed to send OTP. Please try again.");
        }
        return;
      } else {
        console.log("will try to login with OTP")
        const loginCall = await dispatch(login({ mobileNumber: identifier, otp, code: institutionCode }));
        if (!loginCall.payload.auth) {
          return notifyError(loginCall.payload.message);
        } else {
          notifySuccess(loginCall.payload.message);
          navigate(loginCall.payload.user.role.name === "Parent" ? "/parent/home" : "/admin/default");
        }
      }
    } else {
      const loginCall = await dispatch(login({ username: identifier, password, code: institutionCode }));
      if (!loginCall.payload.auth) {
        return notifyError(loginCall.payload.message);
      } else {
        notifySuccess(loginCall.payload.message);
        navigate(loginCall.payload.user.role.name === "Parent" ? "/parent/home" : "/admin/default");
      }
    }
  };

  useEffect(() => {
    if (otpTimer > 0) {
      const timer = setInterval(() => {
        setOtpTimer(prev => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpTimer]);

  const toggleLoginMethod = () => {
    setUseOtp(!useOtp);
    setOtp("");
    setOtpRequested(false);
    setIsMobile(!isMobile); // Toggle isMobile state
  };

  const validateCode = (code) => {
    if (code === '') {
      return;
    }

    // code must be all lowercase
    const institutionCodeRegex = /^[a-z]+$/;

    if (!institutionCodeRegex.test(code)) {
      setValidation({ valid: false, error: "Invalid code. Must contain all lowercase letters only." });
    } else {
      setValidation({ valid: true, error: "" });
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.5 } },
  };

  const notifySuccess = (message) => toast.success(message, {
    style: { padding: '35px', color: '#a0ca00' },
    iconTheme: { primary: '#a0ca00', secondary: '#222c25' }
  });

  const notifyError = (message) => toast.error(message, {
    style: { border: '1px solid #fff', padding: '35px', color: 'red' },
    iconTheme: { primary: 'red', secondary: '#fff' }
  });

  useEffect(() => {
    if (instituteCode) {
      handleInstitutionCodeSubmit();
      setInstitutionCode(instituteCode);
    }
  }, [instituteCode]);

  return (
    <div className="mb-5 flex flex-col items-center justify-center p-5 lg:flex-row lg:justify-start shadow-lg rounded-lg">
      <div className="w-full max-w-full flex flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {!showLogin ? (
          <>
            <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white">Sign In</h4>
            <p className="mb-5 ml-1 text-base text-gray-600 text-center">Enter your institution code to proceed!</p>

            <InputField
              variant="auth"
              extra="mb-3 w-full"
              label="Institution Code*"
              placeholder="Enter your institution code"
              id="institutionCode"
              type="text"
              value={institutionCode}
              onChange={(e) => {
                validateCode(e.target.value);
                setInstitutionCode(e.target.value);
              }}
            />
            <button onClick={handleInstitutionCodeSubmit}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Proceed
            </button>
            {/* <h1 className='my-2'>Don't have an account?<span className='text-brand-500 font-bold hover:cursor-pointer' onClick={(e) => { navigate('/auth/signup') }}> Signup</span></h1> */}
          </>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white mt-10">Sign In</h4>
            <p className="mb-5 ml-1 text-base text-gray-600 text-center">Enter your mobile number, OTP, or username and password to sign in!</p>

            <InputField
              variant="auth"
              extra="mb-3"
              label="Mobile Number/Username*"
              placeholder="Enter your mobile number, or username"
              id="identifier"
              type="text"
              value={identifier}
              onChange={(e) => {
                setIdentifier(e.target.value);
                validate(e.target.value, password);
              }}
            />

            {useOtp && otpRequested ? (
              <InputField
                variant="auth"
                extra="mb-3"
                label="OTP*"
                placeholder="Enter the OTP"
                id="otp"
                type="number"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
              />
            ) : (
              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validate(identifier, e.target.value);
                }}
              />
            )}

            {
              useOtp &&
              <button
                onClick={() => handleLogin(true)} // Call handleLogin to resend OTP
                disabled={otpTimer > 0} // Disable button if OTP timer is active
                className="text-brand-500"
              >
                Resend OTP {otpTimer > 0 && `(${otpTimer}s)`}
              </button>
            }
            <button onClick={handleLogin}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              {useOtp && !otpRequested ? "Request OTP" : "Sign In"}
            </button>
          </motion.div>
        )}
        <Toaster />
      </div>
    </div>
  );
}
