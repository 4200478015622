import React, { useState, useEffect } from 'react';
import Form from "../../../components/form"
import { fetchSettings, updateSetting } from '../../../redux/actions/admin/setting';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';

const SettingsPage = () => {
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();
    useEffect(() => {
        console.log("get'ed settings");
        dispatch(fetchSettings())
        

    }, [dispatch])

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })


    const saveSettings = async (data) => {
        try {
            console.log(settings, "SETTINGS");
            console.log(data, "DATA");
            const updateSettings = await dispatch(updateSetting({ 
                ...data, 
                profile: data.profile, 
                id: settings.id,
                enableWhatsappNotifications: data.enableWhatsappNotifications || false,
                enableEmailNotifications: data.enableEmailNotifications || false 
            }));
            notifySuccess("Updated settings");
            dispatch(fetchSettings());
        } catch (err) { 
            notifyError(err.message);
        }
    }


    const settings = useSelector((settings) => settings.setting.value);
    // console.log(settings);
    
    useEffect(() => {
        
        setFormData(settings)
    }, [settings])
    
    useEffect(() => {
        console.log(formData);
    }, [formData])

    const fields = [
        {
            id: "name",
            name: "name",
            label: "Institutes Name",
            placeholder: "Enter Your Institutes's Name",
            type: "text",
        },
        {
            id: "profile",
            name: "profile",
            label: "Institutes Image",
            prevImage: settings.profile,
            placeholder: "Click here to upload Your Institutes's Image",
            type: "upload",
        },
        {
            id: "enableAdmission",
            name: "enableAdmission",
            label: "Admission Form",
            desc: "Enable Admission Form ?",
            type: "switch",
        },
        {
            id: "enableWhatsappNotifications",
            name: "enableWhatsappNotifications",
            label: "WhatsApp Notifications",
            desc: "Enable WhatsApp Notifications?",
            type: "switch",
        },
        {
            id: "enableEmailNotifications",
            name: "enableEmailNotifications",
            label: "Email Notifications",
            desc: "Enable Email Notifications?",
            type: "switch",
        },
        {
            id: "adminMobile",
            name: "adminMobile",
            label: "Admin Mobile Number",
            placeholder: "Enter official contact number",
            type: "number",
        },
        {
            id: "shortName",
            name: "shortName",
            label: "Short Name",
            placeholder: "Enter short name for WhatsApp messages (max 30 characters)",
            type: "text",
        }
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <Form
                    fields={fields}
                    formData={formData}
                    onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                    <button type="button"
                        onClick={() => saveSettings(formData)}
                        className="w-1/6 border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70"> Save </button>
                </div>
            </div>

        </div>
    );
};

export default SettingsPage;
