import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addPeriod, getPeriodByDate } from '../../../redux/actions/admin/period';
import { getCourses, getCourseStudents } from '../../../redux/actions/admin/course';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';
import DropdownSelect from '../../../components/select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const notifySuccess = (message) => toast.success(message, {
    style: {
        padding: '35px',
        color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
        primary: '#a0ca00',
        secondary: '#222c25',
    }
});

function PeriodAttendance() {
    const courses = useSelector((state) => state.course?.value?.data || { rows: [] });
    const students = useSelector((state) => state.course?.value?.students?.data || []);
    const [attendance, setAttendance] = useState({});
    const [allPresent, setAllPresent] = useState(false);
    const [formData, setFormData] = useState({
        courseId: '',
        date: new Date()
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Reset students when component mounts
    useEffect(() => {
        dispatch({ 
            type: 'course/resetStudents'
        });
    }, [dispatch]);

    useEffect(() => {
        dispatch(getCourses({ limit: 50 }));
    }, [dispatch]);

    // Reset students when course changes
    useEffect(() => {
        if (formData.courseId) {
            dispatch(getCourseStudents(formData.courseId));
        } else {
            dispatch({ 
                type: 'course/resetStudents'
            });
        }
    }, [dispatch, formData.courseId]);

    useEffect(() => {
        if (students?.length) {
            const initialAttendance = {};
            students.forEach(studentData => {
                initialAttendance[studentData.student.id] = false;
            });
            setAttendance(initialAttendance);
        }
    }, [students]);

    // Reset attendance when course changes
    useEffect(() => {
        setAttendance({});
        setAllPresent(false);
    }, [formData.courseId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleCheckboxChange = (studentId) => {
        setAttendance(prevState => ({
            ...prevState,
            [studentId]: !prevState[studentId],
        }));
    };

    const handleAllPresentChange = () => {
        setAllPresent(!allPresent);
        setAttendance(prevState => {
            const newState = {};
            students.forEach(studentData => {
                newState[studentData.student.id] = !allPresent;
            });
            return newState;
        });
    };

    const handleDateChange = async (date) => {
        setFormData(prev => ({ ...prev, date }));
        
        if (formData.courseId) {
            try {
                const formattedDate = moment(date).format('YYYY-MM-DD');
                const response = await dispatch(getPeriodByDate({ 
                    date: formattedDate, 
                    courseId: formData.courseId 
                }));

                const existingPeriodResponse = response.payload;

                if (existingPeriodResponse && existingPeriodResponse.id) {
                    // Redirect to the existing period page
                    navigate(`/admin/period/${existingPeriodResponse.id}`);
                    return;
                }
            } catch (error) {
                console.error('Failed to check existing period:', error);
                toast.error('Failed to check existing attendance');
            }
        }
    };

    const handleCourseChange = async (courseId) => {
        setFormData(prev => ({ ...prev, courseId }));
        
        if (courseId && formData.date) {
            try {
                const formattedDate = moment(formData.date).format('YYYY-MM-DD');
                const response = await dispatch(getPeriodByDate({ 
                    date: formattedDate, 
                    courseId: courseId 
                }));

                const existingPeriodResponse = response.payload;

                if (existingPeriodResponse && existingPeriodResponse.id) {
                    // Redirect to the existing period page
                    navigate(`/admin/period/${existingPeriodResponse.id}`);
                    return;
                }
            } catch (error) {
                console.error('Failed to check existing period:', error);
                toast.error('Failed to check existing attendance');
            }
        }
    };

    const computePeriodName = () => {
        if (!formData.courseId) return '';
        const selectedCourse = courses?.rows?.find(course => course.id === formData.courseId);
        const formattedDate = moment(formData.date).format('DD MMM YYYY');
        return `${selectedCourse?.name || ''} • ${formattedDate}`;
    };

    const handleSubmit = () => {
        const periodData = {
            ...formData,
            name: computePeriodName(),
            date: moment(formData.date).format('YYYY-MM-DD'),
            attendance: Object.entries(attendance).map(([studentId, isPresent]) => ({
                studentId,
                status: isPresent ? 'PRESENT' : 'ABSENT'
            }))
        };

        dispatch(addPeriod(periodData))
            .then(() => {
                notifySuccess('Attendance Marked');
                navigate("/admin/attendances");
            })
            .catch((error) => {
                console.error('Failed to mark attendance:', error);
            });
    };

    return (
        <div className='bg-lightPrimary min-h-screen p-4'>
            <p className='text-center mt-5 my-3'>
                {computePeriodName() || 'Select a course to generate period name'}
            </p>
            <div className='p-2 bg-white rounded-xl'>
                <div className="grid grid-cols-1 gap-4 mb-5">
                    <p className='text-center my-1'>Period Details</p>
                    <div className="flex flex-col gap-4 px-4">
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-700 mb-1">Course</label>
                            <DropdownSelect
                                list={courses?.rows?.map(course => ({
                                    name: course.name,
                                    id: course.id
                                })) || []}
                                placeHolder="Select Course"
                                toSelect="id"
                                onChange={handleCourseChange}
                            />
                        </div>
                        <div className="flex flex-col">
                            <label className="text-sm font-medium text-gray-700 mb-1">Date</label>
                            <DatePicker
                                selected={formData.date}
                                onChange={handleDateChange}
                                dateFormat="MMMM d, yyyy"
                                className="border-gray-300 rounded-lg focus:border-brand-500 focus:ring-brand-500 block w-full sm:text-sm p-2"
                                maxDate={new Date()}
                                placeholderText="Select date"
                            />
                        </div>
                    </div>
                </div>

                <div className='bg-gray-100 h-0.5 w-full mx-2 mb-4'></div>
                <p className='text-center my-1'>Attendance Details</p>
                <div className="flex items-center justify-end m-4">
                    <label htmlFor="all-present" className="mr-2 text-sm font-medium text-gray-900">Check/Uncheck All</label>
                    <input
                        id="all-present"
                        type="checkbox"
                        className="form-checkbox h-5 w-5 text-blue-600"
                        checked={allPresent}
                        onChange={handleAllPresentChange}
                    />
                </div>

                {students?.length === 0 && <p className='text-center mt-5'>No students found</p>}

                {students?.map((studentData) => {
                    // Get student name with fallbacks
                    const studentName = studentData?.student?.admission?.name || 
                                      studentData?.student?.name || 
                                      `Student ${studentData?.student?.id?.slice(0, 8)}` || 
                                      'Unnamed Student';

                    return (
                        <div
                            key={studentData.student.id}
                            className="h-3/12 my-5 border border-brand-500 shadow-gray-200 shadow-xl w-full mt-2 rounded-xl flex items-center justify-between overflow-hidden"
                        >
                            <div>
                                <h1 className='font-poppins font-medium text-lg p-3'>
                                    {studentName}
                                </h1>
                            </div>
                            <div className={`flex items-center p-3 transition-color ${
                                attendance[studentData.student.id] ? "bg-green-600" : "bg-red-500"
                            }`}>
                                <input
                                    id={`student-${studentData.student.id}`}
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                                    checked={attendance[studentData.student.id] || false}
                                    onChange={() => handleCheckboxChange(studentData.student.id)}
                                />
                                <label 
                                    htmlFor={`student-${studentData.student.id}`} 
                                    className="ml-2 text-xl font-bold text-white"
                                >
                                    {attendance[studentData.student.id] ? "P" : "A"}
                                </label>
                            </div>
                        </div>
                    );
                })}

                <button
                    onClick={handleSubmit}
                    disabled={!formData.courseId || !formData.date}
                    className={`mb-5 w-full border-2 text-white linear rounded-xl ${
                        !formData.courseId || !formData.date
                            ? 'bg-gray-400 cursor-not-allowed'
                            : 'bg-brand-700 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70'
                    } px-4 py-2 text-center text-base font-medium transition duration-200`}
                >
                    Mark Attendance
                </button>
            </div>
        </div>
    );
}

// Add custom styles for the DatePicker
const customStyles = `
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
  }
  .react-datepicker__input-container input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e5e7eb;
  }
  .react-datepicker__input-container input:focus {
    outline: none;
    border-color: #6366f1;
    ring-color: #6366f1;
  }
`;

// Add styles to document head
const styleSheet = document.createElement("style");
styleSheet.innerText = customStyles;
document.head.appendChild(styleSheet);

export default PeriodAttendance; 