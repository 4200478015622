import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getExams, addExam, updateExam, deleteExam, downloadRankList } from '../../../../redux/actions/admin/exam';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getExamSets } from '../../../../redux/actions/admin/examset';
import { getSubjects } from '../../../../redux/actions/admin/subject';

import {
    FiCheckCircle,
    FiSearch,
    FiX,
    FiEdit2,
    FiTrash,
    FiSettings,
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/shadcn/table";

import DialogCustom from "../../../../components/dialog";
import Form from "../../../../components/form";
import { FaClipboardCheck, FaFileDownload } from 'react-icons/fa';

export const Exam = (props) => {
    // const history = useHistory();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({});
    // const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExamSets({ limit: 250 }))
        dispatch(getSubjects({ limit: 250 }))
        dispatch(getExams({}))
        // setFormData({})
    }, [dispatch])



    const exams = useSelector((exams) => exams.exam.value.data);
    const examsets = useSelector((examsets) => examsets.examset.value.data);
    const subjects = useSelector((subjects) => subjects.subject.value.data);

    // console.log(exams, "exams")
    const count = useSelector((exams) => exams.exam.value.count);
    const activeCount = useSelector((exams) => exams.exam.value.data.activeCount);
    const inactiveCount = useSelector((exams) => exams.exam.value.data.inactiveCount);
    const apiStatus = useSelector((exams) => exams.exam.value.status);
    const [open, setOpen] = useState(false);
    const transparent = true

    const handleActionButtonClick = (examId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (examId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }

        const selectedExam = exams.rows.find((exam) => exam.id === examId);
        setFormData(selectedExam)
    };

    useEffect(() => {
        dispatch(getExams(filter));
    }, [filter]);

    const handleCrudExam = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateExam(formData));
            if (!formData.id) await dispatch(addExam(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getExams(filter));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteExam(formData.id));
            // setFormData({});
            notifySuccess("Delete exam successfully")
            await dispatch(getExams({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDownloadRankList = async (examId) => {
        try {
            const resultAction = await dispatch(downloadRankList(examId));
            if (downloadRankList.fulfilled.match(resultAction)) {
                notifySuccess('Rank list downloaded successfully');
            } else {
                notifyError('Failed to download rank list');
            }
        } catch (error) {
            console.error('Error downloading rank list:', error);
            notifyError('Failed to download rank list');
        }
    };

    // const exams = [];
    const fields = [
        {
            id: "name",
            name: "name",
            label: "Exam Name",
            placeholder: "Enter Exam's Name",
            type: "text",
        },
        {
            id: "minScore",
            name: "minScore",
            label: "Minimum Marks ",
            placeholder: "Minimum marks to pass",
            type: "text",
        },
        {
            id: "maxScore",
            name: "maxScore",
            label: "Maximum Marks",
            placeholder: "Maximum/Total Exam marks",
            type: "text",
        },
        {
            id: "examsetId",
            name: "examsetId",
            label: "Exam Set",
            placeHolder: "Select Exam Set",
            type: "dropdown",
            list: examsets.rows,
            toSelect: "id",
            selectedvalue: "examset",
        },
        {
            id: "subjectId",
            name: "subjectId",
            label: "Subject",
            placeHolder: "Select Subject",
            type: "dropdown",
            list: subjects.rows,
            toSelect: "id",
            selectedvalue: "subject",
        },
        {
            id: "date",
            name: "date",
            label: "Exam Date",
            placeholder: "Select Exam Date",
            type: "date",
        },
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={exams.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={exams.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={exams.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>


                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium  text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    <DropdownSelect
                                        list={[
                                            { id: "all", name: "All" },
                                            ...examsets.rows,
                                        ]}
                                        triggerStyles="bg-lightPrimary !rounded-full !w-[200px] !mr-5"
                                        // listStyles="text-sm text-red-700"
                                        placeHolder={"Filter by Exam Set"}
                                        toSelect={"id"}
                                        onChange={(value) => {
                                            if (value == "all") {
                                                setFilter({ ...filter, ["examsetId"]: null })
                                                return 0
                                            }
                                            setFilter({ ...filter, ["examsetId"]: value })
                                        }}
                                    />
                                    <DropdownSelect
                                        list={[
                                            { id: "all", name: "All" },
                                            ...subjects.rows,
                                        ]}
                                        triggerStyles="bg-lightPrimary !rounded-full !w-[200px]"
                                        // listStyles="text-sm text-red-700"
                                        placeHolder={"Filter by Subject"}
                                        toSelect={"id"}
                                        onChange={(value) => {
                                            if (value == "All") {
                                                setFilter({ ...filter, ["subjectId"]: null })
                                                return
                                            }
                                            setFilter({ ...filter, ["subjectId"]: value, ["page"]: 1 })
                                        }}
                                    />
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Exam
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {exams.rows.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        {/* <TableHead className="w-1/3"> Exam </TableHead> */}
                                        <TableHead className="w-1/3"> Exam Set </TableHead>
                                        <TableHead className="w-1/3"> Course </TableHead>
                                        <TableHead className="w-1/3"> Subject </TableHead>
                                        <TableHead className="w-1/3"> Date </TableHead>
                                        <TableHead className="w-1/3"> Submitted </TableHead>
                                        <TableHead className="w-1/3 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {exams.rows.map((exam) => (
                                        <TableRow key={exam.id}>
                                            {/* <TableCell className="font-medium">
                                                <h4 className=''> {exam.name} </h4>
                                            </TableCell> */}
                                            <TableCell className="font-medium">
                                                <h4 className=''> {exam.examset ? exam.examset.name : "nil"} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {exam.subject.course.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {exam.subject.name} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> {new Date(exam.date).toLocaleDateString()} </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <div
                                                    className={`mx-2 ${exam.isEvaluated ? "bg-green-500 " : "bg-red-500 "}w-fit p-1 rounded-3xl cursor-pointer`} >
                                                    { 
                                                        exam.isEvaluated && <FiCheckCircle className='h-4 w-4 text-white' />
                                                    }
                                                    { 
                                                        !exam.isEvaluated && <FiX className='h-4 w-4 text-white' />
                                                    }
                                                </div>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => {
                                                            navigate(`/admin/exam/result/${exam.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FaClipboardCheck className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => handleDownloadRankList(exam.id)}
                                                        className='mx-2 bg-green-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FaFileDownload className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => handleActionButtonClick(exam.id, "edit")}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => handleActionButtonClick(exam.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={exams.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {exams.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Exams Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Exam
                            </button>
                        </div>
                    }

                    <DialogCustom

                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle={formData.id ? "Edit Exam" : "Add" + " Exam"}
                        dialogWidth="w-1/2"
                        dialogDesc={(formData.id ? "Update" : "Add") + " the necessary fields and save."}

                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudExam}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + " Exam"}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Exam"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this exam ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Exam