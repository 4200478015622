import { React, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import {
    FaBook,
    FaUser
} from "react-icons/fa";
import PieChart from '../../components/charts/PieChart';
import { useDispatch, useSelector } from 'react-redux'
import { getHomeScreenData } from "../../redux/actions/home"

function Home(props) {
    const { user, student } = props
    console.log(props, "props")
    // const staff = useSelector((user) => user.auth.value.profile);
    const dispatch = useDispatch()
    const home = useSelector((home) => home.home.value);
    const pieChartOptions = {
        labels: ["Present", "Absent"],
        colors: ["#589e00", "#dc2626"],
        chart: {
            width: "50px",
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: true,
        },
        hover: { mode: null },
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    },
                },
            },
        },
        fill: {
            colors: ["#589e00", "#dc2626"],
        },
        tooltip: {
            enabled: true,
            theme: "dark",
            style: {
                fontSize: "12px",
                fontFamily: undefined,
                backgroundColor: "#000000"
            },
        },
    };
    const pieChartTaskOptions = {
        labels: ["Complete", "InComplete"],
        colors: ["#589e00", "#dc2626"],
        chart: {
            width: "50px",
        },
        states: {
            hover: {
                filter: {
                    type: "none",
                },
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: true,
        },
        hover: { mode: null },
        plotOptions: {
            donut: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                    },
                },
            },
        },
        fill: {
            colors: ["#589e00", "#dc2626"],
        },
        tooltip: {
            enabled: true,
            theme: "dark",
            style: {
                fontSize: "12px",
                fontFamily: undefined,
                backgroundColor: "#000000"
            },
        },
    };

    const pieChartData = [home.presentPercentage || 0, home.absentPercentage || 0];
    const pieChartTaskData = [home.presentPercentage + 30 || 0, home.absentPercentage - 10 || 0];
    useEffect(() => {
        console.log("student inside UE :" + JSON.stringify(student));
        dispatch(getHomeScreenData(student?.id));
    }, [student]);
    return (
        user && user.role &&
        <div className=' bg-lightPrimary'>
            <div className='flex'>
                <div className='h-[100px] shadow-gray-100   shadow w-full m-2 p-4 bg-blueSecondary rounded-xl flex items-center justify-between  '>
                    <div className='w-1/2'>
                            {
                                user && user.role && user?.role?.name != "Parent" &&
                                <h1 className='text-white font-poppins font-semibold text-2xl'> Hey, {user?.fullname} </h1>
                            }
                            {
                                user && user?.role?.name == "Parent" &&
                                student?.admission && student?.admission?.name &&
                                <h1 className='text-white font-poppins font-semibold text-2xl'> {student?.admission?.name.split(" ").length ? student?.admission?.name.split(" ")[0] : student?.admission?.name} </h1>
                            }
                        <p className='text-white font-light text-sm'>Hope you are doing great!</p>
                    </div>
                    <div style={{ width: "75px", height: "75px" }} className='bg-brand-500 shadow p-3 h-full w-6/12 text-white rounded-full flex justify-center items-center'>
                        {/* <FaUser className='text-4xl' /> */}
                        {student?.admission?.profile ? <img src={`${process.env.REACT_APP_URL + "images" + student.admission.profile}`} alt="" className='w-full h-full object-cover rounded-full' /> : <FaUser className='text-4xl' />}
                    </div>
                </div>
            </div>
            {
                user && user.role.name == "Parent" &&
                <>
                    <div className='shadow-gray-100  bg-white shadow p-4 m-2 rounded-xl'>
                        <p className='font-medium text-base'> {`${student?.admission && student?.admission?.name}'s Attendance`} </p>
                        <PieChart
                            options={pieChartOptions}
                            type="pie"
                            width="100%"
                            height="100%"
                            series={pieChartData}
                        />
                    </div>
                    <div className='shadow-gray-100  bg-white shadow p-4 m-2 rounded-xl'>
                        <p className='font-medium text-base'> {`${student?.admission && student?.admission?.name}'s Task Report`} </p>
                        <PieChart
                            options={pieChartTaskOptions}
                            type="pie"
                            width="100%"
                            height="100%"
                            series={pieChartTaskData}
                        />
                    </div>
                </>
            }

            {/* <div 
            className='flex cursor-pointer' 
            onClick={() => navigate(`/parent/subjects/`, {
                state: {
                  subjects: home.subjects,
                  enrolledClass: home.enrolledClass
                },
              })}
            > 
                <div className='h-3/12 shadow-gray-100   shadow w-full m-2 p-4 bg-blueSecondary rounded-xl flex items-center justify-between  '>
                    <div>
                        <h1 className='text-white font-poppins font-semibold text-2xl'> SUBJECTS </h1>
                        <p className='text-white font-light underline text-sm'>Click here to view</p>
                    </div>
                    <div className='bg-brand-500 shadow p-3 text-white rounded-xl'>
                        <FaBook />
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default Home