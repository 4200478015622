import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getExams = createAsyncThunk("exam/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exam?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getExamStudents = createAsyncThunk("examstudents/get", async (examId) => {
let response = await fetch(`${process.env.REACT_APP_URL}admin/api/result/students/${examId}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getCourseEnrolledStudents = createAsyncThunk("coursestudents/get", async (course) => {
let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exam/course/${course}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addExam = createAsyncThunk("exam/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/exam/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateExam = createAsyncThunk("exam/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exam/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteExam = createAsyncThunk("exam/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exam/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const downloadRankList = createAsyncThunk(
    'exam/downloadRankList',
    async (examId, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}admin/api/exam/rank-list/${examId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...createHeaders()
                    // Add any auth headers if needed
                },
            });

            if (!response.ok) {
                throw new Error('Failed to download rank list');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `rank-list-${examId}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const examsSlice = createSlice({
  name: 'exames',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      examResults: [],
      courseEnrolledStudents: [],
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExamStudents.fulfilled, (exam, action) => {
        exam.value.examResults = action.payload
      })
      .addCase(getCourseEnrolledStudents.fulfilled, (exam, action) => {
        exam.value.courseEnrolledStudents = action.payload.enrollments
      })
      .addCase(getExams.fulfilled, (exam, action) => {
        console.log()
     
        exam.value.data.rows = action.payload.rows
        exam.value.data.count = action.payload.count
        exam.value.status = action.payload.message
        console.log('Updated exam:', exam.value.data.rows);

        
      })
      .addCase(getExams.pending, (exam) => {
        exam.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getExams.rejected, (exam) => {
        exam.status = "Failed to  fetch data..."
      })
      .addCase(updateExam.fulfilled, (exam, action) => {
        console.log(action.payload.message, "action.payload.message")
        exam.value.status = action.payload.message
        // find the index of the exam with the updated id
        // const index = exam.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the exam at the found index with the updated exam
        //   exam.value.data[index] = action.payload;
        // }
      })
      .addCase(updateExam.pending, (exam) => {
        exam.status = "Updating exam. Please wait a moment...";
      })
      .addCase(updateExam.rejected, (exam) => {
        exam.status = "Failed to update exam...";
      })
      .addCase(deleteExam.fulfilled, (exam, action) => {
        // // filter out the exam with the deleted id
        // exam.value.data = exam.value.data.filter(item => item.id !== action.payload.id);
        exam.value.status = action.payload.message
      })
      .addCase(deleteExam.pending, (exam) => {
        exam.status = "Deleting exam. Please wait a moment...";
      })
      .addCase(deleteExam.rejected, (exam) => {
        exam.status = "Failed to delete exam...";
      })
      .addCase(addExam.fulfilled, (exam, action) => {
        // exam.value.data.push(action.payload); // add the new exam to the list
     })
     .addCase(addExam.pending, (exam) => {
        exam.status = "Adding exam. Please wait a moment...";
     })
     .addCase(addExam.rejected, (exam) => {
        exam.status = "Failed to add exam...";
     });
  }
})

export default examsSlice.reducer
