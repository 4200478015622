import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getEnrollments, addEnrollment, updateEnrollment, deleteEnrollment } from '../../../redux/actions/admin/enrollment';
import { getClasses } from '../../../redux/actions/admin/classRoom';
import { getBatches } from '../../../redux/actions/admin/batch';
import { getCourses } from '../../../redux/actions/admin/course';
import { getSessions } from '../../../redux/actions/admin/session';
import { getStudents } from '../../../redux/actions/admin/student';

import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';
import { useNavigate } from 'react-router-dom';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Enrollment = (props) => {
    const [formData, setFormData] = useState({});
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getEnrollments({}))
        // setFormData({})
    }, [dispatch])

    useEffect(() => {
        dispatch(getClasses({ limit: 50 }))
        dispatch(getBatches({ limit: 50 }))
        dispatch(getCourses({ limit: 50 }))
        dispatch(getSessions({ limit: 50 }))
        dispatch(getStudents( { limit: 500 } ))
    }, [dispatch])
    
    const students = useSelector((students) => students.student.value.data);
    const classes = useSelector((classes) => classes.classRoom.value.data);
    const batches = useSelector((batches) => batches.batch.value.data);
    const courses = useSelector((courses) => courses.course.value.data);
    const sessions = useSelector((sessions) => sessions.session.value.data);



    const enrollments = useSelector((enrollments) => enrollments.enrollment.value.data);
    const handleActionButtonClick = (enrollmentId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (enrollmentId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }

        const selectedEnrollment = enrollments.rows.find((enrollment) => enrollment.id === enrollmentId);
        setFormData(selectedEnrollment)
    };

    useEffect(() => {
        dispatch(getEnrollments(filter));
    }, [filter]);

    const validateEnrollmentData = (data) => {
        const errors = [];
        
        if (!data.studentId) errors.push("Student is required");
        if (!data.courseId) errors.push("Course is required");
        if (!data.registerNumber) errors.push("Register number is required");
        
        // Optional fields - only validate if the course requires them
        const selectedCourse = courses.rows.find(course => course.id === data.courseId);
        if (selectedCourse) {
            if (selectedCourse.requiresBatch && !data.batchId) {
                errors.push("Batch is required for this course");
            }
            if (selectedCourse.requiresClass && !data.classId) {
                errors.push("Class is required for this course");
            }
            if (selectedCourse.requiresSession && !data.sessionId) {
                errors.push("Session is required for this course");
            }
        }

        return errors;
    };

    const handleCrudEnrollment = async () => {
        try {
            const validationErrors = validateEnrollmentData(formData);
            
            if (validationErrors.length > 0) {
                notifyError(validationErrors.join('\n'));
                return;
            }

            setIsDialogOpen(false);
            
            // Clean up null values before sending
            const cleanedFormData = {
                ...formData,
                batchId: formData.batchId || null,
                classId: formData.classId || null,
                sessionId: formData.sessionId || null,
                status: formData.status || false
            };

            if (formData.id) {
                await dispatch(updateEnrollment(cleanedFormData));
            } else {
                await dispatch(addEnrollment(cleanedFormData));
            }
            
            notifySuccess("Success");
            setFormData({});
            await dispatch(getEnrollments({}));

        } catch (error) {
            notifyError(error.message || 'Failed to process enrollment');
            console.error('Failed to process enrollment:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteEnrollment(formData.id));
            // setFormData({});
            notifySuccess("Delete enrollment successfully")
            await dispatch(getEnrollments({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };


    // const enrollments = [];
    const fields = [
        {
            id: "batchId",
            name: "batchId",
            label: "Batch",
            placeHolder: batches.rows.length ? "Select Batch" : "No batches available",
            type: "dropdown",
            list: batches.rows,
            toSelect: "id",
            selectedvalue: "batch",
            disabled: !batches.rows.length
        },
        {
            id: "courseId",
            name: "courseId",
            label: "Course",
            placeHolder: courses.rows.length ? "Select Course" : "No courses available",
            type: "dropdown",
            list: courses.rows,
            toSelect: "id",
            selectedvalue: "course",
            required: true,
            disabled: !courses.rows.length
        },
        {
            id: "sessionId",
            name: "sessionId",
            label: "Session",
            placeHolder: sessions.rows.length ? "Select Session" : "No sessions available",
            type: "dropdown",
            list: sessions.rows,
            toSelect: "id",
            selectedvalue: "session",
            disabled: !sessions.rows.length
        },
        {
            id: "classId",
            name: "classId",
            label: "Class",
            placeHolder: classes.rows.length ? "Select Class" : "No classes available",
            type: "dropdown",
            list: classes.rows,
            toSelect: "id",
            selectedvalue: "class",
            disabled: !classes.rows.length
        },
        {
            id: "studentId",
            name: "studentId",
            label: "Student",
            placeHolder: students.rows.length ? "Select Student" : "No students available",
            type: "dropdown",
            list: students.rows,
            toSelect: "id",
            selectedvalue: "student",
            toShow: ['admission', 'name'],
            required: true,
            disabled: !students.rows.length
        },
        {
            id: "registerNumber",
            name: "registerNumber",
            label: "Register Number",
            placeHolder: "Enter Register Number",
            type: "text",
            required: true
        },
        {
            id: "Status",
            name: "status",
            label: "Status",
            desc: "Set status as active/inactive",
            type: "switch"
        }
    ];

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={enrollments.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={enrollments.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={enrollments.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>


                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Enrollment
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {enrollments.rows.length > 0 &&
                            <div className="">
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-4/12"> Student </TableHead>
                                        <TableHead className="w-3/12"> Register Number </TableHead>
                                        <TableHead className="w-3/12"> Course </TableHead>
                                        <TableHead className="w-4/12"> Details </TableHead>
                                        <TableHead className="w-1/3 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {enrollments.rows.map((enrollment) => (
                                        <TableRow key={enrollment.id}>
                                            <TableCell className="font-medium">
                                                <h4 className=''> 
                                                    {enrollment.student?.admission?.name || 'N/A'} 
                                                </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> 
                                                    {enrollment.registerNumber || 'N/A'} 
                                                </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''> 
                                                    {enrollment.course?.name || 'N/A'} 
                                                </h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>
                                                    {[
                                                        enrollment.class?.name,
                                                        enrollment.session?.name
                                                    ].filter(Boolean).join(" - ") || 'N/A'}
                                                </h4>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => handleActionButtonClick(enrollment.id, "edit")}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    {/* <div
                                                        onClick={() => {
                                                            navigate(`/admin/permission/${enrollment.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiSettings className='h-4 w-4 text-white' />
                                                    </div> */}
                                                    <div
                                                        onClick={() => handleActionButtonClick(enrollment.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={enrollments.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {enrollments.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Enrollments Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Enrollment
                            </button>
                        </div>
                    }

                    <DialogCustom
                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle="Enrollment"
                        dialogWidth="w-1/2"
                        dialogDesc="Update the neccessary fields and save."
                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudEnrollment}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + " Enrollment"}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Enrollment"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this enrollment ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Enrollment