import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../../redux/actions/admin/course";
import { getEnrollments } from "../../../redux/actions/admin/enrollment";
import Card from "components/card";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { scheduleMeeting } from "../../../redux/actions/admin/meeting";
import toast, { Toaster } from 'react-hot-toast';

const Meetings = () => {
  const dispatch = useDispatch();
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [meetingDate, setMeetingDate] = useState(new Date());
  const [meetingTime, setMeetingTime] = useState("");

  const courses = useSelector((state) => state.course?.value?.data?.rows || []);
  const enrollments = useSelector((state) => state.enrollment?.value?.data?.rows || []);

  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })


  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  })

  useEffect(() => {
    dispatch(getCourses({}));
  }, [dispatch]);

  useEffect(() => {
    if (selectedCourse) {
      dispatch(getEnrollments({ courseId: selectedCourse.value }));
    }
  }, [selectedCourse, dispatch]);

  const courseOptions = courses.map((course) => ({
    value: course.id,
    label: course.name,
  }));

  const studentOptions = enrollments
    .filter((enrollment) => enrollment.status === true)
    .map((enrollment) => ({
      value: enrollment.studentId,
      label: `${enrollment.student.admission.name}`,
      parent: enrollment.student.parent,
    }));

  const handleScheduleMeeting = () => {
    const formattedData = {
      studentIds: selectedStudents.map(student => student.value),
      time: meetingTime,
      date: meetingDate.toISOString().split('T')[0],
      courseId: selectedCourse.value
    };

    dispatch(scheduleMeeting(formattedData))
      .unwrap()
      .then((response) => {
        // console.log('Meeting scheduled successfully asddd:', response);
        notifySuccess("Meeting scheduled & Parents Notified");
        setSelectedCourse(null);
        setSelectedStudents([]);
        setMeetingDate(new Date());
        setMeetingTime("");
      })
      .catch((error) => {
        console.error('Failed to schedule meeting:', error);
      });
  };

  return (
    <div className="mt-3">
      <Toaster />
      <Card extra="!p-[20px]">
        <div className="mb-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Schedule Parent-Teacher Meetings
          </h4>
        </div>

        <div className="mb-4">
          <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
            Select Course
          </label>
          <Select
            options={courseOptions}
            value={selectedCourse}
            onChange={setSelectedCourse}
            className="basic-single"
            classNamePrefix="select"
          />
        </div>

        {selectedCourse && (
          <div className="mb-4">
            <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
              Select Students
            </label>
            <Select
              isMulti
              options={studentOptions}
              value={selectedStudents}
              onChange={setSelectedStudents}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        )}

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
              Meeting Date
            </label>
            <DatePicker
              selected={meetingDate}
              onChange={(date) => setMeetingDate(date)}
              className="w-full rounded-lg border border-gray-300 p-2"
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-white">
              Meeting Time
            </label>
            <input
              type="time"
              value={meetingTime}
              onChange={(e) => setMeetingTime(e.target.value)}
              className="w-full rounded-lg border border-gray-300 p-2"
            />
          </div>
        </div>

        <div className="mt-6">
          <button
            onClick={handleScheduleMeeting}
            disabled={!selectedStudents.length || !meetingTime}
            className="rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Schedule Meetings
          </button>
        </div>
      </Card>
    </div>
  );
};

export default Meetings; 