import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';
import axios from 'axios';

export const getReports = createAsyncThunk("reports/get", async (query) => {
  let filteredQuery = Object.fromEntries(
    Object.entries(query || {}).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);

  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/report?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  return json;
});

export const generateReport = createAsyncThunk("reports/generate", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/report/generate`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const updateReport = createAsyncThunk("reports/update", async (data) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/report`, {
    method: 'PUT',
    headers: createHeaders(),
    body: JSON.stringify({ ...data })
  });
  let json = await response.json();
  return json;
});

export const deleteReport = createAsyncThunk("reports/delete", async (id) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/report/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const downloadReport = createAsyncThunk(
  'reports/download',
  async (reportId, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}admin/api/report/${reportId}`, {
        method: 'GET',
        headers: createHeaders()
      });

      if (!response.ok) {
        throw new Error('Failed to download report');
      }

      const data = await response.json();
      return data;
      
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReports.fulfilled, (state, action) => {
        state.value.data.rows = action.payload.rows;
        state.value.data.count = action.payload.count;
        state.value.status = action.payload.message;
      })
      .addCase(getReports.pending, (state) => {
        state.value.status = "Fetching reports. Please wait a moment...";
      })
      .addCase(getReports.rejected, (state) => {
        state.value.status = "Failed to fetch reports...";
      })
      .addCase(generateReport.fulfilled, (state, action) => {
        state.value.status = action.payload.message;
      })
      .addCase(generateReport.pending, (state) => {
        state.value.status = "Generating report. Please wait a moment...";
      })
      .addCase(generateReport.rejected, (state) => {
        state.value.status = "Failed to generate report...";
      })
      .addCase(updateReport.fulfilled, (state, action) => {
        state.value.status = action.payload.message;
      })
      .addCase(updateReport.pending, (state) => {
        state.value.status = "Updating report. Please wait a moment...";
      })
      .addCase(updateReport.rejected, (state) => {
        state.value.status = "Failed to update report...";
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        state.value.status = action.payload.message;
      })
      .addCase(deleteReport.pending, (state) => {
        state.value.status = "Deleting report. Please wait a moment...";
      })
      .addCase(deleteReport.rejected, (state) => {
        state.value.status = "Failed to delete report...";
      });
  }
});

export default reportsSlice.reducer; 