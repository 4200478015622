import { useState, useEffect } from 'react';

export default function ThemeCustomizer() {
  const defaultColors = {
    lightPrimary: '#e9ebd5',
    blueSecondary: '#589E00',
    brandLinear: '#868CFF',
  };

  const [isOpen, setIsOpen] = useState(false);
  const [colors, setColors] = useState(defaultColors);

  useEffect(() => {
    const savedColors = localStorage.getItem('themeColors');
    if (savedColors) {
      const parsedColors = JSON.parse(savedColors);
      setColors(parsedColors);
      Object.entries(parsedColors).forEach(([key, value]) => {
        document.documentElement.style.setProperty(`--${key}`, value);
      });
    }
  }, []);

  const updateColor = (key, value) => {
    const newColors = { ...colors, [key]: value };
    setColors(newColors);
    document.documentElement.style.setProperty(`--${key}`, value);
    localStorage.setItem('themeColors', JSON.stringify(newColors));
  };

  const resetColors = () => {
    setColors(defaultColors);
    Object.entries(defaultColors).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    localStorage.removeItem('themeColors');
  };

  return (
    <div className={`fixed right-0 top-1/4 transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-[calc(100%-40px)]'}`}>
      {/* Toggle Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="absolute left-0 top-0 -translate-x-full bg-white p-2 shadow-lg rounded-l-lg"
      >
        {isOpen ? '→' : '←'}
      </button>

      {/* Drawer Content */}
      <div className="w-80 bg-white p-4 shadow-xl rounded-l-lg">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-bold">Theme Customizer</h3>
          <button
            onClick={resetColors}
            className="px-3 py-1 text-sm bg-gray-100 hover:bg-gray-200 rounded-md transition-colors"
          >
            Reset
          </button>
        </div>
        
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Light Primary</label>
            <div className="flex gap-2">
              <input
                type="color"
                value={colors.lightPrimary}
                onChange={(e) => updateColor('lightPrimary', e.target.value)}
                className="w-10 h-10"
              />
              <input
                type="text"
                value={colors.lightPrimary}
                onChange={(e) => updateColor('lightPrimary', e.target.value)}
                className="flex-1 border rounded px-2"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Blue Secondary</label>
            <div className="flex gap-2">
              <input
                type="color"
                value={colors.blueSecondary}
                onChange={(e) => updateColor('blueSecondary', e.target.value)}
                className="w-10 h-10"
              />
              <input
                type="text"
                value={colors.blueSecondary}
                onChange={(e) => updateColor('blueSecondary', e.target.value)}
                className="flex-1 border rounded px-2"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Brand Linear</label>
            <div className="flex gap-2">
              <input
                type="color"
                value={colors.brandLinear}
                onChange={(e) => updateColor('brandLinear', e.target.value)}
                className="w-10 h-10"
              />
              <input
                type="text"
                value={colors.brandLinear}
                onChange={(e) => updateColor('brandLinear', e.target.value)}
                className="flex-1 border rounded px-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 