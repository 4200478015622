import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getClassStudents, submitAttendance } from '../../redux/actions/home';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import toast from 'react-hot-toast';

const notifySuccess = (message) => toast.success(message, {
  style: {
    padding: '35px',
    color: '#a0ca00',
  },
  duration: 3000,
  iconTheme: {
    primary: '#a0ca00',
    secondary: '#222c25',
  }
});

function CrudAttendances() {
  const classId = window.location.pathname.split("attendance/")[1];
  const students = useSelector((home) => home.home.value.classStudents);
  const [attendance, setAttendance] = useState({});
  const [allPresent, setAllPresent] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    from: '',
    to: ''
  });

  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getClassStudents(classId));
  }, [dispatch, classId]);

  useEffect(() => {
    // Initialize attendance with all students marked as false
    if (students?.rows?.length) {
      const initialAttendance = {};
      students.rows.forEach(student => {
        initialAttendance[student.id] = false;
      });
      setAttendance(initialAttendance);
    }
  }, [students]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxChange = (studentId) => {
    setAttendance(prevState => ({
      ...prevState,
      [studentId]: !prevState[studentId],
    }));
  };

  const handleAllPresentChange = () => {
    setAllPresent(!allPresent);
    setAttendance(prevState => {
      const newState = {};
      students.rows.forEach(student => {
        newState[student.id] = !allPresent;
      });
      return newState;
    });
  };

  
  return (
    <div className='bg-lightPrimary h-screen p-4'>
      <p className='text-center mt-5 my-3'> Attendance - <span className='font-bold'>{`${moment(new Date()).format('dddd MM/DD/YYYY')}`}</span> </p>
      <div className='p-2 bg-white rounded-xl '>
        {/* Input fields for name, from, and to */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 mb-5">
          <p className='text-center my-1'>  Period Details </p>                    
          <div className="flex flex-col m-2 border-2 border-gray-300 rounded-lg p-2">
            <label htmlFor="name" className="text-sm font-medium text-gray-700 mb-1">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name} 
              onChange={handleInputChange}
              className="border-gray-300 rounded-lg focus:border-brand-500 focus:ring-brand-500 block w-full sm:text-sm p-2"
              placeholder="Enter Name" 
            />
          </div>
          <div className="flex flex-col m-2 border-2 border-gray-300 rounded-lg p-2">
            <label htmlFor="from" className="text-sm font-medium text-gray-700 mb-1">From</label>
            <input 
              type="time" 
              id="from" 
              name="from" 
              value={formData.from} 
              onChange={handleInputChange}
              className="border-gray-300 rounded-lg focus:border-brand-500 focus:ring-brand-500 block w-full sm:text-sm p-2"
            />
          </div>
          <div className="flex flex-col m-2 border-2 border-gray-300 rounded-lg p-2">
            <label htmlFor="to" className="text-sm font-medium text-gray-700 mb-1">To</label>
            <input 
              type="time" 
              id="to" 
              name="to" 
              value={formData.to} 
              onChange={handleInputChange}
              className="border-gray-300 rounded-lg focus:border-brand-500 focus:ring-brand-500 block w-full sm:text-sm p-2"
            />
          </div>
        </div>
        <div className='bg-gray-100 h-0.5 w-full mx-2 mb-4'></div>
        <p className='text-center my-1'>  Attendance Details </p>    
        <div className="flex items-center justify-end m-4">
          <label htmlFor="all-present" className="mr-2 text-sm font-medium text-gray-900"> Check/Uncheck All</label>
          <input
            id="all-present"
            type="checkbox"
            className="form-checkbox h-5 w-5 text-blue-600"
            checked={allPresent}
            onChange={handleAllPresentChange}
          />
        </div>

        {students?.rows?.length === 0 && <p className='text-center mt-5'> No students found </p>}

        {students?.rows?.map((student, index) => (
          <div
            key={student.id}
            className={`h-3/12 my-5 border border-brand-500 shadow-gray-200 shadow-xl w-full mt-2 rounded-xl flex items-center justify-between overflow-hidden`}
          >
            <div>
              <h1 className='font-poppins font-medium text-lg p-3'> {student?.admission?.name} </h1>
            </div>
            <div className={`flex items-center p-3 transition-color ${attendance[student.id] ? "bg-green-600" : "bg-red-500"}`}>
              <input
                id={`student-${student.id}`}
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                checked={attendance[student.id] || false}
                onChange={() => handleCheckboxChange(student.id)}
              />
              <label htmlFor={`student-${student.id}`} className={`ml-2 text-xl font-bold text-white transition-colors`}> {attendance[student.id] ? "P" : "A"}</label>
            </div>
          </div>
        ))}

        <button
          onClick={() => {
            dispatch(submitAttendance({ attendance, period: {...formData, classId} }));
            notifySuccess(`Attendance Marked`);
            navigate("/parent/classes")
          }}
          className="mb-5 w-full border-2 text-white linear rounded-xl bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70"
        >
          Mark Attendance
        </button>
      </div>
    </div>
  );
}

export default CrudAttendances;
