import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';
import moment from 'moment';
import axios from 'axios';


export const getPeriods = createAsyncThunk("period/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);

// Format date if it exists
if (filteredQuery.date) {
    filteredQuery.date = moment(filteredQuery.date).format('YYYY-MM-DD');
}

let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getSinglePeriod = createAsyncThunk("singleperiod/get", async (id) => {
  console.log(id)

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/${id}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getAllPeriods = createAsyncThunk("allperiod/get", async () => {

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/all`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const getPeriod = createAsyncThunk("single-period/get", async (id) => {

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/${id}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addPeriod = createAsyncThunk("period/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const approvePeriod = createAsyncThunk("period/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/approve`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const postOpenPeriod = createAsyncThunk("period/add", async (query) => {
  const { code, formData } = query
  console.log(formData, "formData")
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/period/${code}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
  const json = await response.json();
  return json;
});



export const updatePeriod = createAsyncThunk("period/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deletePeriod = createAsyncThunk("period/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const getPeriodByDate = createAsyncThunk(
  'period/getByDate',
  async ({ date, courseId }) => {
    try {
      let response = await fetch(`${process.env.REACT_APP_URL}admin/api/period/filter/by-date?date=${date}&courseId=${courseId}`, {
        headers: createHeaders(),
      });
      // const response = await axios.get(`${process.env.REACT_APP_URL}admin/api/period/by-date?date=${date}&courseId=${courseId}`);
      let json = await response.json();
      return json;
    } catch (error) {
      throw error;
    }
  }
);

export const periodsSlice = createSlice({
  name: 'periodes',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPeriods.fulfilled, (period, action) => {
     
        period.value.data.rows = action.payload.rows
        period.value.data.count = action.payload.count
        period.value.status = action.payload.message
        console.log('Updated period:', period.value.data.rows);

        
      })
      .addCase(getSinglePeriod.fulfilled, (period, action) => {
     
        period.value.singlePeriod = action.payload
        
      })
      .addCase(getAllPeriods.fulfilled, (period, action) => {
        period.value.allPeriods = action.payload
      })
      .addCase(getPeriods.pending, (period) => {
        period.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getPeriods.rejected, (period) => {
        period.status = "Failed to  fetch data..."
      })
      .addCase(updatePeriod.fulfilled, (period, action) => {
        console.log(action.payload.message, "action.payload.message")
        period.value.status = action.payload.message
        // find the index of the period with the updated id
        // const index = period.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the period at the found index with the updated period
        //   period.value.data[index] = action.payload;
        // }
      })
      .addCase(updatePeriod.pending, (period) => {
        period.status = "Updating period. Please wait a moment...";
      })
      .addCase(updatePeriod.rejected, (period) => {
        period.status = "Failed to update period...";
      })
      .addCase(deletePeriod.fulfilled, (period, action) => {
        // // filter out the period with the deleted id
        // period.value.data = period.value.data.filter(item => item.id !== action.payload.id);
        period.value.status = action.payload.message
      })
      .addCase(deletePeriod.pending, (period) => {
        period.status = "Deleting period. Please wait a moment...";
      })
      .addCase(deletePeriod.rejected, (period) => {
        period.status = "Failed to delete period...";
      })
      .addCase(addPeriod.fulfilled, (period, action) => {
        // period.value.data.push(action.payload); // add the new period to the list
     })
     .addCase(addPeriod.pending, (period) => {
        period.status = "Adding period. Please wait a moment...";
     })
     .addCase(addPeriod.rejected, (period) => {
        period.status = "Failed to add period...";
     });
  }
})

export default periodsSlice.reducer
