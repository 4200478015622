import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCourses, addCourse, updateCourse, deleteCourse, importStudents } from '../../../redux/actions/admin/course';
import toast, { Toaster } from 'react-hot-toast';
import Paginate from 'components/paginate';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
    FiTrendingUp,
    FiX,
    FiLayers,
    FiSearch,
    FiUnlock,
    FiEdit2,
    FiTrash,
    FiSettings,
    FiUpload,
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

// Import XLSX at the top of the file
import * as XLSX from 'xlsx';
import LoadingIcons from 'react-loading-icons';

export const Course = (props) => {
    // const history = useHistory();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({});
    // const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
    const [importedStudents, setImportedStudents] = useState([]);
    const [jsonData, setJsonData] = useState("");
    const [isBulkImportOpen, setIsBulkImportOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCourses({}))
        // setFormData({})
    }, [dispatch])

    

    const courses = useSelector((courses) => courses.course.value.data);
    // console.log(courses, "courses")
    const count = useSelector((courses) => courses.course.value.count);
    const activeCount = useSelector((courses) => courses.course.value.data.activeCount);
    const inactiveCount = useSelector((courses) => courses.course.value.data.inactiveCount);
    const apiStatus = useSelector((courses) => courses.course.value.status);
    const [open, setOpen] = useState(false);
    const transparent = true

    const handleActionButtonClick = (courseId, action) => {
        if (action === 'edit') setIsDialogOpen(true);
        if (action === 'delete') setIsDeleteDialogOpen(true);
        if (courseId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }

        const selectedCourse = courses.rows.find((course) => course.id === courseId);
        setFormData(selectedCourse)
    };

    useEffect(() => {
        dispatch(getCourses(filter));
    }, [filter]);

    const handleCrudCourse = async () => {
        try {
            setIsDialogOpen(false)
            if (formData.id) await dispatch(updateCourse(formData));
            if (!formData.id) await dispatch(addCourse(formData));
            notifySuccess("Success")

            setFormData({});
            await dispatch(getCourses({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            await dispatch(deleteCourse(formData.id));
            // setFormData({});
            notifySuccess("Delete course successfully")
            await dispatch(getCourses({}));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const validateAndParseExcel = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const workbook = XLSX.read(e.target.result, { type: 'binary' });
                    const firstSheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[firstSheetName];
                    const data = XLSX.utils.sheet_to_json(worksheet, { defval: "" });

                    // Validate that required columns exist in the header
                    const requiredColumns = ['name', 'DOB', 'email', 'phone', 'parentName'];
                    const fileColumns = Object.keys(data[0] || {}).map(col => col.toLowerCase());

                    const missingColumns = requiredColumns.filter(
                        col => !fileColumns.includes(col.toLowerCase())
                    );

                    if (missingColumns.length > 0) {
                        notifyError(`Missing required columns: ${missingColumns.join(', ')}`)
                        reject(`Missing required columns: ${missingColumns.join(', ')}`);
                        return;
                    }

                    setImportedStudents(data);
                    setJsonData(JSON.stringify(data, null, 2));
                    resolve(data);
                } catch (error) {
                    reject('Error parsing Excel file: ' + error.message);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    };

    const handleFileUpload = async (courseId) => {
        try {
            if (!selectedFile) {
                return notifyError("Please select a file first");
            }

            setIsBulkImportOpen(true);
            
            try {
                // Send all students at once
                await dispatch(importStudents({
                    courseId,
                    students: importedStudents
                }));
                
                setIsBulkImportOpen(false);
                setIsImportDialogOpen(false);
                setSelectedFile(null);
                setImportedStudents([]);
                setJsonData("");
                dispatch(getCourses(filter));
                notifySuccess("Students imported successfully!");
            } catch (error) {
                throw error;
            }

        } catch (error) {
            setIsBulkImportOpen(false);
            notifyError(error.message || "Failed to import students");
        }
    };

    // const courses = [];
    const fields = [
        {
            id: "coursename",
            name: "name",
            label: "Course Name",
            placeholder: "Enter Course's Name",
            type: "text",
        }
    ];

    const downloadSampleFile = () => {
        // Create sample data
        const sampleData = [
            {
                name: "John Doe",
                DOB: "2000-01-01",
                email: "john@example.com",
                phone: "1234567890",
                parentName: "Jane Doe"
            }
        ];

        // Create workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(sampleData);

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, "Students");

        // Save file
        XLSX.writeFile(wb, "sample_students_import.xlsx");
    };

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={courses.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={courses.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={courses.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
                    

                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            // value={filter.name}
                                            onChange={(e) => {
                                                setFilter({ ...filter, ["name"]: e.target.value })
                                            }}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                    {/* <DropdownSelect
                                                list={[
                                                    { name: "All", id: 1 },
                                                    { name: "Active", id: 2 },
                                                    { name: "Inactive", id: 3 },
                                                ]}
                                                triggerStyles="bg-lightPrimary !rounded-full"
                                                // listStyles="text-sm text-red-700"
                                                placeHolder={"Status"}
                                                toSelect={"name"}
                                                onChange={(value) => {
                                                    setFilter({ ...filter, ["status"]: value })
                                                }}
                                            /> */}
                                </div>
                                <div className='flex  justify-end'>
                                    <button
                                        onClick={() => handleActionButtonClick(null, "add")}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                        Add Course
                                    </button>

                                </div>
                            </div>
                        </TableCaption>
                        {courses.rows.length > 0 &&
                            <div className=""> 
                                <TableHeader>

                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-11/12"> Course </TableHead>
                                        <TableHead className="w-1/3 text-center ">Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {courses.rows.map((course) => (
                                        <TableRow key={course.id}>
                                            <TableCell className="font-medium">
                                                <h4 className='text-brand-500 font-black cursor-pointer' onClick={() => { navigate(`/admin/course/${course.id}`) }}> {course.name} </h4>
                                            </TableCell>
                                            <TableCell className="w-1/6  ">
                                                <div className='w-full flex justify-around'>
                                                    <div
                                                        onClick={() => handleActionButtonClick(course.id, "edit")}
                                                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiEdit2 className='h-4 w-4 text-white' />
                                                    </div>
                                                    {/* <div
                                                        onClick={() => {
                                                            navigate(`/admin/permission/${course.id}`);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                                                        <FiSettings className='h-4 w-4 text-white' />
                                                    </div> */}
                                                    <div
                                                        onClick={() => handleActionButtonClick(course.id, "delete")}
                                                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                                                        <FiTrash className='h-4 w-4 text-white' />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            setFormData(course);
                                                            setIsImportDialogOpen(true);
                                                        }}
                                                        className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer'
                                                    >
                                                        <FiUpload className='h-4 w-4 text-white' />
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}


                                </TableBody>
                                <Paginate
                                    page={filter.page}
                                    totalCount={courses.count}
                                    limit={filter.limit}
                                    changePage={(value) => {
                                        setFilter({ ...filter, ["page"]: value })
                                    }}
                                />
                            </div>
                        }
                    </Table>
                    {courses.rows.length === 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Courses Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add Course
                            </button>
                        </div>
                    }

                    <DialogCustom

                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle={formData.id ? "Edit Course" : "Add" + " Course"}
                        dialogWidth="w-1/2"
                        dialogDesc={(formData.id ? "Update" : "Add") + " the necessary fields and save."}
                        content={
                            <>
                                <Form
                                    fields={fields}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={handleCrudCourse}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + " Course"}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete Course"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this course ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                    <DialogCustom
                        open={isImportDialogOpen}
                        onOpenChange={setIsImportDialogOpen}
                        dialogTitle="Import Students"
                        dialogWidth="w-1/2"
                        dialogDesc="Upload the Students XLS file to import students in bulk"
                        content={
                            <>
                                <div className='flex justify-between'>
                                    <div className="relative">
                                        <input
                                            type="file"
                                            accept=".xls,.xlsx"
                                            onChange={(e) => {
                                                setSelectedFile(e.target.files[0]);
                                                validateAndParseExcel(e.target.files[0]);
                                            }}
                                            className="appearance-none opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                                        />
                                        <div className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                            Choose File
                                        </div>
                                    </div>

                                    <button
                                        onClick={downloadSampleFile}
                                        className="text-brand-500 hover:text-brand-700 underline text-sm"
                                    >
                                        Download Sample
                                    </button>
                                </div>

                                {selectedFile && (
                                    <div className="mt-4 text-sm text-gray-600">
                                        Selected file: {selectedFile.name}
                                    </div>
                                )}

                                <div className='flex flex-row-reverse mt-4'>
                                    <button
                                        onClick={() => handleFileUpload(formData.id)}
                                        disabled={!selectedFile || importedStudents.length === 0}
                                        className={`text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 
                                            ${selectedFile && importedStudents.length > 0 
                                                ? 'hover:!bg-brand-850/80' 
                                                : 'opacity-50 cursor-not-allowed'}`}
                                    >
                                        Submit
                                    </button>
                                    <button
                                        onClick={() => {
                                            setIsImportDialogOpen(false);
                                            setSelectedFile(null);
                                            setImportedStudents([]);
                                        }}
                                        className="mr-4 text-white linear rounded-xl bg-gray-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-gray-600"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        }
                    />
                    <DialogCustom
                        open={isBulkImportOpen}
                        onOpenChange={setIsBulkImportOpen}
                        dialogTitle="Uploading Students"
                        dialogWidth="w-1/2"
                        dialogDesc="Please wait while the students are being uploaded"
                        content={
                            <>
                                <div className="flex justify-center flex-col">
                                    <div className='w-full'>
                                        <LoadingIcons.SpinningCircles className='w-full' fill="#7cb400" />
                                    </div>
                                    <p className="text-center text-brand-500 ml-2">Uploading students...</p>
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => {
                                            setIsBulkImportOpen(false);
                                        }}
                                        className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    )
};

export default Course