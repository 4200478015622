import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Admin
import Course from "views/admin/course";
import Batch from "views/admin/batch";
import Session from "views/admin/session";
import User from "views/admin/user";
import Role from "views/admin/roles";
import Settings from "views/admin/settings";
import Admissions from "views/admin/admissions";
import Fields from "views/admin/fields";
import Student from "views/admin/students";
import Task from "views/admin/task/task"
import TaskType from "views/admin/task/task-type"
import Class from "views/admin/classes"
import Enrollment from "views/admin/enrollments"
import Period from "views/admin/period"
import CustomRecord from "views/admin/customrecords"
import ExamMaster from "views/admin/exams/exam-master";
import Exam from "views/admin/exams/exams";
import Result from "views/admin/exams/results";
import Grade from "views/admin/exams/grades";
import ExamSet from "views/admin/exams/exam-sets";

// Icon Imports
import {
  MdHome,
  MdPerson,
  MdAccountTree,
  MdBadge
} from "react-icons/md";
import { 
  FaWpforms,
  FaTasks,
  FaUserPlus, 
  FaUserGraduate,
  FaCalendarCheck,
  FaRecordVinyl
} from "react-icons/fa";

import {
  FiEdit3,
  FiSettings
} from "react-icons/fi"



const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Structure",
    layout: "/admin",
    icon: <MdAccountTree className="h-6 w-6" />,
    path: "structure",
    isDropdown: true,
    children: [
      {
        name: "Batch",
        layout: "/admin",
        path: "batch",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Batch />,
      }, 
      {
        name: "Course",
        layout: "/admin",
        path: "course",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Course />,
      }, 
      {
        name: "Session",
        layout: "/admin",
        path: "session",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Session />,
      }, 
      {
        name: "Classrooms",
        layout: "/admin",
        path: "classrooms",
        icon: <FaUserPlus className="h-6 w-6" />,
        component: <Class />,
        // secondary: true,
      },
      
    ],
    component: <User />,
  },
  {
    name: "Admissions",
    layout: "/admin",
    path: "admissions",
    icon: <FaUserPlus className="h-6 w-6" />,
    component: <Admissions />,
    secondary: true,
  },
  // {
  //   name: "Custom Records",
  //   layout: "/admin",
  //   icon: <FiFilePlus className="h-6 w-6" />,
  //   path: "customrecord",
  //   isDropdown: true,
  //   children: [
  //     {
  //       name: "Custom Records**",
  //       layout: "/admin",
  //       path: "customrecords",
  //       icon: <FaRecordVinyl className="h-6 w-6" />,
  //       component: <CustomRecord />,
  //     },
  //   ],
  //   component: <User />,
  // },
  {
    name: "Enrollments",
    layout: "/admin",
    path: "enrollments",
    icon: <MdBadge className="h-6 w-6" />,
    component: <Enrollment />,
    secondary: true,
  },
  {
    name: "Students",
    layout: "/admin",
    path: "students",
    icon: <FaUserGraduate className="h-6 w-6" />,
    component: <Student />,
    secondary: true,
  },
  {
    name: "Attendances",
    layout: "/admin",
    path: "attendances",
    icon: <FaCalendarCheck className="h-6 w-6" />,
    component: <Period />,
    secondary: true,
  },
  {
    name: "Task",
    layout: "/admin",
    path: "task",
    icon: <FaTasks className="h-6 w-6" />,
    isDropdown: true,
    children: [
      {
        name: "Task Type",
        layout: "/admin",
        path: "task-type",
        icon: <MdPerson className="h-6 w-6" />,
        component: <TaskType />,
      }, 
      {
        name: "Task",
        layout: "/admin",
        path: "tasks",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Task />,
      },
    ],
    secondary: true,
  },
  {
    name: "Exams",
    layout: "/admin",
    path: "exam",
    icon: <FiEdit3 className="h-6 w-6" />,
    isDropdown: true,
    children: [
      {
        name: "Grades",
        layout: "/admin",
        path: "grades",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Grade />,
      }, 
      {
        name: "Exam Types",
        layout: "/admin",
        path: "examtypes",
        icon: <MdPerson className="h-6 w-6" />,
        component: <ExamMaster />,
      }, 
      {
        name: "Exam Sets",
        layout: "/admin",
        path: "sets",
        icon: <MdPerson className="h-6 w-6" />,
        component: <ExamSet />,
      }, 
      {
        name: "Exams",
        layout: "/admin",
        path: "exams",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Exam />,
      },
      {
        name: "Results",
        layout: "/admin",
        path: "results",
        icon: <MdPerson className="h-6 w-6" />,
        component: <Result />,
      },
    ],
    secondary: true,
  },
  // {
  //   name: "Users & Roles",
  //   layout: "/admin",
  //   path: "people",
  //   icon: <FiUsers className="h-6 w-6" />,
  //   isDropdown: true,
  //   children: [
      // {
      //   name: "Users",
      //   layout: "/admin",
      //   path: "users",
      //   icon: <MdPerson className="h-6 w-6" />,
      //   component: <User />,
      // }, 
  //     {
  //       name: "Roles",
  //       layout: "/admin",
  //       path: "roles",
  //       icon: <MdPerson className="h-6 w-6" />,
  //       component: <Role />,
  //     },
  //     // {
  //     //   name: "Organizations",
  //     //   layout: "/admin",
  //     //   path: "profile-4",
  //     //   icon: <MdPerson className="h-6 w-6" />,
  //     //   component: <Permission />,
  //     // },
  //   ],
  //   secondary: true,
  // },
  {
    name: "Users",
    layout: "/admin",
    path: "users",
    icon: <MdPerson className="h-6 w-6" />,
    component: <User />,
    secondary: true,
  }, 
  
  
  {
    name: "Custom Fields",
    layout: "/admin",
    path: "customfields",
    icon: <FaWpforms className="h-6 w-6" />,
    component: <Fields />,
    secondary: true,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <FiSettings className="h-6 w-6" />,
    component: <Settings />,
    secondary: true,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "nft-marketplace",
  //   icon: <MdOutlineShoppingCart className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <MdBarChart className="h-6 w-6" />,
  //   path: "data-tables",
  //   component: <DataTables />,
  // },
  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "profile",
  //   icon: <MdPerson className="h-6 w-6" />,
  //   component: <Profile />,
  // },
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
  // {
  //   name: "Sign Up",
  //   layout: "/auth",
  //   path: "sign-up",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignUp/>,
  // },
//   {
//     name: "Blog",
//     layout: "/admin",
//     path: "blog/:id",
//     icon: <MdApps className="h-6 w-6" />,
//     component: <NFTMarketplace />,
//     secondary: true,
// },
  // {
  //   name: "Reset Password",
  //   layout: "/auth",
  //   path: "reset-password",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <ResetPassword/>,
  // },
  // {
  //   name: "Reset Password",
  //   layout: "/auth",
  //   path: "reset-password-request",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <ResetPasswordRequest/>,
  // },
  // {
  //   name: "CHECK TOKEN",
  //   layout: "/auth",
  //   path: "CHECKTOKEN",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <CheckToken/>,
  // },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "rtl",
  //   icon: <MdHome className="h-6 w-6" />,
  //   component: <RTLDefault />,
  // },
];
export default routes;
