import { React, useState, useEffect } from 'react'
import { getPeriods, getSinglePeriod, updatePeriod } from '../../../redux/actions/admin/period';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../components/shadcn/table";
import {
    FiSearch,
} from "react-icons/fi";
import moment from 'moment';

export const SinglePeriod = (props) => {
    const { setTitle } = props
    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const period = useSelector((periods) => periods.period.value.singlePeriod);
    const [studentAttendance, setStudentAttendance] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        dispatch(getPeriods({}))
        dispatch(getSinglePeriod(id))
        if(period) {
            setTitle("Period - " + period.name)
        }
    }, [dispatch])

    useEffect(() => {
        if (period && period.attendances) {
            const initialAttendance = period.attendances.map(attendance => ({
                id: attendance.id,
                studentId: attendance.student.id,
                studentName: attendance.student.admission.name,
                isPresent: attendance.status === 'PRESENT'
            }));
            setStudentAttendance(initialAttendance);
        }
    }, [period]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleAttendanceToggle = (studentId) => {
        setStudentAttendance(prev => 
            prev.map(student => 
                student.studentId === studentId 
                    ? { ...student, isPresent: !student.isPresent }
                    : student
            )
        );
    };

    const handleMarkAll = () => {
        const allPresent = studentAttendance.every(student => student.isPresent);
        setStudentAttendance(prev => 
            prev.map(student => ({ ...student, isPresent: !allPresent }))
        );
    };

    const handleUpdateAttendance = async () => {
        try {
            const updatedPeriod = {
                id: period.id,
                attendance: studentAttendance.map(student => ({
                    id: student.id,
                    studentId: student.studentId,
                    status: student.isPresent ? 'PRESENT' : 'ABSENT'
                }))
            };

            await dispatch(updatePeriod(updatedPeriod));
            toast.success('Attendance updated successfully');
            dispatch(getSinglePeriod(id)); // Refresh the data
        } catch (error) {
            console.error('Update error:', error);
            toast.error('Failed to update attendance');
        }
    };

    const filteredAttendances = studentAttendance.filter(attendance =>
        attendance.studentName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className="mb-6 p-4 rounded-xl border-2 border-gray-100">
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <div className="p-4 rounded-lg bg-gray-50">
                            <h3 className="text-sm text-gray-500 mb-1">Created By</h3>
                            <p className="font-medium text-gray-900">{period?.createdBy || 'N/A'}</p>
                        </div>
                        <div className="p-4 rounded-lg bg-gray-50">
                            <h3 className="text-sm text-gray-500 mb-1">Created At</h3>
                            <p className="font-medium text-gray-900">
                                {period?.createdAt ? moment(period.createdAt).format('DD MMM YYYY, HH:mm') : 'N/A'}
                            </p>
                        </div>
                        <div className="p-4 rounded-lg bg-green-50">
                            <h3 className="text-sm text-green-600 mb-1">Total Present</h3>
                            <p className="font-medium text-green-700">
                                {studentAttendance.filter(student => student.isPresent).length}
                            </p>
                        </div>
                        <div className="p-4 rounded-lg bg-red-50">
                            <h3 className="text-sm text-red-600 mb-1">Total Absent</h3>
                            <p className="font-medium text-red-700">
                                {studentAttendance.filter(student => !student.isPresent).length}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                                <div className='flex justify-end'>
                                    {/* <button
                                        onClick={handleMarkAll}
                                        className={`px-4 py-2 rounded-lg font-medium mr-4 ${
                                            studentAttendance.every(s => s.isPresent)
                                                ? 'bg-red-100 text-red-700'
                                                : 'bg-green-100 text-green-700'
                                        }`}
                                    >
                                        {studentAttendance.every(s => s.isPresent) 
                                            ? 'Mark All Absent' 
                                            : 'Mark All Present'}
                                    </button> */}
                                    <button
                                        onClick={handleUpdateAttendance}
                                        className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500"
                                    >
                                        Update Attendance
                                    </button>
                                </div>
                            </div>
                        </TableCaption>
                        {filteredAttendances.length > 0 && (
                            <div className="">
                                <TableHeader>
                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-2/12"></TableHead>
                                        <TableHead className="w-6/12">Student</TableHead>
                                        <TableHead className="w-6/12">Status</TableHead>
                                        <TableHead className="w-2/12"></TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {filteredAttendances.map((attendance) => (
                                        <TableRow key={attendance.id}>
                                            <TableCell className="font-medium">
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <h4 className=''>{attendance.studentName}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                                <button
                                                    onClick={() => handleAttendanceToggle(attendance.studentId)}
                                                    className={`px-4 py-2 rounded-lg font-medium ${
                                                        attendance.isPresent
                                                            ? 'bg-green-100 text-green-700'
                                                            : 'bg-red-100 text-red-700'
                                                    }`}
                                                >
                                                    {attendance.isPresent ? 'Present' : 'Absent'}
                                                </button>
                                            </TableCell>
                                            <TableCell className="font-medium">
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </div>
                        )}
                    </Table>
                    {filteredAttendances.length === 0 && (
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'>No Attendances Found!</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default SinglePeriod