import React, { useState } from "react";
import InputField from "components/fields/InputField";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import { requestOtp, validateOtp, resetPasswordMobile } from "../../../redux/actions/auth";
import { motion } from "framer-motion";
import Eye from "components/icons/Eye";
import { useNavigate } from "react-router-dom";

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { 
    opacity: 1, 
    y: 0,
    transition: { duration: 0.5, ease: "easeOut" }
  }
};

export default function ResetPassword() {
  const dispatch = useDispatch();
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState(1); // 1: Mobile, 2: OTP, 3: New Password
  const [validation, setValidation] = useState({ valid: false, error: "" });
  const code = window.location.href.split("?code=")[1];
  const [otpTimer, setOtpTimer] = useState(0);
  const navigate = useNavigate();
  const [passwordValidation, setPasswordValidation] = useState({
    valid: true,
    error: ""
  });

  const validateMobileNumber = (number) => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!number) {
      setValidation({ valid: false, error: "Mobile number is required" });
      return false;
    }
    if (!mobileRegex.test(number)) {
      setValidation({ valid: false, error: "Please enter a valid 10-digit mobile number" });
      return false;
    }
    setValidation({ valid: true, error: "" });
    return true;
  };

  // const validateOtp = (otpValue) => {
  //   const otpRegex = /^[0-9]{4}$/;
  //   if (!otpValue) {
  //     setValidation({ valid: false, error: "OTP is required" });
  //     return false;
  //   }
  //   if (!otpRegex.test(otpValue)) {
  //     setValidation({ valid: false, error: "Please enter a valid 6-digit OTP" });
  //     return false;
  //   }
  //   setValidation({ valid: true, error: "" });
  //   return true;
  // };

  const handleSendOTP = async () => {
    if (!validateMobileNumber(mobileNumber)) {
      notifyError(validation.error);
      return;
    }

    try {
      const response = await dispatch(requestOtp({ 
        mobile: mobileNumber,
        code: code 
      }));

      if (response.payload.auth) {
        notifySuccess("OTP sent successfully!");
        setStep(2);
        setOtpTimer(30);
      } else {
        notifyError(response.payload.message || "Failed to send OTP");
      }
    } catch (error) {
      notifyError("An error occurred while sending OTP");
    }
  };

  const handleVerifyOTP = async () => {
    // if (!validateOtp(otp)) {
    //   notifyError(validation.error);
    //   return;
    // }

    try {
      const response = await dispatch(validateOtp({
        mobile: mobileNumber,
        otp: otp,
        code: code
      }));

      if (response.payload.auth) {
        notifySuccess("OTP verified successfully!");
        setStep(3);
      } else {
        notifyError(response.payload.message || "Invalid OTP");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      notifyError("An error occurred while verifying OTP");
    }
  };

  const validatePasswords = (password, confirmPass) => {
    const newPass = password ?? newPassword;
    const confirmPassword = confirmPass ?? confirmPassword;

    if (!newPass) {
      setPasswordValidation({ valid: false, error: "Password is required" });
      return false;
    }
    if (newPass.length < 8) {
      setPasswordValidation({ valid: false, error: "Password must be at least 8 characters long" });
      return false;
    }
    if (!confirmPassword) {
      setPasswordValidation({ valid: false, error: "Please confirm your password" });
      return false;
    }
    if (newPass !== confirmPassword) {
      setPasswordValidation({ valid: false, error: "Passwords do not match" });
      return false;
    }
    
    setPasswordValidation({ valid: true, error: "" });
    return true;
  };

  const handleResetPassword = async () => {
    if (!validatePasswords(newPassword, confirmPassword)) {
      notifyError(passwordValidation.error);
      return;
    }

    try {
      const response = await dispatch(resetPasswordMobile({
        mobile: mobileNumber,
        password: newPassword,
        otp: otp,
        code: code
      }));

      if (response.payload.success) {
        notifySuccess("Password reset successful!");
        setTimeout(() => {
          navigate("/auth/sign-in");
        }, 1500);
      } else {
        notifyError(response.payload.message || "Failed to reset password");
      }
    } catch (error) {
      console.error("Password reset error:", error);
      notifyError("An error occurred while resetting password");
    }
  };

  React.useEffect(() => {
    if (otpTimer > 0) {
      const timer = setInterval(() => {
        setOtpTimer(prev => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [otpTimer]);

  const notifySuccess = (message) => toast.success(message, {
    style: { padding: '35px', color: '#a0ca00' },
    iconTheme: { primary: '#a0ca00', secondary: '#222c25' }
  });

  const notifyError = (message) => toast.error(message, {
    style: { border: '1px solid #fff', padding: '35px', color: 'red' },
    iconTheme: { primary: 'red', secondary: '#fff' }
  });

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Reset Password
        </h4>
        
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate="visible"
        >
          {step === 1 && (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Enter your mobile number to receive OTP.
              </p>
              <InputField
                variant="auth"
                extra="mb-3"
                label="Mobile Number*"
                placeholder="Enter your 10-digit mobile number"
                id="mobileNumber"
                type="tel"
                maxLength="10"
                value={mobileNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  setMobileNumber(value);
                  validateMobileNumber(value);
                }}
              />
              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleSendOTP}
              >
                Send OTP
              </button>
            </>
          )}

          {step === 2 && (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Enter the OTP sent to your mobile number.
              </p>
              <InputField
                variant="auth"
                extra="mb-3"
                label="OTP*"
                placeholder="Enter 6-digit OTP"
                id="otp"
                type="number"
                maxLength="6"
                value={otp}
                onChange={(e) => {
                  const value = e.target.value.slice(0, 6);
                  setOtp(value);
                  validateOtp(value);
                }}
              />
              {otpTimer > 0 ? (
                <p className="text-sm text-gray-500 mb-3">Resend OTP in {otpTimer}s</p>
              ) : (
                <button
                  className="text-brand-500 text-sm mb-3"
                  onClick={handleSendOTP}
                >
                  Resend OTP
                </button>
              )}
              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleVerifyOTP}
              >
                Verify OTP
              </button>
            </>
          )}

          {step === 3 && (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Enter your new password.
              </p>
              <div className="relative mb-4">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="New Password*"
                  placeholder="Enter your new password"
                  id="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setNewPassword(newValue);
                    validatePasswords(newValue, confirmPassword);
                  }}
                />
                <button
                  type="button"
                  className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  <Eye closed={!showNewPassword} />
                </button>
              </div>

              <div className="relative mb-4">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Confirm Password*"
                  placeholder="Confirm your new password"
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setConfirmPassword(newValue);
                    validatePasswords(newPassword, newValue);
                  }}
                />
                <button
                  type="button"
                  className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <Eye closed={!showConfirmPassword} />
                </button>
              </div>

              {passwordValidation.error && (
                <p className="text-red-500 text-sm mb-4">{passwordValidation.error}</p>
              )}

              <button
                className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={handleResetPassword}
              >
                Reset Password
              </button>
            </>
          )}
        </motion.div>
        <Toaster />
      </div>
    </div>
  );
}
