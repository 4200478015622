import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import InputField from "components/fields/InputField";
import toast, { Toaster } from 'react-hot-toast';
import { useState, useEffect } from 'react';
import { checkEntity, loginMobile } from '../../../redux/actions/auth';
import { getOpenSetting } from '../../../redux/actions/admin/setting';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Eye from "components/icons/Eye";

// Add this constant before the SignIn component
const containerVariants = {
  hidden: { 
    opacity: 0,
    y: 20
  },
  visible: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: "easeOut"
    }
  }
};

export default function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((auth) => auth.auth.value.isLoggedIn);
  const status = useSelector((auth) => auth.auth.value.status);
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [businessLogo, setBusinessLogo] = useState("");
  const [validation, setValidation] = useState({ valid: true, error: "" });
  const [institutionCode, setInstitutionCode] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const instituteCode = searchParams.get("code");
  const [showPassword, setShowPassword] = useState(false);

  const handleInstitutionCodeSubmit = async () => {
    const action = await dispatch(checkEntity({ code: instituteCode || institutionCode }));
    if (action.payload.auth) {
      notifySuccess("Valid institute code");
    } else {
      notifyError("Invalid institute code");
    }

    const apicall = await dispatch(getOpenSetting(instituteCode || institutionCode));
    setBusinessLogo(apicall.payload.profile);
    if (action.payload.auth) {
      setValidation({ valid: true, error: "" });
      return setShowLogin(true);
    }
    notifyError(action.payload.message);
  };

  const validateMobileNumber = (number) => {
    const mobileRegex = /^[0-9]{10}$/;
    if (!number) {
      setValidation({ valid: false, error: "Mobile number is required" });
      return false;
    }
    if (!mobileRegex.test(number)) {
      setValidation({ valid: false, error: "Please enter a valid 10-digit mobile number" });
      return false;
    }
    setValidation({ valid: true, error: "" });
    return true;
  };

  const validatePassword = (pass) => {
    if (!pass) {
      setValidation({ valid: false, error: "Password is required" });
      return false;
    }
    // if (pass.length < 8) {
    //   setValidation({ valid: false, error: "Password must be at least 8 characters long" });
    //   return false;
    // }
    setValidation({ valid: true, error: "" });
    return true;
  };

  const handleLogin = async () => {
    // Validate mobile number
    if (!validateMobileNumber(mobileNumber)) {
      notifyError(validation.error);
      return;
    }

    // Validate password
    if (!validatePassword(password)) {
      notifyError(validation.error);
      return;
    }

    try {
      const loginCall = await dispatch(loginMobile({ 
        mobileNumber, 
        password, 
        code: instituteCode || institutionCode 
      }));

      if (!loginCall.payload.auth) {
        notifyError(loginCall.payload.message || "Login failed");
        return;
      }

      notifySuccess("Login successful");
      
      if (loginCall.payload.user) {
        localStorage.setItem('user', JSON.stringify(loginCall.payload.user));
      }

      navigate(loginCall.payload.user.role.name === "Parent" ? "/parent/home" : "/admin/default");
    } catch (error) {
      console.error("Login error:", error);
      notifyError(error.response?.data?.message || "An error occurred during login");
    }
  };

  useEffect(() => {
    if (instituteCode) {
      handleInstitutionCodeSubmit();
      setInstitutionCode(instituteCode);
    }
  }, [instituteCode]);

  const notifySuccess = (message) => toast.success(message, {
    style: { padding: '35px', color: '#a0ca00' },
    iconTheme: { primary: '#a0ca00', secondary: '#222c25' }
  });

  const notifyError = (message) => toast.error(message, {
    style: { border: '1px solid #fff', padding: '35px', color: 'red' },
    iconTheme: { primary: 'red', secondary: '#fff' }
  });

  return (
    <div className="mb-5 flex flex-col items-center justify-center p-5 lg:flex-row lg:justify-start shadow-lg rounded-lg">
      <div className="w-full max-w-full flex flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {!showLogin ? (
          <>
            <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white">Sign In</h4>
            <p className="mb-5 ml-1 text-base text-gray-600 text-center">Enter your institution code to proceed!</p>

            <InputField
              variant="auth"
              extra="mb-3 w-full"
              label="Institution Code*"
              placeholder="Enter your institution code"
              id="institutionCode"
              type="text"
              value={institutionCode}
              onChange={(e) => setInstitutionCode(e.target.value.toLowerCase())}
            />
            <button onClick={handleInstitutionCodeSubmit}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Proceed
            </button>
          </>
        ) : (
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
          >
            <h4 className="mb-2.5 text-4xl font-bold text-center text-navy-700 dark:text-white mt-10">Sign In</h4>
            <p className="mb-5 ml-1 text-base text-gray-600 text-center">Enter your mobile number and password to sign in!</p>

            <InputField
              variant="auth"
              extra="mb-3"
              label="Mobile Number*"
              placeholder="Enter your 10-digit mobile number"
              id="mobileNumber"
              type="tel"
              maxLength="10"
              value={mobileNumber}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                setMobileNumber(value);
                validateMobileNumber(value);
              }}
            />

            <div className="relative">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Enter your password"
                id="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
              />
              <button
                type="button"
                className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
                onClick={() => setShowPassword(!showPassword)}
              >
                <Eye closed={!showPassword} />
              </button>
            </div>

            <div className="mb-4 flex items-center justify-end">
              <Link to={`/auth/forget-password?code=${institutionCode}`} className="text-sm text-brand-500 hover:text-brand-600">
                Forgot Password?
              </Link>
            </div>

            <button onClick={handleLogin}
              className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Sign In
            </button>
          </motion.div>
        )}
        <Toaster />
      </div>
    </div>
  );
}
