import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getUsers = createAsyncThunk("user/get", async (query) => {
    console.log(query)
    let filteredQuery = Object.fromEntries(
      Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/user/?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  console.log(json);
  return json;
});


export const checkParentExist = createAsyncThunk("parent/check", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/open/admission/checkparent/${data.code}/${data.mobile}`, {
    method: 'POST',
    // headers: createHeaders(),
    // body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const addUser = createAsyncThunk("user/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/user/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});


export const updateUser = createAsyncThunk("user/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/user/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const deleteUser = createAsyncThunk("user/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/user/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const resendPasswordEmail = createAsyncThunk("user/resendmail", async (userId) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/user/resendemail`, {
    method: 'POST',
    body: JSON.stringify({userId}),
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const resetPassword = createAsyncThunk("user/resetpassword", async (user) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/user/resetpassword`, {
    method: 'POST',
    body: JSON.stringify({ ...user }),
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: '',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        console.log(action.payload, "DATA FETCHED")
        state.value.data.rows = action.payload.rows
        state.value.data.count = action.payload.count
        
        // console.log('Updated state:', state);
      })
      .addCase(getUsers.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getUsers.rejected, (state) => {
        state.status = "Failed to  fetch data..."
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        // find the index of the state with the updated id
        // const index = state.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the state at the found index with the updated state
        //   state.value.data[index] = action.payload;
        // }
      })
      .addCase(updateUser.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateUser.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        // filter out the state with the deleted id
        // state.value.data = state.value.data.filter(item => item.id !== action.payload.id);
      })
      .addCase(deleteUser.pending, (state) => {
        state.status = "Deleting state. Please wait a moment...";
      })
      .addCase(deleteUser.rejected, (state) => {
        state.status = "Failed to delete state...";
      })
      .addCase(addUser.fulfilled, (state, action) => {
        // state.value.data.push(action.payload); // add the new state to the list
     })
     .addCase(addUser.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
     })
     .addCase(addUser.rejected, (state) => {
        state.status = "Failed to add state...";
     });
  }
})

export default usersSlice.reducer
