import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getExamMasters = createAsyncThunk("exammaster/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exammaster?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addExamMaster = createAsyncThunk("exammaster/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/exammaster/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateExamMaster = createAsyncThunk("exammaster/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exammaster/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteExamMaster = createAsyncThunk("exammaster/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/exammaster/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const downloadMarkSheets = createAsyncThunk(
    'exammaster/downloadMarkSheets',
    async (exammasterId, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}admin/api/exammaster/mark-sheets/${exammasterId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...createHeaders(),
                    // Add any auth headers if needed
                },
            });

            if (!response.ok) {
                throw new Error('Failed to download mark sheets');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `mark-sheets-${exammasterId}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);

            return true;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const exammastersSlice = createSlice({
  name: 'exammasteres',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExamMasters.fulfilled, (exammaster, action) => {
        console.log()
     
        exammaster.value.data.rows = action.payload.rows
        exammaster.value.data.count = action.payload.count
        exammaster.value.status = action.payload.message
        console.log('Updated exammaster:', exammaster.value.data.rows);

        
      })
      .addCase(getExamMasters.pending, (exammaster) => {
        exammaster.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getExamMasters.rejected, (exammaster) => {
        exammaster.status = "Failed to  fetch data..."
      })
      .addCase(updateExamMaster.fulfilled, (exammaster, action) => {
        console.log(action.payload.message, "action.payload.message")
        exammaster.value.status = action.payload.message
        // find the index of the exammaster with the updated id
        // const index = exammaster.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the exammaster at the found index with the updated exammaster
        //   exammaster.value.data[index] = action.payload;
        // }
      })
      .addCase(updateExamMaster.pending, (exammaster) => {
        exammaster.status = "Updating exammaster. Please wait a moment...";
      })
      .addCase(updateExamMaster.rejected, (exammaster) => {
        exammaster.status = "Failed to update exammaster...";
      })
      .addCase(deleteExamMaster.fulfilled, (exammaster, action) => {
        // // filter out the exammaster with the deleted id
        // exammaster.value.data = exammaster.value.data.filter(item => item.id !== action.payload.id);
        exammaster.value.status = action.payload.message
      })
      .addCase(deleteExamMaster.pending, (exammaster) => {
        exammaster.status = "Deleting exammaster. Please wait a moment...";
      })
      .addCase(deleteExamMaster.rejected, (exammaster) => {
        exammaster.status = "Failed to delete exammaster...";
      })
      .addCase(addExamMaster.fulfilled, (exammaster, action) => {
        // exammaster.value.data.push(action.payload); // add the new exammaster to the list
     })
     .addCase(addExamMaster.pending, (exammaster) => {
        exammaster.status = "Adding exammaster. Please wait a moment...";
     })
     .addCase(addExamMaster.rejected, (exammaster) => {
        exammaster.status = "Failed to add exammaster...";
     });
  }
})

export default exammastersSlice.reducer
