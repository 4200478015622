import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getCustomRecords = createAsyncThunk("customrecord/get", async (query) => {
  console.log(query)
  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  console.log(json);
  return json;
});

export const getCustomRecordData = createAsyncThunk("customrecorddata/get", async (query) => {
  console.log(query)
  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
  );
  let params = new URLSearchParams(filteredQuery);
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/data?${params.toString()}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  console.log(json);
  return json;
});

export const getAllCustomRecords = createAsyncThunk("customrecord/getall", async () => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/all`, {
    headers: createHeaders()
  });
  let json = await response.json();
  console.log(json);
  return json;
});

export const getCustomRecordField = createAsyncThunk("customrecord/getfields", async (id) => {
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/id/${id}`, {
    headers: createHeaders()
  });
  let json = await response.json();
  console.log(json);
  return json;
});

export const getCustomRecord = createAsyncThunk("singlecustomrecord/get", async (customrecordId) => {
  try {
    let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/${customrecordId}`, {
      headers: createHeaders()
    });
    let json = await response.json();
    console.log(json);
    return json;
  } catch (err) {
    console.log(err)
  }
});

export const addCustomRecord = createAsyncThunk("customrecord/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});


export const addCustomRecordData = createAsyncThunk("customrecorddata/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/adddata`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});


export const updateCustomRecord = createAsyncThunk("customrecord/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});

export const deleteCustomRecord = createAsyncThunk("customrecord/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/customrecord/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const customrecordsSlice = createSlice({
  name: 'customrecords',
  initialState: {
    value: {
      isError: false,
      singleCustomRecordField: {},
      customRecordData: {
        count: 0,
         rows: []
      },
      isLoading: false,
      status: '',
      allRecords: [],
      data: {
        count: 0,
        rows: []
      }
    }
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomRecords.fulfilled, (state, action) => {
        console.log(action.payload, "DATA FETCHED")
        state.value.data.rows = action.payload.rows
        state.value.data.count = action.payload.count

        // console.log('Updated state:', state);
      })
      .addCase(getCustomRecordField.fulfilled, (state, action) => {
        state.value.singleCustomRecordField = action.payload
      })
      .addCase(getCustomRecordData.fulfilled, (state, action) => {
        state.value.customRecordData = action.payload
      })
      .addCase(getCustomRecords.pending, (state) => {
        state.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getCustomRecords.rejected, (state) => {
        state.status = "Failed to  fetch data..."
      })
      .addCase(getAllCustomRecords.fulfilled, (state, action) => {
          state.value.allRecords = action.payload

      })
      .addCase(updateCustomRecord.fulfilled, (state, action) => {
        // find the index of the state with the updated id
        // const index = state.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the state at the found index with the updated state
        //   state.value.data[index] = action.payload;
        // }
      })
      .addCase(updateCustomRecord.pending, (state) => {
        state.status = "Updating state. Please wait a moment...";
      })
      .addCase(updateCustomRecord.rejected, (state) => {
        state.status = "Failed to update state...";
      })
      .addCase(deleteCustomRecord.fulfilled, (state, action) => {
        // filter out the state with the deleted id
        // state.value.data = state.value.data.filter(item => item.id !== action.payload.id);
      })
      .addCase(deleteCustomRecord.pending, (state) => {
        state.status = "Deleting state. Please wait a moment...";
      })
      .addCase(deleteCustomRecord.rejected, (state) => {
        state.status = "Failed to delete state...";
      })
      .addCase(addCustomRecord.fulfilled, (state, action) => {
        // state.value.data.push(action.payload); // add the new state to the list
      })
      .addCase(addCustomRecord.pending, (state) => {
        state.status = "Adding state. Please wait a moment...";
      })
      .addCase(addCustomRecord.rejected, (state) => {
        state.status = "Failed to add state...";
      });
  }
})

export default customrecordsSlice.reducer
