
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getResults = createAsyncThunk("result/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/result?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addResult = createAsyncThunk("result/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/result/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const multiResult = createAsyncThunk("multiresult/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/result/multiresults`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateResult = createAsyncThunk("result/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/result/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteResult = createAsyncThunk("result/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/result/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const resultsSlice = createSlice({
  name: 'resultes',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResults.fulfilled, (result, action) => {
        console.log()
     
        result.value.data.rows = action.payload.rows
        result.value.data.count = action.payload.count
        result.value.status = action.payload.message
        console.log('Updated result:', result.value.data.rows);

        
      })
      .addCase(getResults.pending, (result) => {
        result.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getResults.rejected, (result) => {
        result.status = "Failed to  fetch data..."
      })
      .addCase(updateResult.fulfilled, (result, action) => {
        console.log(action.payload.message, "action.payload.message")
        result.value.status = action.payload.message
        // find the index of the result with the updated id
        // const index = result.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the result at the found index with the updated result
        //   result.value.data[index] = action.payload;
        // }
      })
      .addCase(updateResult.pending, (result) => {
        result.status = "Updating result. Please wait a moment...";
      })
      .addCase(updateResult.rejected, (result) => {
        result.status = "Failed to update result...";
      })
      .addCase(deleteResult.fulfilled, (result, action) => {
        // // filter out the result with the deleted id
        // result.value.data = result.value.data.filter(item => item.id !== action.payload.id);
        result.value.status = action.payload.message
      })
      .addCase(deleteResult.pending, (result) => {
        result.status = "Deleting result. Please wait a moment...";
      })
      .addCase(deleteResult.rejected, (result) => {
        result.status = "Failed to delete result...";
      })
      .addCase(addResult.fulfilled, (result, action) => {
        // result.value.data.push(action.payload); // add the new result to the list
     })
     .addCase(addResult.pending, (result) => {
        result.status = "Adding result. Please wait a moment...";
     })
     .addCase(addResult.rejected, (result) => {
        result.status = "Failed to add result...";
     });
  }
})

export default resultsSlice.reducer
