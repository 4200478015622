import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers } from '../../../redux/actions/admin/user';
import { getClasses } from '../../../redux/actions/admin/classRoom';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';

import {
  FiSearch,
  FiTrendingUp,
  FiX,
  FiEdit2,
  FiTrash,
  FiSend,
} from "react-icons/fi";

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const Parent = (props) => {
  const [formData, setFormData] = useState({});
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 10,
    page: 1,
    role: 'Parent'
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const users = useSelector((users) => users.user.value.data);
  const classes = useSelector((classes) => classes.classRoom.value.data);

  useEffect(() => {
    dispatch(getUsers(filter));
    dispatch(getClasses({ limit: 100 }));
  }, [dispatch, filter]);

  const handleActionButtonClick = (userId, action) => {
    if (action === 'edit') setIsDialogOpen(true);
    if (action === 'delete') setIsDeleteDialogOpen(true);
    if (userId == null) {
      setIsDialogOpen(true);
      return setFormData({});
    }

    const selectedUser = users.rows.find((user) => user.id === userId);
    setFormData(selectedUser);
  };

  const fields = [
    {
      id: "username",
      name: "username",
      label: "Parent Name",
      placeholder: "Enter Parent Name",
      type: "text",
    },
    {
      id: "Status",
      name: "status",
      label: "Status",
      desc: "Set status as active/inactive",
      type: "switch",
    },
    {
      id: "email",
      name: "email",
      label: "Email",
      placeholder: "Enter Parent Email",
      type: "mail",
    },
    {
      id: "mobile",
      name: "mobile",
      label: "Mobile",
      placeholder: "Enter Parent Mobile",
      type: "number",
    },
    {
      id: "occupation",
      name: "occupation",
      label: "Occupation",
      placeholder: "Enter Parent Occupation",
      type: "text",
    },
    {
      id: "address",
      name: "address",
      label: "Address",
      placeholder: "Enter Parent Address",
      type: "textarea",
    },
    {
      id: "students",
      name: "students",
      label: "Children",
      type: "multiselect",
      toSelect: ["id"],
      options: classes.rows ? classes.rows : [], // This should be replaced with students list
    },
  ];

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
          <Table className="border-b border-grey-500">
            <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
              <div className='flex justify-between items-center'>
                <div className='flex justify-between w-1/2 items-center'>
                  <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                      type="text"
                      placeholder="Search parents..."
                      className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                  <DropdownSelect
                    list={[
                      { name: "All", id: 1 },
                      { name: "Active", id: 2 },
                      { name: "Inactive", id: 3 },
                    ]}
                    triggerStyles="bg-lightPrimary !rounded-full"
                    placeHolder={"Status"}
                    toSelect={"name"}
                    onChange={(value) => setFilter({ ...filter, status: value })}
                  />
                </div>
                <div className='flex justify-end'>
                  <button
                    onClick={() => handleActionButtonClick(null, "add")}
                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                    Add Parent
                  </button>
                </div>
              </div>
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3">Parent Details</TableHead>
                <TableHead className="w-1/5">Children</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-1/6 text-center">Actions</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {users.rows.length > 0 && users.rows.map((user) => (
                <TableRow key={user.id}>
                  <TableCell className="font-medium">
                    <h4 className='text-brand-500 font-black'>{user.fullname}</h4>
                    <p>{user.email}</p>
                    <p>{user.mobile}</p>
                    <p>{user.occupation}</p>
                  </TableCell>
                  <TableCell className="font-medium">
                    {user.students && user.students.length > 0 ? (
                      <>
                        <div className="font-bold text-brand-500 mb-1">
                          {user.students.length} {user.students.length === 1 ? 'Child' : 'Children'}
                        </div>
                        <div className="text-sm text-gray-600">
                          {user.students.map(student => student.admission?.name).join(', ')}
                        </div>
                      </>
                    ) : (
                      <span className="text-gray-500">No children</span>
                    )}
                  </TableCell>
                  <TableCell>
                    {user.status ? (
                      <div className="flex items-center justify-start w-fit bg-green-500 rounded-2xl">
                        <FiTrendingUp className="h-6 w-6 text-white p-1" />
                      </div>
                    ) : (
                      <div className="flex items-center justify-start w-fit rounded-xl bg-red-500">
                        <FiX className="h-6 w-6 text-white p-1" />
                      </div>
                    )}
                  </TableCell>
                  <TableCell className="w-1/6">
                    <div className='w-full flex justify-around'>
                      <div
                        title="Update Parent"
                        onClick={() => handleActionButtonClick(user.id, "edit")}
                        className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer'>
                        <FiEdit2 className='h-4 w-4 text-white' />
                      </div>
                      <div
                        title="delete"
                        onClick={() => handleActionButtonClick(user.id, "delete")}
                        className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                        <FiTrash className='h-4 w-4 text-white' />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {users.rows.length > 0 && (
            <Paginate
              page={filter.page}
              totalCount={users.count}
              limit={filter.limit}
              changePage={(value) => setFilter({ ...filter, page: value })}
            />
          )}

          {users.rows.length === 0 && (
            <div className='p-10 flex flex-col justify-center items-center'>
              <h4 className='mb-5 text-2xl text-brand-500 font-medium'>No Parents Added! Click below to add one.</h4>
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                Add Parent
              </button>
            </div>
          )}

          <DialogCustom
            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle={formData.id ? "Edit Parent" : "Add Parent"}
            dialogWidth="w-1/2"
            dialogDesc="Update the necessary fields and save."
            content={
              <>
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                  <button
                    onClick={() => {
                      // Handle save/update
                      setIsDialogOpen(false);
                    }}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white active:!bg-white/70">
                    {formData.id ? "Update" : "Add"}
                  </button>
                </div>
              </>
            }
          />

          <DialogCustom
            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete Parent"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this parent?"
            content={
              <div className='flex flex-row-reverse'>
                <button
                  onClick={() => {
                    // Handle delete
                    setIsDeleteDialogOpen(false);
                  }}
                  className="text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg">
                  Delete
                </button>
                <button
                  onClick={() => setIsDeleteDialogOpen(false)}
                  className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg">
                  Cancel
                </button>
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Parent; 