import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FiCalendar, FiChevronLeft } from "react-icons/fi";
import { addPeriod, getPeriod, updatePeriod, getPeriodByDate } from '../../redux/actions/admin/period';
import { getCourses, getCourseStudents, clearStudents } from '../../redux/actions/admin/course';
import toast from 'react-hot-toast';

function CrudAttendance() {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const isAddMode = location.pathname.endsWith('/add');
    const dispatch = useDispatch();
    const courses = useSelector((courses) => courses.course.value.data);

    // States
    const [formData, setFormData] = useState({
        courseId: '',
        date: new Date()
    });
    const [studentAttendance, setStudentAttendance] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [existingPeriod, setExistingPeriod] = useState(null);

    // Fetch initial data
    useEffect(() => {
        const initializeData = async () => {
            try {
                dispatch(getCourses({ limit: 100 }))
                dispatch(clearStudents())
                if (!isAddMode && id) {
                    // Fetch period data
                    const periodResponse = await dispatch(getPeriod(id)).unwrap();
                    setExistingPeriod(periodResponse);
                    
                    // Set form data
                    setFormData({
                        courseId: periodResponse.courseId,
                        date: new Date(periodResponse.from)
                    });

                    // Fetch students for this course
                    const studentsResponse = await dispatch(getCourseStudents(periodResponse.courseId)).unwrap();
                    
                    // Initialize attendance array with existing data
                    const initialAttendance = studentsResponse.data.map(enrollment => ({
                        studentId: enrollment.student.id,
                        studentName: enrollment.student.admission.name,
                        isPresent: periodResponse.attendances.some(
                            a => a.studentId === enrollment.student.id && a.status === 'PRESENT'
                        )
                    }));

                    setStudentAttendance(initialAttendance);
                } else {
                    // For add mode, check for existing attendance on the current date
                    const currentDate = new Date();
                    setFormData(prev => ({ ...prev, date: currentDate }));
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Initialization error:', error);
                toast.error('Failed to load data');
                setIsLoading(false);
            }
        };

        initializeData();
    }, [dispatch, id, isAddMode]);

    // Add effect to check for existing attendance when in add mode
    useEffect(() => {
        if (isAddMode && formData.courseId && formData.date) {
            handleDateChange(formData.date);
        }
    }, [formData.courseId, isAddMode]); // Only run when courseId changes in add mode

    // Compute the period name based on date and selected course
    const computePeriodName = () => {
        if (!formData.courseId) return '';
        const selectedCourse = courses?.rows?.find(course => course.id === formData.courseId);
        const formattedDate = moment(formData.date).format('DD MMM YYYY');
        return `${selectedCourse?.name || ''} • ${formattedDate}`;
    };

    // Handle course change for add mode
    const handleCourseChange = async (courseId) => {
        setFormData(prev => ({ ...prev, courseId }));
        
        if (courseId) {
            // Call handleDateChange with current date to check for existing records
            await handleDateChange(formData.date);
        } else {
            setStudentAttendance([]);
            setExistingPeriod(null);
        }
    };

    // Add date change handler
    const handleDateChange = async (newDate) => {
        setFormData(prev => ({ ...prev, date: new Date(newDate) }));
        
        if (formData.courseId) {
            try {
                const formattedDate = moment(newDate).format('YYYY-MM-DD');
                const response = await dispatch(getPeriodByDate({ 
                    date: formattedDate, 
                    courseId: formData.courseId 
                }));

                const existingPeriodResponse = response.payload;

                if (existingPeriodResponse && existingPeriodResponse.id) {
                    // Redirect to the existing attendance page
                    navigate(`/parent/attendance/${existingPeriodResponse.id}`);
                    return;
                }

                // Only fetch students if no existing period found
                const studentsResponse = await dispatch(getCourseStudents(formData.courseId));
                const students = studentsResponse.payload.data;
                const newAttendance = students.map(enrollment => ({
                    studentId: enrollment.student.id,
                    studentName: enrollment.student.admission.name,
                    isPresent: false
                }));
                setStudentAttendance(newAttendance);
                setExistingPeriod(null);

            } catch (error) {
                console.error('Failed to check existing period:', error);
                toast.error('Failed to check existing attendance');
            }
        }
    };

    // Toggle individual attendance
    const handleAttendanceToggle = (studentId) => {
        setStudentAttendance(prev => 
            prev.map(student => 
                student.studentId === studentId 
                    ? { ...student, isPresent: !student.isPresent }
                    : student
            )
        );
    };

    // Toggle all attendance
    const handleMarkAll = () => {
        const allPresent = studentAttendance.every(student => student.isPresent);
        setStudentAttendance(prev => 
            prev.map(student => ({ ...student, isPresent: !allPresent }))
        );
    };

    // Submit form
    const handleSubmit = async () => {
        try {
            const periodData = {
                ...(id && { id }),
                ...formData,
                name: computePeriodName(),
                date: moment(formData.date).format('YYYY-MM-DD'),
                attendance: studentAttendance.map(student => ({
                    studentId: student.studentId,
                    status: student.isPresent ? 'PRESENT' : 'ABSENT'
                }))
            };

            if (isAddMode) {
                await dispatch(addPeriod(periodData));
                toast.success('Attendance marked successfully');
            } else {
                await dispatch(updatePeriod(periodData));
                toast.success('Attendance updated successfully');
            }
            
            navigate('/parent/classes');
        } catch (error) {
            console.error('Submission error:', error);
            toast.error(isAddMode ? 'Failed to mark attendance' : 'Failed to update attendance');
        }
    };

    if (isLoading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-brand-500"></div>
            </div>
        );
    }

    return (
        <div className="bg-lightPrimary min-h-screen">
            {/* Header */}
            <div className="bg-white p-4 shadow-sm flex items-center gap-3 sticky top-0 z-10">
                <button 
                    onClick={() => navigate('/parent/classes')}
                    className="p-2 hover:bg-gray-100 rounded-full"
                >
                    <FiChevronLeft className="w-6 h-6" />
                </button>
                <div>
                    <h1 className="text-xl font-medium">
                        {isAddMode ? 'Mark Attendance' : 'Update Attendance'}
                    </h1>
                    {formData.courseId && (
                        <p className="text-sm text-gray-500 mt-0.5">
                            {computePeriodName()}
                        </p>
                    )}
                </div>
            </div>

            <div className="p-4 space-y-4">
                {/* Period Details */}
                <div className="bg-white rounded-xl p-4 space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Course
                        </label>
                        <select
                            value={formData.courseId}
                            onChange={(e) => handleCourseChange(e.target.value)}
                            className={`w-full p-3 rounded-lg border border-gray-300 ${
                                !isAddMode ? 'bg-gray-100 cursor-not-allowed' : 'focus:border-brand-500'
                            }`}
                            disabled={!isAddMode}
                        >
                            <option value="">Select Course</option>
                            {courses?.rows?.map(course => (
                                <option key={course.id} value={course.id}>
                                    {course.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                            Date
                        </label>
                        <div className="relative">
                            <input
                                type="date"
                                value={moment(formData.date).format('YYYY-MM-DD')}
                                onChange={(e) => handleDateChange(e.target.value)}
                                max={moment().format('YYYY-MM-DD')}
                                className={`w-full p-3 rounded-lg border border-gray-300 ${
                                    !isAddMode ? 'bg-gray-100 cursor-not-allowed' : 'focus:border-brand-500'
                                }`}
                                disabled={!isAddMode}
                            />
                            <FiCalendar className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" />
                        </div>
                    </div>

                    {/* Add attendance summary and creator info */}
                    {!isAddMode && (
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <div className="space-y-4">
                                {/* Attendance Summary */}
                                <div className="border-b border-gray-200 pb-4">
                                    <h3 className="text-sm font-semibold text-gray-800 mb-3">
                                        Attendance Summary
                                    </h3>
                                    <div className="flex gap-4">
                                        <div className="flex flex-col items-center bg-green-50 px-4 py-3 rounded-lg border border-green-200 w-full">
                                            <span className="text-2xl font-bold text-green-700">
                                                {studentAttendance.filter(s => s.isPresent).length}
                                            </span>
                                            <span className="text-green-700">Present</span>
                                        </div>
                                        <div className="flex flex-col items-center bg-red-50 px-4 py-3 rounded-lg border border-red-200 w-full">
                                            <span className="text-2xl font-bold text-red-700">
                                                {studentAttendance.filter(s => !s.isPresent).length}
                                            </span>
                                            <span className="text-red-700">Absent</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Created By */}
                                <div className="flex justify-between">
                                    <div>
                                    <h3 className="text-sm font-semibold text-gray-800 mb-1">
                                        Created By
                                    </h3>
                                    <div className="text-gray-600">
                                        <p className="font-medium">{existingPeriod?.createdBy || 'N/A'}</p>
                                        <p className="text-sm text-gray-500">
                                            {existingPeriod?.createdAt 
                                                ? moment(existingPeriod.createdAt).format('DD MMM YYYY, h:mm A')
                                                : 'N/A'
                                            }
                                        </p>
                                    </div>

                                    </div>
                                    <div>
                                    <h3 className="text-sm font-semibold text-gray-800 mb-1">
                                        Updated By
                                    </h3>
                                    <div className="text-gray-600">
                                        <p className="font-medium">{existingPeriod?.updatedBy || 'N/A'}</p>
                                        <p className="text-sm text-gray-500">
                                            {existingPeriod?.updatedAt 
                                                ? moment(existingPeriod.updatedAt).format('DD MMM YYYY, h:mm A')
                                                : 'N/A'
                                            }
                                        </p>
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* Attendance Section */}
                {studentAttendance.length > 0 && (
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <h2 className="font-medium text-lg">Mark Attendance</h2>
                            {/* <button
                                onClick={handleMarkAll}
                                className={`px-4 py-2 rounded-lg font-medium ${
                                    studentAttendance.every(s => s.isPresent)
                                        ? 'bg-red-100 text-red-700'
                                        : 'bg-green-100 text-green-700'
                                }`}
                            >
                                {studentAttendance.every(s => s.isPresent) 
                                    ? 'Mark All Absent' 
                                    : 'Mark All Present'}
                            </button> */}
                        </div>

                        <div className="space-y-3">
                            {studentAttendance.map(student => (
                                <div 
                                    key={student.studentId}
                                    className="bg-white rounded-xl overflow-hidden shadow-sm"
                                >
                                    <div className="p-4 flex items-center justify-between">
                                        <span className="font-medium">{student.studentName}</span>
                                        <button
                                            onClick={() => handleAttendanceToggle(student.studentId)}
                                            className={`px-4 py-2 rounded-lg font-medium ${
                                                student.isPresent
                                                    ? 'bg-green-100 text-green-700'
                                                    : 'bg-red-100 text-red-700'
                                            }`}
                                        >
                                            {student.isPresent ? 'Present' : 'Absent'}
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <button
                            onClick={handleSubmit}
                            className="w-full p-3 rounded-xl text-white font-medium bg-brand-500 active:bg-brand-600 sticky bottom-4"
                        >
                            {isAddMode ? 'Mark Attendance' : 'Update Attendance'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CrudAttendance;