import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';

export const scheduleMeeting = createAsyncThunk("meeting/schedule", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/meeting/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});

export const meetingsSlice = createSlice({
  name: 'meetings',
  initialState: {
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(scheduleMeeting.fulfilled, (state, action) => {
        state.value.status = action.payload.message;
      })
      .addCase(scheduleMeeting.pending, (state) => {
        state.value.isLoading = true;
      })
      .addCase(scheduleMeeting.rejected, (state) => {
        state.value.isError = true;
        state.value.isLoading = false;
      });
  }
});

export default meetingsSlice.reducer; 