import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCustomRecordField, getCustomRecordData, addCustomRecordData } from '../../../redux/actions/admin/customrecord';
import toast, { Toaster } from 'react-hot-toast';
import Paginate from 'components/paginate';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableFooter,
    TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

const CustomRecordField = (props) => {
    const location = useLocation();
    const recordId = location.pathname.split("customrecord/")[1];
    // const recordId = window.location.pathname.split("customrecord/")[1]
    console.log(window.location.pathname, "window.location.pathname")
    const [formData, setFormData] = useState({});
    // const [recordId, setRecordId] = useState(null)
    const [filter, setFilter] = useState({
        name: "",
        status: "All",
        limit: 10,
        page: 1,
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    const dispatch = useDispatch();
    const [customFieldForm, setCustomFieldForm] = useState([]);


    // useEffect(() => {
    //     setRecordId(window.location.pathname.split("customrecord/")[1])
    //     console.log("i changed -- ", recordId)
    // }, [window.location.pathname])

    useEffect(() => {
        dispatch(getCustomRecordField(recordId))
        dispatch(getCustomRecordData({ recordId, ...filter }))
    }, [dispatch, recordId])

    const record = useSelector((state) => state.customrecord.value.singleCustomRecordField);
    const fieldData = useSelector((state) => state.customrecord.value.customRecordData);
    if (document.querySelector(".main-page-title")) document.querySelector(".main-page-title").textContent = record.name
    console.log(fieldData, "fieldData")

    useEffect(() => {
        if (record.customrecordfields) {
            setCustomFieldForm(generateCustomFormFields(record.customrecordfields));
        }

    }, [record])


    const handleActionButtonClick = (customRecordId, action) => {
        // if (action === 'edit') setIsDialogOpen(true);
        // if (action === 'delete') setIsDeleteDialogOpen(true);
        if (customRecordId == null) {
            setIsDialogOpen(true)
            return setFormData({})
        }

        const selectedCustomRecordField = record.customrecordfields.find((customRecord) => customRecord.id === customRecordId);
        setFormData(selectedCustomRecordField)
    };


    const handleCrudCustomRecordField = async () => {
        try {
            // console.log(formData, "formData")
            // return 
            setIsDialogOpen(false)
            // if (formData.id) await dispatch(updateCustomRecordField(formData));
            // if (!formData.id) await dispatch(addCustomRecordData(formData));
            notifySuccess("Success")

            setFormData({});
            dispatch(getCustomRecordData({ recordId, ...filter }))

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    const handleDelete = async () => {
        try {
            setIsDeleteDialogOpen(false)
            // await dispatch(deleteCustomRecordField(formData.id));
            // setFormData({});
            notifySuccess("Delete customRecord successfully")
            await dispatch(getCustomRecordField(recordId));

        } catch (error) {
            console.error('Failed to add state:', error);
        }
    };

    // Helper function to map datatype to input type
    const mapDatatypeToInputType = (datatype) => {
        switch (datatype) {
            case 'Text':
                return 'text';
            case 'Time':
                return 'time';
            default:
                return 'text'; // default type if datatype doesn't match
        }
    };

    const generateCustomFormFields = (fields) => {
        if (fields && fields.length == 0) return {}
        return fields.map(field => ({
            id: field.id,
            name: field.name,
            label: field.name,
            type: mapDatatypeToInputType(field.datatype),
            placeholder: `Enter ${field.name}`
        }));
    };

    // Example usage:

    // console.log(customFieldsForm, "customFieldsForm")
    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    <Widget
                        icon={<FiLayers className="h-7 w-7" />}
                        subtitle={customRecords.count}
                        styling={
                            {
                                iconContStyles: "bg-brand-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-yellow-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiTrendingUp className="h-7 w-7" />}
                        subtitle={customRecords.activeCount}
                        styling={
                            {
                                iconContStyles: "bg-green-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-green-100"
                            }
                        }
                    />
                    <Widget
                        icon={<FiX className="h-7 w-7" />}
                        subtitle={customRecords.inactiveCount}
                        styling={
                            {
                                iconContStyles: "bg-red-500",
                                iconStyles: "text-white",
                                border: "border-solid border-2 border-red-100"
                            }
                        }
                    />


                </div> */}

                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>

                    {
                        fieldData?.rows?.length &&
                        <>
                            <Table className="border-b border-grey-500" columns={Object.keys(JSON.parse(fieldData.rows[0].data)).length + 1}>
                                <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                                    <div className="flex justify-between items-center">
                                        <div className="flex justify-between w-1/2 items-center">
                                            {/* <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
                                                <p className="pl-3 pr-2 text-xl">
                                                    <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                                </p>
                                                <input
                                                    onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                                                    type="text"
                                                    placeholder="Search..."
                                                    className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                                />
                                            </div> */}
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() => handleActionButtonClick(null, 'add')}
                                                className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70">
                                                Add {record.name}
                                            </button>
                                        </div>
                                    </div>
                                </TableCaption>

                                {fieldData.rows.length > 0 && (
                                    <>
                                        <TableHeader>
                                            <TableRow className="dark:text-white">
                                                {Object.keys(JSON.parse(fieldData.rows[0].data)).map((key) => (
                                                    <TableHead key={key}>{key}</TableHead>
                                                ))}
                                                <TableHead className="text-center"> Created Time </TableHead>
                                                {/* <TableHead className="text-center">Actions</TableHead> */}
                                            </TableRow>
                                        </TableHeader>

                                        <TableBody>
                                            {fieldData.rows.map((customRecord) => {
                                                const parsedData = JSON.parse(customRecord.data);

                                                return (
                                                    <TableRow key={customRecord.id}>
                                                        {Object.values(parsedData).map((value, index) => (
                                                            <TableCell key={index}>
                                                                <h4>{value}</h4>
                                                            </TableCell>
                                                        ))}
                                                        <TableCell className="text-center">
                                                            {/* <h4>  {customRecord.createdAt} </h4> */}
                                                            <h4>  {moment(customRecord.createdAt).format("DD/MM/YYYY HH:mm")} </h4>
                                                        </TableCell>
                                                        {/* <TableCell className="text-center">
                                                            <div className="flex justify-around">
                                                                <div
                                                                    onClick={() => handleActionButtonClick(customRecord.id, 'edit')}
                                                                    className="mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer">
                                                                    <FiEdit2 className="h-4 w-4 text-white" />
                                                                </div>
                                                                <div
                                                                    onClick={() => handleActionButtonClick(customRecord.id, 'delete')}
                                                                    className="mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer">
                                                                    <FiTrash className="h-4 w-4 text-white" />
                                                                </div>
                                                            </div>
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>

                                    </>
                                )}
                            </Table>
                            <Paginate
                                page={filter.page}
                                totalCount={fieldData.count}
                                limit={filter.limit}
                                changePage={(value) => setFilter({ ...filter, page: value })}
                            />
                        </>
                    }





                    {fieldData.rows.length == 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No {record.name}s Found! </p>
                            <button
                                onClick={() => handleActionButtonClick(null, "add")}
                                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                                Add {record.name}
                            </button>
                        </div>
                    }

                    <DialogCustom

                        open={isDialogOpen}
                        onOpenChange={setIsDialogOpen}
                        dialogTitle={formData.id ? `Edit ${record.name}` : "Add" + ` ${record.name}`}
                        dialogWidth="w-1/2"
                        dialogDesc={(formData.id ? "Update" : "Add") + " the necessary fields and save."}
                        content={
                            <>
                                <Form
                                    fields={customFieldForm}
                                    formData={formData}
                                    onChange={(newFormData) => setFormData(newFormData)}
                                />
                                <div className='flex justify-end'>
                                    <button
                                        onClick={() => {
                                            dispatch(addCustomRecordData({ fieldData: formData, customrecordId: record.id }))
                                            handleCrudCustomRecordField()
                                        }}
                                        className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                                        {formData.id ? "Update" : "Add" + ` ${record.name}`}
                                    </button>
                                </div></>
                        }
                    />
                    <DialogCustom

                        open={isDeleteDialogOpen}
                        onOpenChange={setIsDeleteDialogOpen}
                        dialogTitle="Delete CustomRecordField"
                        dialogWidth="w-1/2"
                        dialogDesc="Are you sure you want to delete this customRecord ?"
                        content={
                            <div className='flex flex-row-reverse'>
                                <button
                                    onClick={handleDelete}
                                    className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                                    Delete
                                </button>
                                <button
                                    onClick={() => {
                                        setIsDeleteDialogOpen(false);
                                    }}
                                    className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                                    Cancel
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    )
    // return (  " yo " )
};

export default CustomRecordField