import { React, useState, useEffect } from 'react'
import { getExamSets, addExamSet, updateExamSet, clearExamSet } from '../../../../redux/actions/admin/examset';
import { getCourses } from '../../../../redux/actions/admin/course';
import { getSubjects } from '../../../../redux/actions/admin/subject';
import { getExamMasters } from '../../../../redux/actions/admin/exammaster';

import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/shadcn/table";
import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";
import Form from '../../../../components/form';

export const SingleExamSet = (props) => {
    const { setTitle } = props
    const { id } = useParams()
    const [formData, setFormData] = useState({});
    const [examFields, setExamFields] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    console.log(id, "PERIOD ID")
    const navigate = useNavigate();
    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    })
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    })

    // let exams = []
    const examset = useSelector((examsets) => examsets.examset.value.data.rows[0]);
    const exammasters = useSelector((exammasteres) => exammasteres.exammaster.value.data.rows);
    const courses = useSelector((courses) => courses.course.value.data.rows);
    const subjects = useSelector((subjects) => subjects.subject.value.data.rows);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCourses({ limit: 50 }));
        dispatch(getExamMasters({ limit: 50 }));
        
        // Clear previous data first
        dispatch(clearExamSet());
        
        if (id !== "0") {  // Change to string comparison if id is a string
            dispatch(getExamSets({ id }));
        } else {
            setTitle(" Add ExamSet ");
        }
    }, [id]); // Add id to dependency array to reload when id changes

    useEffect(() => {
        if (id != 0 && examset && examset.id) {
            setTitle("ExamSet - " + examset.name)
            setFormData({
                // ...formData,
                id: examset.id,
                courseId: examset.courseId,
                course: examset.course,
                exammasterId: examset.exammasterId,
                exammaster: examset.exammaster,
                name: examset.name
            })
        }
    }, [examset])

    useEffect(() => {
        dispatch(getSubjects({ courseId: formData.courseId }))
        if (!id) {
            console.log("setting course name")
            searchAndSetCourseName(formData.courseId)
        }
    }, [formData.courseId])

    useEffect(() => {
        if (!id) {
            console.log("setting exammaster name")
            searchAndSetExamMasterName(formData.exammasterId)
        }
    }, [formData.exammasterId])


    const searchAndSetCourseName = (id) => {
            let course = courses.find(course => course.id === id);
            console.log("setting")
            setFormData({
                ...formData,
                name: formData.name.split("-")[0] + "- " + course.name
            })
    }
    const searchAndSetExamMasterName = (id) => {
            let exammaster = exammasters.find(exammaster => exammaster.id === id);
            setFormData({
                ...formData,
                name: exammaster.name + " - "
            })
    }
    // Update the onChange event handler for the input field
    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    // Filter the attendances based on the search query
    const filteredResults = examFields.filter(subject => 
        subject.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const fields = [
        {
            id: "exammasterId",
            name: "exammasterId",
            label: "Exam Type",
            placeHolder: "Select Exam",
            type: "dropdown",
            list: exammasters,
            toSelect: "id",
            selectedvalue: "exammaster",
        },
        {
            id: "courseId",
            name: "courseId",
            label: "Course",
            placeHolder: "Select Course",
            type: "dropdown",
            list: courses,
            toSelect: "id",
            selectedvalue: "course",
            // toShow: ['admission', 'name']
        },
        {
            id: "name",
            name: "name",
            label: "Name",
            placeholder: "ExamSet Name",
            type: "text",
        }
    ];

    useEffect(() => {
        if (subjects) {
            let newExamFields;
            
            if (examset?.exams && examset.exams.length > 0) {
                // For update mode: merge existing exam data
                newExamFields = subjects.map(subject => {
                    const existingExam = examset.exams.find(exam => exam.subjectId === subject.id);
                    return {
                        ...subject,
                        examId: existingExam?.id || '',
                        minScore: existingExam?.minScore || '',
                        maxScore: existingExam?.maxScore || '',
                        date: existingExam?.date ? new Date(existingExam.date).toISOString().split('T')[0] : ''
                    };
                });
            } else {
                // For create mode: initialize empty fields
                newExamFields = subjects.map(subject => ({
                    ...subject,
                    examId: '',
                    minScore: '',
                    maxScore: '',
                    date: ''
                }));
            }
            
            setExamFields(newExamFields);
        }
    }, [subjects, examset]); // Dependencies include both subjects and examset

    const handleInputChange = (subjectId, field, value) => {
        setExamFields(prevFields => 
            prevFields.map(entry => 
                entry.id === subjectId 
                    ? { ...entry, [field]: value }
                    : entry
            )
        );
    };

    const handleSaveChanges = () => {
        const payload = {
            examSet: formData,
            exams: examFields
        };

        if (id == 0) {
            dispatch(addExamSet(payload));
        } else {
            dispatch(updateExamSet(payload));
        }
        notifySuccess("Saved Successfully");
    };

    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Form
                        fields={fields}
                        formData={formData}
                        onChange={(newFormData) => setFormData(newFormData)}
                    />
                    {
                        formData.exammasterId && formData.courseId &&
                        <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                            </div>
                        </TableCaption>
                        {filteredResults.length > 0 &&
                            <div className="">
                                <TableHeader>
                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-2/12 text-center"> Exam </TableHead>
                                        <TableHead className="w-1/12 text-center"> Min. Marks </TableHead>
                                        <TableHead className="w-1/12 text-center"> Max. Marks </TableHead>
                                        <TableHead className="w-1/12 text-center"> Exam Date </TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {filteredResults.map((result) => (
                                        <TableRow key={result.id}>
                                            <TableCell className="font-medium text-center">
                                                <h4 className=''>{result.name}</h4>
                                            </TableCell>
                                            <TableCell className="font-medium text-center ">
                                                <input
                                                    className="text-center appearance-none  rounded-lg w-[130px] mx-3 p-3 bg-gray-200"
                                                    type="number"
                                                    placeholder="Min. marks"
                                                    value={result.minScore}
                                                    onChange={(e) => handleInputChange(result.id, 'minScore', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell className="font-medium text-center ">
                                                <input
                                                    className="text-center appearance-none  rounded-lg w-[130px] mx-3 p-3 bg-gray-200"
                                                    type="number"
                                                    placeholder="Max. marks"
                                                    value={result.maxScore}
                                                    onChange={(e) => handleInputChange(result.id, 'maxScore', e.target.value)}
                                                />
                                            </TableCell>
                                            <TableCell className="font-medium text-center ">
                                                <input
                                                    className="text-center appearance-none  rounded-lg w-[150px] mx-3 p-3 bg-gray-200"
                                                    type="date"
                                                    placeholder="Exam Date"
                                                    value={result.date ? new Date(result.date).toISOString().split('T')[0] : ""}
                                                    onChange={(e) => handleInputChange(result.id, 'date', e.target.value)}
                                                />
                                            </TableCell>
                                            {/* <ScorePercentage maxScore={maxScore} obtainedMarks={result.result.obtainedScore} /> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </div>
                        }
                    </Table>
                    }
                    {filteredResults.length === 0 &&
                        <div className='p-10 flex flex-col justify-center items-center'>
                            <p className='mb-5 font-semibold'> No Subjects Found! </p>
                        </div>
                    }
                    <div className='mt-5 flex justify-end'>
                        <button
                            onClick={handleSaveChanges}
                            className="px-6 py-3 rounded-lg bg-green-600 text-white font-semibold"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SingleExamSet