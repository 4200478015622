import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyAttendanceChart from "views/admin/default/components/WeeklyAttendance";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";

import { getDashboardData } from "../../../redux/actions/admin/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const Dashboard = () => {
  const dispatch = useDispatch()

  const dashboardData = useSelector((data) => data.dashboard.value.data);


  useEffect(() => {
    dispatch( getDashboardData() )
  }, [dispatch])
  

  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Students"}
          subtitle={dashboardData.totalStudents}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Total Parents"}
          subtitle={dashboardData.totalParents}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Enrollments"}
          subtitle={dashboardData.totalEnrollments}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Total Courses"}
          subtitle={dashboardData.totalCourses}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total Classes"}
          subtitle={dashboardData.totalClasses}
        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Completed Tasks"}
          subtitle={dashboardData.totalCompletedTasks}
        />
      </div>

     

      {/* Charts */}
      {/* {
        dashboardData?.attendanceStatuses && 
      <div className="mt-5">
        <TotalSpent  data={dashboardData.attendanceStatuses} />
      </div>
      } */}
      {
        dashboardData?.weeklyAttendance && 
      <div className="mt-5">
        <WeeklyAttendanceChart data={dashboardData.weeklyAttendance} />
      </div>
      }
      {/* {
        dashboardData?.classStats && 
      <div className="mt-5">
        <WeeklyRevenue data={dashboardData.classStats} />
      </div>
      } */}
      

       {/* Course Analytics Section */}
       {dashboardData?.courseAnalytics && (
        <div className="mt-5">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white mb-4">
            Course Analytics
          </h4>
          <div className="grid grid-cols-1 gap-5">
            {dashboardData.courseAnalytics.map((course) => (
              <div key={course.courseId} className="rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none p-6">
                {/* Course Header */}
                <div className="flex justify-between items-center mb-6">
                  <h5 className="text-xl font-bold text-navy-700 dark:text-white">
                    {course.courseName}
                  </h5>
                  <div className="flex gap-4">
                    <div className="text-right">
                      <p className="text-sm text-gray-600 dark:text-white">Total Students</p>
                      <p className="text-lg font-bold text-navy-700 dark:text-white">
                        {course.analytics.enrollment.total}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-gray-600 dark:text-white">Active</p>
                      <p className="text-lg font-bold text-green-500">
                        {course.analytics.enrollment.active}
                      </p>
                    </div>
                    <div className="text-right">
                      <p className="text-sm text-gray-600 dark:text-white">Inactive</p>
                      <p className="text-lg font-bold text-red-500">
                        {course.analytics.enrollment.inactive}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Subjects Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {course.analytics.subjectPerformance.map((subject) => (
                    <div 
                      key={subject.subjectId}
                      className="rounded-xl bg-lightPrimary dark:bg-navy-700 p-4"
                    >
                      <h6 className="font-semibold text-navy-700 dark:text-white mb-3">
                        {subject.subjectName}
                      </h6>
                      <div className="grid grid-cols-2 gap-2 text-sm">
                        <div>
                          <p className="text-gray-600 dark:text-gray-400">Avg Score</p>
                          <p className="font-bold text-navy-700 dark:text-white">
                            {subject.averageScore}%
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-600 dark:text-gray-400">Total Exams</p>
                          <p className="font-bold text-navy-700 dark:text-white">
                            {subject.totalExams}
                          </p>
                        </div>
                        <div>
                          <p className="text-gray-600 dark:text-gray-400">Results</p>
                          <p className="font-bold text-navy-700 dark:text-white">
                            {subject.totalResults}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Enrollment Trend */}
                <div className="mt-4">
                  <p className="text-sm text-gray-600 dark:text-white mb-2">Latest Enrollment</p>
                  {course.analytics.trends.enrollmentTrend.map((trend) => (
                    <div key={trend.month} className="flex items-center gap-2">
                      <span className="text-navy-700 dark:text-white">
                        {new Date(trend.month).toLocaleDateString('default', { month: 'long', year: 'numeric' })}:
                      </span>
                      <span className="font-bold text-navy-700 dark:text-white">
                        {trend.count} students
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          {/* <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          /> */}
        </div>

        {/* Traffic chart & Pie Chart */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <PieChartCard />
        </div>

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />  */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;

