import React from 'react';
import { Bar } from 'react-chartjs-2';
import { 
  Chart as ChartJS, 
  CategoryScale, 
  LinearScale, 
  BarElement, 
  Title, 
  Tooltip, 
  Legend 
} from 'chart.js';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const WeeklyAttendanceChart = ({ data }) => {

  const fromDate = data[0]?.date || 'N/A';
  const toDate = data[data.length - 1]?.date || 'N/A';

  // Prepare chart data
  const chartData = {
    labels: data.map(item => item.day),
    datasets: [
      {
        label: 'Present',
        data: data.map(item => item.totalPresentCount),
        backgroundColor: 'rgba(159, 203, 1, 0.85)',
        borderColor: 'rgba(75, 192, 192, 1)',
        // borderWidth: 1
      },
      {
        label: 'Absent',
        data: data.map(item => item.totalAbsentCount),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        // borderWidth: 1
      }
    ]
  };

  // Chart options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Weekly Attendance',
      },
      tooltip: {
        enabled: true, // Keep tooltips enabled
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Days of the Week',
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        title: {
          display: true,
          text: 'Number of Attendees',
        },
      },
    },
    // Add a custom plugin to display totals
    plugins: {
      totalLabels: {
        display: true, // Enable the total labels
      },
    },
  };
  
  const totalLabelsPlugin = {
    id: 'totalLabels', // Plugin ID
    afterDatasetsDraw(chart) {
      const { ctx, data, scales } = chart;
  
      // Loop through each data point
      data.labels.forEach((_, index) => {
        const datasets = data.datasets;
        let total = 0;
  
        // Calculate total for the current label
        datasets.forEach((dataset) => {
          total += dataset.data[index];
        });
  
        // Get the x-coordinate and y-coordinate of the bar
        const x = scales.x.getPixelForValue(index);
        const y = scales.y.getPixelForValue(total);
  
        // Draw the total above the bar
        ctx.save();
        ctx.font = 'bold 12px Arial';
        ctx.fillStyle = '#000'; // Black text
        ctx.textAlign = 'center';
        ctx.fillText(total, x, y - 10); // Offset the text slightly above the bar
        ctx.restore();
      });
    },
  };
  
  // Register the custom plugin globally
  ChartJS.register(totalLabelsPlugin);

  return (
    <div className="rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none p-6" >
      <h4 className="text-xl font-bold text-navy-700 dark:text-white mb-4">
        Weekly Attendance
      </h4>
      <p className="text-sm text-gray-500 dark:text-gray-400">
          From: <span className="font-bold">{new Date(fromDate).toLocaleDateString()}</span> To: <span className="font-bold">{new Date(toDate).toLocaleDateString()}</span>
        </p>
      <div style={{ height: 'calc(100% - 80px)' }}>
        <Bar data={chartData} options={chartOptions} style={{ width: "100%", maxHeight: "400px" }} />
      </div>
    </div>
  );
};

export default WeeklyAttendanceChart;
