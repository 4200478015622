import { React, useState, useEffect } from 'react'
import { multiResult } from '../../../../redux/actions/admin/result';
import { getExams, getExamStudents, getCourseEnrolledStudents, updateExam } from '../../../../redux/actions/admin/exam';
import { useSelector, useDispatch } from 'react-redux'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Switch from '../../../../components/switch'
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../../../../components/shadcn/table";
import {
    FiSearch,
    FiEdit2,
    FiTrash,
} from "react-icons/fi";

export const SingleResult = (props) => {
    const { setTitle } = props
    const { id } = useParams()
    const navigate = useNavigate();
    const notifySuccess = (message) => toast.success(message, {
        style: {
            padding: '35px',
            color: '#a0ca00',
        },
        duration: 3000,
        iconTheme: {
            primary: '#a0ca00',
            secondary: '#222c25',
        }
    });
    const notifyError = (message) => toast.error(message, {
        style: {
            border: '1px solid #fff',
            padding: '35px',
            color: 'red',
        },
        iconTheme: {
            primary: 'red',
            secondary: '#fff',
        }
    });

    const exam = useSelector((exams) => exams.exam.value.data.rows[0]);
    const students = useSelector((exams) => exams.exam.value.courseEnrolledStudents);
    const examResults = useSelector((exams) => exams.exam.value.examResults);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExams({ examId: id }));
        if (exam) {
            setTitle("Result - " + exam.name);
        }
    }, [dispatch]);

    useEffect(() => {
        if (exam) {
            dispatch(getExamStudents(exam.id));
            dispatch(getCourseEnrolledStudents(exam.subject.courseId));
        }
    }, [exam]);

    const [searchQuery, setSearchQuery] = useState('');
    // const [minimumRequiredScore, setMinimumRequiredScore] = useState();
    // const [maxScore, setMaxScore] = useState();

    // Local state to manage updated results
    const [updatedResults, setUpdatedResults] = useState([]);

    // Initialize or update local state whenever the exam results change
    useEffect(() => {
        if (students) {
            const initialResults = students
                .map((enrollment) => {
                    // Skip if required properties are missing
                    if (!enrollment?.student?.admission) {
                        console.warn('Missing student or admission data:', enrollment);
                        return null;
                    }

                    // Find matching result or create default
                    const result = examResults.find(
                        (result) => result.studentId === enrollment.studentId
                    );

                    return {
                        ...enrollment,
                        result: result || { 
                            obtainedScore: '', 
                            remarks: '', 
                            isAbsent: false 
                        },
                    };
                })
                .filter(Boolean); // Remove null entries

            setUpdatedResults(initialResults);
        }
    }, [students, examResults]);

    // Handler to update the local state when input values change
    const handleInputChange = (studentId, field, value) => {
        const updated = updatedResults.map((entry) => {
            if (entry.studentId === studentId) {
                return {
                    ...entry,
                    result: {
                        ...entry.result,
                        [field]: value,
                    },
                };
            }
            return entry;
        });
        console.log(updated)
        setUpdatedResults(updated);
    };

    // Filter results based on search query
    const filteredResults = updatedResults.filter((enrollment) => {
        // Method 1: Using optional chaining
        return enrollment?.student?.admission?.name
            ?.toLowerCase()
            ?.includes(searchQuery.toLowerCase()) ?? false;

        // Method 2: Using explicit checks (alternative)
        /*
        if (!enrollment?.student?.admission?.name) {
            return false;
        }
        return enrollment.student.admission.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        */
    });

    

    // Send the updated results to the backend
    const handleSaveChanges = () => {
        try {
            const payload = filteredResults.map((entry) => ({
                resultId: entry.result.id,
                studentId: entry.studentId,
                obtainedScore: entry.result.obtainedScore,
                remarks: entry.result.remarks,
                isAbsent: entry.result.isAbsent,
            }));
            console.log(payload)

            // Dispatch the update action with payload here
            dispatch(multiResult({ results: payload, exam }));
            notifySuccess("Updated Results")
            dispatch(getExams({ examId: id }));


        } catch (err) {
            console.log(err)
        }
    };

    const submitExam = () => {
        try {
            if (exam.isEvaluated) {
                notifyError("Cancelled Exam Submission");
            } else {
                notifySuccess("Submitted Exam");
            }
            dispatch(updateExam({ ...exam, isEvaluated: !exam.isEvaluated }));
            dispatch(getExams({ examId: id }));
        } catch (err) {
            console.log(err)
        }
    };

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };


    return (
        <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
            <Toaster />
            <div className="h-full w-full rounded-xl">
                <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
                    <Table className="border-b border-grey-500">
                        <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
                            <div className='flex justify-between items-center'>
                                <div className='flex justify-between w-1/2 items-center'>
                                    <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                                        <p className="pl-3 pr-2 text-xl">
                                            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                                        </p>
                                        <input
                                            value={searchQuery}
                                            onChange={handleSearch}
                                            type="text"
                                            placeholder="Search..."
                                            className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                                        />
                                    </div>
                                </div>
                                {exam && (
                                    <>
                                        <div className='flex justify-start items-center'>
                                            <p className="text-base"> Status: &nbsp;</p>
                                            <p className={`text-base ${exam.isEvaluated ? "text-green-500" : "text-red-500"}`}>
                                                {exam.isEvaluated ? " Submitted" : " Pending"}
                                            </p>
                                        </div>
                                        <div className='flex justify-end items-center'>
                                            <p className='text-base'>Min./Pass Marks: {exam.minScore || 0}</p>
                                            <div className="divider p-0.5 mx-3 rounded-lg bg-gray-200 !h-[50px]"></div>
                                            <p className='text-base'>Max./Total Marks: {exam.maxScore || 0}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        </TableCaption>

                        {filteredResults?.length > 0 ? (
                            <div className="">
                                <TableHeader>
                                    <TableRow className="dark:text-white">
                                        <TableHead className="w-2/12 text-center">Student</TableHead>
                                        <TableHead className="w-1/12 text-center">Obtained Marks</TableHead>
                                        <TableHead className="w-6/12 text-center">Remarks</TableHead>
                                        <TableHead className="w-2/12 text-center">Percentage</TableHead>
                                        <TableHead className="w-2/12 text-center">Absent</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {filteredResults.map((result) => {
                                        // Additional safety check before rendering
                                        if (!result?.student?.admission?.name) {
                                            return null;
                                        }

                                        return (
                                            <TableRow key={result.studentId || Math.random()}>
                                                <TableCell className="font-medium text-center">
                                                    <h4>{result.student.admission.name}</h4>
                                                </TableCell>
                                                <TableCell className="font-medium">
                                                    <input
                                                        className="text-center appearance-none rounded-lg w-[90px] mx-3 p-3 bg-gray-200"
                                                        type="number"
                                                        placeholder="marks"
                                                        value={result.result?.obtainedScore || ''}
                                                        onChange={(e) => handleInputChange(result.studentId, 'obtainedScore', e.target.value)}
                                                    />
                                                </TableCell>
                                                <TableCell className="font-medium text-center">
                                                    <input
                                                        className="rounded-lg w-[85%] mx-3 p-3 bg-gray-200"
                                                        type="text"
                                                        placeholder="remarks"
                                                        value={result.result?.remarks || ''}
                                                        onChange={(e) => handleInputChange(result.studentId, 'remarks', e.target.value)}
                                                    />
                                                </TableCell>
                                                <TableCell className="font-medium text-center">
                                                    <p className='text-base'>
                                                        {exam?.maxScore && result.result?.obtainedScore
                                                            ? ((result.result.obtainedScore / exam.maxScore) * 100).toFixed(2)
                                                            : 0}
                                                        %
                                                    </p>
                                                </TableCell>
                                                <TableCell className="font-medium text-center">
                                                    <Switch
                                                        id={result.result?.id || result.studentId}
                                                        checked={Boolean(result.result?.isAbsent)}
                                                        onChange={(e) => handleInputChange(result.studentId, 'isAbsent', e.target.checked)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </div>
                        ) : (
                            <div className='p-10 flex flex-col justify-center items-center'>
                                <p className='mb-5 font-semibold'>No Students Found!</p>
                            </div>
                        )}
                    </Table>

                    <div className='mt-5 flex justify-end'>
                        {exam && (
                            <button
                                onClick={submitExam}
                                className={`px-6 py-3 rounded-lg text-white font-semibold mr-5 ${
                                    exam.isEvaluated ? "bg-red-500" : "bg-green-900"
                                }`}
                            >
                                {exam.isEvaluated ? "Cancel Submission" : "Submit Exam"}
                            </button>
                        )}
                        <button
                            onClick={handleSaveChanges}
                            className="px-6 py-3 rounded-lg bg-green-600 text-white font-semibold"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SingleResult;



