
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from '../headers';


export const getGrades = createAsyncThunk("grade/get", async (query) => {
  console.log(query)

  let filteredQuery = Object.fromEntries(
    Object.entries(query).filter(([key, value]) => value != null && value !== '')
);
let params = new URLSearchParams(filteredQuery);

let response = await fetch(`${process.env.REACT_APP_URL}admin/api/grade?${params.toString()}`, {
  headers: createHeaders()
});
let json = await response.json();
return json;
});

export const addGrade = createAsyncThunk("grade/add", async (data) => {
  const response = await fetch(`${process.env.REACT_APP_URL}admin/api/grade/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return json;
});



export const updateGrade = createAsyncThunk("grade/update", async (data) => {
  console.log(data, "ID and DATA for update");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/grade/`, {
    method: 'PUT', // or 'PATCH' depending on your API
    headers: createHeaders(),
    body: JSON.stringify({ ...data }) // Include the id in the request body
  });
  let json = await response.json();
  return json;
});


export const deleteGrade = createAsyncThunk("grade/delete", async (id) => {
  console.log(id, "ID for delete");
  let response = await fetch(`${process.env.REACT_APP_URL}admin/api/grade/${id}`, {
    method: 'DELETE',
    headers: createHeaders(),
  });
  let json = await response.json();
  return json;
});

export const gradesSlice = createSlice({
  name: 'gradees',
  initialState: {
  
    value: {
      isError: false,
      isLoading: false,
      status: 'default',
      data: {
        count: 0,
        rows: []
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGrades.fulfilled, (grade, action) => {
     
        grade.value.data.rows = action.payload.rows
        grade.value.data.count = action.payload.count
        grade.value.status = action.payload.message
        console.log('Updated grade:', grade.value.data.rows);

        
      })
      .addCase(getGrades.pending, (grade) => {
        grade.status = "Fetching todos. Please wait a moment..."
      })
      .addCase(getGrades.rejected, (grade) => {
        grade.status = "Failed to  fetch data..."
      })
      .addCase(updateGrade.fulfilled, (grade, action) => {
        console.log(action.payload.message, "action.payload.message")
        grade.value.status = action.payload.message
        // find the index of the grade with the updated id
        // const index = grade.value.data.findIndex(item => item.id === action.payload.id);
        // if (index !== -1) {
        //   // replace the grade at the found index with the updated grade
        //   grade.value.data[index] = action.payload;
        // }
      })
      .addCase(updateGrade.pending, (grade) => {
        grade.status = "Updating grade. Please wait a moment...";
      })
      .addCase(updateGrade.rejected, (grade) => {
        grade.status = "Failed to update grade...";
      })
      .addCase(deleteGrade.fulfilled, (grade, action) => {
        // // filter out the grade with the deleted id
        // grade.value.data = grade.value.data.filter(item => item.id !== action.payload.id);
        grade.value.status = action.payload.message
      })
      .addCase(deleteGrade.pending, (grade) => {
        grade.status = "Deleting grade. Please wait a moment...";
      })
      .addCase(deleteGrade.rejected, (grade) => {
        grade.status = "Failed to delete grade...";
      })
      .addCase(addGrade.fulfilled, (grade, action) => {
        // grade.value.data.push(action.payload); // add the new grade to the list
     })
     .addCase(addGrade.pending, (grade) => {
        grade.status = "Adding grade. Please wait a moment...";
     })
     .addCase(addGrade.rejected, (grade) => {
        grade.status = "Failed to add grade...";
     });
  }
})

export default gradesSlice.reducer
