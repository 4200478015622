import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
// import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  verifyResetPasswordToken,
  resetPassword,
} from "../../../redux/actions/forgetpassword";
import { useNavigate } from "react-router-dom";
import Eye from "components/icons/Eye";

export default function CreateNewPassword() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  //const [tokenValidated, setTokenValidated] = useState(false);
  //const [loading, setLoading] = useState(true);
  const loading = useSelector((state) => state.forgetPassword.loading);
  const error = useSelector((state) => state.forgetPassword.error);
  const [validToken, setValidToken] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token").split("?code=")[0];
  const navigate = useNavigate();
  const [validation, setValidation] = useState({
    passwordValid: false,
    passwordError: "",
  });
  const code = window.location.href.split("?code=")[1] 
  console.log("SAME PAGE")
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //useEffect(() => {
  // if (token) {
  //   axios.post('http://localhost:3001/open/auth/verifyresetpasswordtoken', { token })
  //     .then(response => {
  //       // Token validated successfully
  //       setTokenValidated(true);
  //       setLoading(false);
  //     })
  //     .catch(error => {
  //       // Token validation failed
  //       setTokenValidated(false);
  //       setLoading(false);
  //       // Redirect to a different page or show an error message
  //     //   history.push('/error');
  //     });
  // } else {
  //   // Token not provided
  //   setLoading(false);
  // }
  //dispatch(verifyResetPasswordToken(token));
  //}, []);

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
    
    if (!password) {
      setValidation((prevState) => ({
        ...prevState,
        passwordValid: false,
        passwordError: "Password is required.",
      }));
    } else if (!passwordRegex.test(password)) {
      setValidation((prevState) => ({
        ...prevState,
        passwordValid: false,
        passwordError:
          "Password must be at least 8 characters long and contain at least one letter and one number.",
      }));
    } else {
      setValidation((prevState) => ({
        ...prevState,
        passwordValid: true,
        passwordError: "",
      }));
    }
  };

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const response = await dispatch(verifyResetPasswordToken({code, token}));
        setValidToken(true);
        if (response.payload.success === false) {
          //notifyError("invalid token");
          navigate("/auth/sign-in"); // Redirect to a specific route for invalid token
        }
      } catch (error) {
        console.error("Error verifying reset password token:", error);
      }
    };

    verifyToken();
  }, [dispatch, token]);

  const handleClick = async () => {
    // Validate password first
    validatePassword(password);
    
    if (!validation.passwordValid) {
      notifyError(validation.passwordError);
      return;
    }

    // Then check if passwords match
    if (password !== confirmPassword) {
      notifyError("Passwords do not match");
      return;
    }

    // If all validations pass, proceed with password reset
    try {
      const result = await dispatch(resetPassword({token, password, code}));
      if (result.payload?.success) {
        notifySuccess("Password reset successful");
        navigate("/auth/sign-in");
      } else {
        notifyError(result.payload?.message || "Error while resetting password");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      notifyError("Error while resetting password");
    }
  };

  /*const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };*/

  //if (loading) {
  //return <div className="flex items-center justify-center">Loading...</div>;
  //}
  if (!validToken) {
    return <div className="flex items-center justify-center">Loading...</div>;
  }

  const notifySuccess = (message) =>
    toast.success(message, {
      style: {
        padding: "35px",
        color: "#a0ca00",
      },
      iconTheme: {
        primary: "#a0ca00",
        secondary: "#222c25",
      },
    });
  const notifyError = (message) =>
    toast.error(message, {
      style: {
        border: "1px solid #fff",
        padding: "35px",
        color: "red",
      },
      iconTheme: {
        primary: "red",
        secondary: "#fff",
      },
    });

  //   if (!tokenValidated) {
  //     return <div>Token validation failed. Please try again.</div>;
  //   }

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Create New Password
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter a new password.
        </p>

        {/* Password */}
        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-3"
            label="New Password*"
            placeholder="Enter your new password"
            id="password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              validatePassword(e.target.value);
            }}
          />
          <button
            type="button"
            className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
            onClick={() => setShowPassword(!showPassword)}
          >
            <Eye closed={!showPassword} />
          </button>
        </div>

        {/* Confirm Password */}
        <div className="relative">
          <InputField
            variant="auth"
            extra="mb-3"
            label="Confirm Password*"
            placeholder="Confirm your new password"
            id="confirmPassword"
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
              validatePassword(e.target.value);
            }}
          />
          <button
            type="button"
            className="absolute right-3 top-[50%] translate-y-[30%] text-gray-600"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          >
            <Eye closed={!showConfirmPassword} />
          </button>
        </div>

        <button
          className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          onClick={handleClick}
        >
          Set New Password
        </button>
        <Toaster />
      </div>
    </div>
  );
}
