import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getReports, downloadReport } from '../../../redux/actions/admin/reports';
import { FiSearch, FiEye, FiUser, FiCalendar } from 'react-icons/fi';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";
import Paginate from 'components/paginate';
import toast, { Toaster } from 'react-hot-toast';

const ReportView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 10,
    page: 1
  });

  const reports = useSelector((state) => state.reports?.value?.data?.rows || []);
  const loading = useSelector((state) => state.reports?.loading);

  useEffect(() => {
    dispatch(getReports(filter));
  }, [dispatch, filter]);

  const handleViewReport = (reportId) => {
    navigate(`/admin/reports/details/${reportId}`);
  };

  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  });

  const notifyError = (message) => toast.error(message, {
    style: {
      padding: '35px',
      color: '#ff4a4a',
    },
    duration: 3000,
    iconTheme: {
      primary: '#ff4a4a',
      secondary: '#222c25',
    }
  });

  const formatDateRange = (dateRange) => {
    if (!dateRange) return '';
    return `${new Date(dateRange.from).toLocaleDateString()} - ${new Date(dateRange.to).toLocaleDateString()}`;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4 dark:bg-navy-700">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        <div className='mt-3 p-4 rounded-xl border-solid border-2 border-gray-100'>
          <Table className="border-b border-grey-500">
            <TableCaption className="mb-3 font-bold text-2xl text-start text-black">
              <div className='flex justify-between items-center'>
                <div className='flex justify-between w-1/2 items-center'>
                  <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      onChange={(e) => setFilter({ ...filter, name: e.target.value })}
                      type="text"
                      placeholder="Search..."
                      className="block h-full w-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                </div>
                <div className='flex justify-end'>
                  <Link
                    to="/admin/reports/create"
                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70"
                  >
                    Generate New Report
                  </Link>
                </div>
              </div>
            </TableCaption>

            {reports.length > 0 ? (
              <div>
                <TableHeader>
                  <TableRow className="dark:text-white">
                    <TableHead className="w-4/12">Report Details</TableHead>
                    <TableHead className="w-3/12">Course Info</TableHead>
                    <TableHead className="w-2/12">Date Range</TableHead>
                    <TableHead className="w-2/12">Status</TableHead>
                    <TableHead className="w-1/12 text-center">Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {reports.map((report) => (
                    <TableRow key={report.id}>
                      <TableCell>
                        <div className="space-y-1">
                          <h4 className="font-medium text-brand-500">{report.name}</h4>
                          <div className="flex items-center text-sm text-gray-600">
                            <FiUser className="mr-2" />
                            {report.generator?.fullname || 'N/A'}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="space-y-1">
                          <p className="font-medium">{report.course?.name || 'N/A'}</p>
                          {report.batch && (
                            <p className="text-sm text-gray-600">
                              {report.batch.name} {report.class && `- ${report.class.name}`}
                            </p>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="flex items-center">
                          <FiCalendar className="mr-2" />
                          <span className="text-sm">
                            {formatDateRange(report.dateRange)}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        <span className={`px-3 py-1 rounded-full ${
                          report.status === 'completed' ? 'bg-green-100 text-green-800' : 
                          'bg-yellow-100 text-yellow-800'
                        }`}>
                          {report.status}
                        </span>
                      </TableCell>
                      <TableCell className="text-center">
                        <button
                          onClick={() => handleViewReport(report.id)}
                          className="text-brand-500 hover:text-brand-700 flex items-center justify-center mx-auto"
                          title="View Report"
                        >
                          <FiEye className="h-5 w-5" />
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <Paginate
                  page={filter.page}
                  totalCount={reports.count}
                  limit={filter.limit}
                  changePage={(value) => setFilter({ ...filter, page: value })}
                />
              </div>
            ) : (
              <div className='p-10 flex flex-col justify-center items-center'>
                <p className='mb-5 font-semibold'>No Reports Found!</p>
                <Link
                  to="/admin/reports/create"
                  className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500 active:!bg-white/70"
                >
                  Generate Report
                </Link>
              </div>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ReportView; 