import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { downloadReport } from '../../../redux/actions/admin/reports';
import {
  FiUsers,
  FiCalendar,
  FiClock,
  FiCheckCircle,
  FiXCircle,
  FiAlertTriangle,
  FiUser,
  FiChevronLeft,
  FiChevronRight
} from 'react-icons/fi';
import Card from "components/card";
import PieChart from "components/charts/PieChart";
import LineChart from "components/charts/LineChart";
import BarChart from "components/charts/BarChart";
import toast, { Toaster } from 'react-hot-toast';

const ReportDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);

  const notifyError = (message) => toast.error(message, {
    style: {
      padding: '35px',
      color: '#ff4a4a',
    },
    duration: 3000,
    iconTheme: {
      primary: '#ff4a4a',
      secondary: '#222c25',
    }
  });

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await dispatch(downloadReport(id));
        if (downloadReport.fulfilled.match(response)) {
          setReportData(response.payload);
        } else {
          notifyError("Failed to fetch report details");
        }
      } catch (error) {
        notifyError(error.message || "Failed to fetch report details");
      } finally {
        setLoading(false);
      }
    };

    fetchReport();
  }, [dispatch, id]);

  // Add a debug log to see the data structure
  console.log('Report Data:', reportData);

  const PeriodWiseAttendance = ({ periodData }) => {
    const [startIndex, setStartIndex] = useState(0);
    const DAYS_TO_SHOW = 14;

    // Group periods by date
    const periodsByDate = periodData.reduce((acc, period) => {
      const date = period.date;
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(period);
      return acc;
    }, {});

    // Get sorted unique dates
    const allDates = Object.keys(periodsByDate).sort();
    const totalDates = allDates.length;

    // Get current chunk of dates
    const currentDates = allDates.slice(startIndex, startIndex + DAYS_TO_SHOW);

    // Get data for current chunk
    const currentChunkData = currentDates.reduce((acc, date) => {
      acc.push(...periodsByDate[date]);
      return acc;
    }, []);

    const chartData = [{
      name: "Present",
      data: currentChunkData.map(period => ({
        x: new Date(period.date).toLocaleDateString(),
        y: period.totalPresent
      }))
    }, {
      name: "Absent",
      data: currentChunkData.map(period => ({
        x: new Date(period.date).toLocaleDateString(),
        y: period.totalAbsent
      }))
    }];

    const chartOptions = {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 5,
          columnWidth: '70%',
        },
      },
      xaxis: {
        type: 'category',
        labels: {
          rotate: -45,
          style: { fontSize: '12px' }
        }
      },
      yaxis: {
        title: { text: 'Number of Students' }
      },
      colors: ['#4CAF50', '#FF5252'],
      legend: {
        position: 'top',
        horizontalAlign: 'left'
      }
    };

    return (
      <Card extra="!p-[20px]">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-xl font-bold">Attendance Overview</h3>
          <div className="flex items-center space-x-4">
            <div className="text-sm">
              <span className="text-gray-500">Showing: </span>
              <span className="font-medium text-brand-500">
                {new Date(currentDates[0]).toLocaleDateString()}
              </span>
              <span className="text-gray-500"> - </span>
              <span className="font-medium text-brand-500">
                {new Date(currentDates[currentDates.length - 1]).toLocaleDateString()}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <button
                onClick={() => setStartIndex(prev => Math.max(0, prev - DAYS_TO_SHOW))}
                disabled={startIndex === 0}
                className="p-2 rounded-full bg-brand-500 text-white disabled:opacity-50 hover:bg-brand-600 transition-colors"
                title="Previous"
              >
                <FiChevronLeft className="h-5 w-5" />
              </button>
              <button
                onClick={() => setStartIndex(prev => Math.min(totalDates - 1, prev + DAYS_TO_SHOW))}
                disabled={startIndex + DAYS_TO_SHOW >= totalDates}
                className="p-2 rounded-full bg-brand-500 text-white disabled:opacity-50 hover:bg-brand-600 transition-colors"
                title="Next"
              >
                <FiChevronRight className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        <div className="h-[400px]">
          <BarChart
            chartData={chartData}
            chartOptions={chartOptions}
          />
        </div>

        <div className="mt-4 text-center text-sm text-gray-600">
          Page {Math.floor(startIndex / DAYS_TO_SHOW) + 1} of {Math.ceil(totalDates / DAYS_TO_SHOW)}
        </div>
      </Card>
    );
  };

  const MetricsOverview = ({ metrics }) => (
    <Card extra="!p-[20px] text-center">
      <div className="grid grid-cols-3 gap-4">
        <div className="flex flex-col items-center">
          <FiUsers className="h-8 w-8 text-brand-500 mb-2" />
          <p className="text-gray-600">Total Students</p>
          <h3 className="text-2xl font-bold">{metrics.totalEnrolledStudents}</h3>
        </div>
        <div className="flex flex-col items-center">
          <FiClock className="h-8 w-8 text-brand-500 mb-2" />
          <p className="text-gray-600">Total Periods</p>
          <h3 className="text-2xl font-bold">{metrics.totalPeriodsConducted}</h3>
        </div>
        <div className="flex flex-col items-center">
          <FiCalendar className="h-8 w-8 text-brand-500 mb-2" />
          <p className="text-gray-600">Date Range</p>
          <h3 className="text-sm font-medium">
            {new Date(reportData.dateRange.from).toLocaleDateString()} - 
            {new Date(reportData.dateRange.to).toLocaleDateString()}
          </h3>
        </div>
      </div>
    </Card>
  );

  const ReportHeader = () => (
    <div className="bg-white rounded-xl p-6 mb-6 shadow-sm">
      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold text-navy-700 dark:text-white">
            {reportData.name}
          </h1>
          <div className="mt-2 space-y-1">
            <p className="text-gray-600">
              <span className="font-medium">Course:</span> {reportData.course?.name}
            </p>
            <p className="text-gray-600">
              <span className="font-medium">Batch:</span> {reportData.batch?.name}
            </p>
            <p className="text-gray-600">
              <span className="font-medium">Class:</span> {reportData.class?.name}
            </p>
          </div>
        </div>
        <div className="text-right">
          <div className="flex items-center text-gray-600">
            <FiUser className="mr-2" />
            Generated by: {reportData.generator?.fullname}
          </div>
          <p className="text-sm text-gray-500 mt-1">
            {new Date(reportData.createdAt).toLocaleString()}
          </p>
          <span className={`mt-2 inline-block px-3 py-1 rounded-full ${
            reportData.status === 'completed' ? 'bg-green-100 text-green-800' : 
            'bg-yellow-100 text-yellow-800'
          }`}>
            {reportData.status}
          </span>
        </div>
      </div>
    </div>
  );

  if (loading || !reportData) {
    return (
      <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
        <div className="flex justify-center items-center h-full">
          <p className="text-xl text-gray-600">
            {loading ? "Loading report details..." : "No report data available"}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full p-4">
      <Toaster />
      
      <ReportHeader />

      <div className="grid grid-cols-1 gap-6">
        {/* Metrics Overview */}
        <MetricsOverview metrics={reportData.reportData.metrics} />

        {/* Period-wise Attendance Chart */}
        <PeriodWiseAttendance 
          periodData={reportData.reportData.metrics.periodWiseAttendance} 
        />

        {/* Additional Info */}
        <Card extra="!p-[20px]">
          <h3 className="text-xl font-bold mb-4">Report Information</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <h4 className="font-medium text-gray-700">Report Details</h4>
              <ul className="mt-2 space-y-2">
                <li className="text-gray-600">Type: {reportData.type}</li>
                <li className="text-gray-600">ID: {reportData.id}</li>
                <li className="text-gray-600">
                  Generated: {new Date(reportData.reportData.metadata.reportGeneratedAt).toLocaleString()}
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-medium text-gray-700">Date Range</h4>
              <ul className="mt-2 space-y-2">
                <li className="text-gray-600">
                  From: {new Date(reportData.dateRange.from).toLocaleDateString()}
                </li>
                <li className="text-gray-600">
                  To: {new Date(reportData.dateRange.to).toLocaleDateString()}
                </li>
              </ul>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ReportDetails; 