import { React, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, addUser, updateUser, deleteUser, resendPasswordEmail, resetPassword } from '../../../redux/actions/admin/user';
import { getRoles } from '../../../redux/actions/admin/role';
import { getClasses } from '../../../redux/actions/admin/classRoom';
import toast, { Toaster } from 'react-hot-toast';
import DropdownSelect from 'components/select';
import Paginate from 'components/paginate';


import {
  FiTrendingUp,
  FiX,
  FiLayers,
  FiSearch,
  FiUnlock,
  FiEdit2,
  FiTrash,
  FiSettings,
  FiSend,
  FiRefreshCw,
  FiChevronDown
} from "react-icons/fi";

import Widget from "components/widget/Widget";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/shadcn/table";

import DialogCustom from "../../../components/dialog";
import Form from "../../../components/form";

export const User = (props) => {
  const [formData, setFormData] = useState({});
  const [passwordFormData, setPasswordFormData] = useState({});
  // const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    name: "",
    status: "All",
    limit: 10,
    page: 1
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isResetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [resetUser, setResetUser] = useState({})
  const notifySuccess = (message) => toast.success(message, {
    style: {
      padding: '35px',
      color: '#a0ca00',
    },
    duration: 3000,
    iconTheme: {
      primary: '#a0ca00',
      secondary: '#222c25',
    }
  })
  const notifyError = (message) => toast.error(message, {
    style: {
      border: '1px solid #fff',
      padding: '35px',
      color: 'red',
    },
    iconTheme: {
      primary: 'red',
      secondary: '#fff',
    }
  })

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers(filter))
    dispatch(getClasses({ limit: 100 }))

    // setFormData({})
  }, [dispatch])

  useEffect(() => {
    dispatch(getRoles({}));
    // setFormData({})
  }, [])

  const users = useSelector((users) => users.user.value.data);
  const classes = useSelector((classes) => classes.classRoom.value.data);
  const roles = useSelector((roles) => roles.role.value.data);

  const [selectedRoleValue, setSelectedRoleValue] = useState(null);
  const [selectedClassValue, setSelectedClassValue] = useState([]);

  const handleActionButtonClick = (userId, action) => {
    if (action === 'edit') setIsDialogOpen(true);
    if (action === 'delete') setIsDeleteDialogOpen(true);
    if (action === 'reset-password') {
      setResetPasswordDialogOpen(true);
      setResetUser(userId)
    }
    if (userId == null) {
      setIsDialogOpen(true)
      return setFormData({})
    }

    const selectedUser = users.rows.find((user) => user.id === userId);
    console.log(selectedUser, "FORM")
    setSelectedRoleValue(selectedUser.role ? selectedUser.role.id : null)

    setFormData(selectedUser)
  };

  useEffect(() => {
    dispatch(getUsers(filter));
  }, [filter]);

  const handleCrudUser = async () => {
    try {
      setIsDialogOpen(false)
      if (formData.id) await dispatch(updateUser(formData));
      if (!formData.id) await dispatch(addUser(formData));
      notifySuccess("Seccess")

      setFormData({});
      await dispatch(getUsers(filter));

    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsDeleteDialogOpen(false)
      await dispatch(deleteUser(formData.id));
      // setFormData({});
      notifySuccess("Deleted Successfully");
      await dispatch(getUsers(filter));

    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  const handlePasswordChange = async () => {
    try {
      setIsDeleteDialogOpen(false)
      await dispatch(resetPassword( {...resetUser, ...passwordFormData} ));
      // setFormData({});
      notifySuccess("Password Reset Successfull");
    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  const sendResetPasswordEmail = async (userId) => {
    try {
      await dispatch(resendPasswordEmail(userId));
      notifySuccess("Password Reset Successfull");
    } catch (error) {
      console.error('Failed to add state:', error);
    }
  };

  // const users = [];
  const fields = [
    {
      id: "username",
      name: "username",
      label: "User Name",
      placeholder: "Enter User's Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "Status",
      name: "status",
      label: "Status",
      desc: "Set status as active/inactive",
      type: "switch",
    },
    {
      id: "fullname",
      name: "fullname",
      label: "Full Name",
      placeholder: "Enter User's Full Name",
      type: "text",
      // reflectChange: false
    },
    {
      id: "email",
      name: "email",
      label: "Email",
      placeholder: "Enter User's Email",
      type: "mail",
      // reflectChange: false
    },
    {
      id: "mobile",
      name: "mobile",
      label: "User Mobile",
      placeholder: "Enter User's Mobile",
      type: "number",
    },
    {
      id: "roles",
      name: "roles",
      label: "User Role",
      desc: "Set a user role",
      type: "dropdown",
      toSelect: "id",
      selectedvalue: selectedRoleValue,
      placeHolder: "select role",
      list: roles.rows
    },
    {
      id: "classes",
      name: "classes",
      label: "Assigned Classes",
      type: "multiselect",
      toSelect: ["id"],
      options: classes.rows ? classes.rows : [],
    },
  ];

  const passwordFields = [
    {
      id: "password",
      name: "password",
      label: "New Password",
      placeholder: "Enter a new password",
      type: "password",
      // reflectChange: false
    },
    {
      id: "confirm-password",
      name: "confirm-password",
      label: "New Password",
      placeholder: "Confirm password",
      type: "password",
      // reflectChange: false
    },
  ];

  return (
    <div className="mt-3 h-full min-h-[84vh] w-full rounded-xl bg-white p-4">
      <Toaster />
      <div className="h-full w-full rounded-xl">
        {/* <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
          <Widget
            icon={<FiLayers className="h-7 w-7" />}
            title={"Total Users"}
            subtitle={users.count || 0}
            styling={
              {
                iconContStyles: "bg-brand-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-yellow-100"
              }
            }
          />
          <Widget
            icon={<FiTrendingUp className="h-7 w-7" />}
            title={"Active Users"}
            subtitle={users.activeCount || 0}
            styling={
              {
                iconContStyles: "bg-green-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-green-100"
              }
            }
          />
          <Widget
            icon={<FiX className="h-7 w-7" />}
            title={"Inactive Users"}
            subtitle={users.inactiveCount || 0}
            styling={
              {
                iconContStyles: "bg-red-500",
                iconStyles: "text-white",
                border: "border-solid border-2 border-red-100"
              }
            }
          />


        </div> */}

        <div className='mt-3  p-4 rounded-xl border-solid border-2 border-gray-100'>
          <Table className="border-b border-grey-500">
            <TableCaption className="mb-3 font-bold  text-2xl text-start text-black">
              <div className='flex justify-between items-center'>
                <div className='flex justify-between w-1/2 items-center'>
                  <div className="mr-5 p-4 flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
                    <p className="pl-3 pr-2 text-xl">
                      <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
                    </p>
                    <input
                      // value={filter.name}
                      onChange={(e) => {
                        setFilter({ ...filter, ["name"]: e.target.value })
                      }}
                      type="text"
                      placeholder="Search..."
                      className="block h-full w-full  bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
                    />
                  </div>
                  <DropdownSelect
                    list={[
                      { name: "All", id: 1 },
                      { name: "Active", id: 2 },
                      { name: "Inactive", id: 3 },
                    ]}
                    triggerStyles="bg-lightPrimary !rounded-full"
                    // listStyles="text-sm text-red-700"
                    placeHolder={"Status"}
                    toSelect={"name"}
                    onChange={(value) => {
                      setFilter({ ...filter, ["status"]: value })
                    }}
                  />
                </div>
                <div className='flex  justify-end'>
                  <button
                    onClick={() => handleActionButtonClick(null, "add")}
                    className="border-2 text-white linear rounded-full bg-brand-700 px-4 py-3 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                    Add User
                  </button>

                </div>
              </div>
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-1/3">User</TableHead>
                <TableHead className="w-1/5">Role</TableHead>
                <TableHead>Status</TableHead>
                <TableHead className="w-1/6 text-center ">Actions</TableHead>
              </TableRow>
            </TableHeader>

            <TableBody>
              {users.rows.length > 0 &&

                users.rows.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell className="font-medium">
                      <h4 className='text-brand-500 font-black'> {user.fullname} </h4>
                      <p> {user.email} </p>
                      <p> {user.mobile} </p>
                    </TableCell>
                    <TableCell className="font-medium">{user.role ? user.role.name : "-"}</TableCell>
                    <TableCell>
                      {user.status ?
                        <div className="flex items-center justify-start w-fit bg-green-500 rounded-2xl  ">
                          <FiTrendingUp className="h-6 w-6 text-white p-1 " />
                          {/* <span>Active</span> */}
                        </div> :
                        <div className="flex items-center justify-start w-fit rounded-xl bg-red-500">
                          <FiX className="h-6 w-6 text-white  p-1" />
                          {/* <span>Inactive</span> */}
                        </div>
                      }
                    </TableCell>
                    <TableCell className="w-1/6  ">
                      <div className='w-full flex justify-around'>
                        <div
                          title="Update User"
                          onClick={() => handleActionButtonClick(user.id, "edit")}
                          className='mx-2 bg-yellow-500 w-fit p-1 rounded-3xl cursor-pointer' >
                          <FiEdit2 className='h-4 w-4 text-white' />
                        </div>

                        <div
                          title="resend password email"
                          onClick={() => sendResetPasswordEmail(user.id)}
                          className='mx-2 bg-blue-500 w-fit p-1 rounded-3xl cursor-pointer' >
                          <FiSend className='h-4 w-4 text-white' />
                        </div>
                        {/* <div
                          title="reset password"
                          onClick={() => handleActionButtonClick(user, "reset-password")}
                          className='mx-2 bg-yellow-700 w-fit p-1 rounded-3xl cursor-pointer' >
                          <FiRefreshCw className='h-4 w-4 text-white' />
                        </div> */}



                        <div
                          title="delete"
                          onClick={() => handleActionButtonClick(user.id, "delete")}
                          className='mx-2 bg-red-500 w-fit p-1 rounded-3xl cursor-pointer'>
                          <FiTrash className='h-4 w-4 text-white' />
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                ))


              }

            </TableBody>

          </Table>


          {users.rows.length > 0 &&
            <Paginate
              page={filter.page}
              totalCount={users.count}
              limit={filter.limit}
              changePage={(value) => {
                setFilter({ ...filter, ["page"]: value })
              }}
            />
          }

          {users.rows.length == 0 &&
            <div className='p-10 flex flex-col justify-center items-center'>
              <h4 className='mb-5 text-2xl text-brand-500 font-medium'> No Users Added! Click below to add one. </h4>
              <button
                onClick={() => handleActionButtonClick(null, "add")}
                className="border-2 text-white linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-500  active:!bg-white/70">
                Add User
              </button>
            </div>
          }

          <DialogCustom

            open={isDialogOpen}
            onOpenChange={setIsDialogOpen}
            dialogTitle="Edit User"
            dialogWidth="w-1/2"
            dialogDesc="Update the neccessary fields and save."
            content={
              <>
                <Form
                  fields={fields}
                  formData={formData}
                  onChange={(newFormData) => setFormData(newFormData)}
                />
                <div className='flex justify-end'>
                  <button
                    onClick={handleCrudUser}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                    {formData.id ? "Update" : "Add" + " User"}
                  </button>
                </div></>
            }
          />
          <DialogCustom

            open={isDeleteDialogOpen}
            onOpenChange={setIsDeleteDialogOpen}
            dialogTitle="Delete User"
            dialogWidth="w-1/2"
            dialogDesc="Are you sure you want to delete this user ?"
            content={
              <div className='flex flex-row-reverse'>
                <button
                  onClick={handleDelete}
                  className=" text-white linear rounded-xl border-2 border-white bg-red-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-red-500 hover:border-2 hover:border-red-700 hover:shadow-lg  ">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setIsDeleteDialogOpen(false);
                  }}
                  className="mr-4 text-white linear rounded-xl border-2 border-white bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white hover:text-brand-500 hover:border-2 hover:border-brand-700 hover:shadow-lg  ">
                  Cancel
                </button>
              </div>
            }
          />
          <DialogCustom

            open={isResetPasswordDialogOpen}
            onOpenChange={setResetPasswordDialogOpen}
            dialogTitle={`Reset password for ${resetUser.username}`}
            dialogWidth="w-1/2"
            dialogDesc="Enter a new password and confirm"
            content={
              <>
                <Form
                  fields={passwordFields}
                  formData={passwordFormData}
                  onChange={(newFormData) => setPasswordFormData(newFormData)}
                />
                <div className='flex justify-end'>
                  <button
                    onClick={() => setResetPasswordDialogOpen(false)}
                    className="text-black linear rounded-xl bg-red-500 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70 mr-5">
                    Cancel
                  </button>
                  <button
                    onClick={handlePasswordChange}
                    className="text-black linear rounded-xl bg-brand-700 px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-brand-850/80 hover:text-white  active:!bg-white/70">
                    Reset Password
                  </button>
                </div>
                </>
            }
          />
        </div>
      </div>
    </div>
  )
};

export default User