import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { FiChevronLeft, FiChevronRight, FiCalendar, FiPlus } from "react-icons/fi";
import { getPeriods } from '../../redux/actions/admin/period';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Add this CSS either in your component or in a separate CSS file
const datePickerStyles = `
  .react-datepicker-wrapper {
    width: auto;
  }
  .react-datepicker-popper {
    z-index: 999 !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .react-datepicker {
    font-family: inherit;
    border: none !important;
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding: 1rem;
  }
  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
    padding: 0;
    margin-bottom: 0.5rem;
  }
  .react-datepicker__current-month {
    font-size: 1rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 0.5rem;
  }
  .react-datepicker__navigation {
    top: 1rem;
  }
  .react-datepicker__navigation--previous {
    left: 1rem;
    border: none;
  }
  .react-datepicker__navigation--next {
    right: 1rem;
    border: none;
  }
  .react-datepicker__day-name {
    color: #6B7280;
    margin: 0.5rem;
    width: 2rem;
  }
  .react-datepicker__day {
    margin: 0.5rem;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 9999px;
    color: #374151;
  }
  .react-datepicker__day:hover {
    background-color: #F3F4F6;
    border-radius: 9999px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: var(--brand-500, #4F46E5) !important;
    color: white !important;
    border-radius: 9999px;
  }
  .react-datepicker__day--disabled {
    color: #D1D5DB;
  }
`;

function Classes(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [periods, setPeriods] = useState([]);
    const [filter, setFilter] = useState({
        date: new Date()
    });
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    useEffect(() => {
        const fetchPeriods = async () => {
            const formattedDate = moment(filter.date).format('YYYY-MM-DD');
            const response = await dispatch(getPeriods({ date: formattedDate }));
            setPeriods(response.payload.rows || []);
        };
        fetchPeriods();
    }, [dispatch, filter.date]);

    const handlePrevDay = () => {
        setFilter(prev => ({
            ...prev,
            date: moment(prev.date).subtract(1, 'days').toDate()
        }));
    };

    const handleNextDay = () => {
        const nextDay = moment(filter.date).add(1, 'days');
        if (nextDay.isSameOrBefore(moment(), 'day')) {
            setFilter(prev => ({
                ...prev,
                date: nextDay.toDate()
            }));
        }
    };

    const handleDateChange = (date) => {
        if (moment(date).isSameOrBefore(moment(), 'day')) {
            setFilter(prev => ({
                ...prev,
                date: date
            }));
        }
        setIsDatePickerOpen(false);
    };

    return (
        <div className='bg-lightPrimary h-screen p-4'>
            <style>{datePickerStyles}</style>
            {/* Date Navigation */}
            <div className="flex justify-center mb-6">
                <div className="flex items-center gap-4 bg-gray-50 p-2 rounded-xl shadow-sm w-full max-w-2xl">
                    <button
                        onClick={handlePrevDay}
                        className="flex items-center justify-center w-12 h-12 rounded-lg bg-brand-500 shadow-md hover:bg-brand-600 transition-all duration-200 text-white"
                    >
                        <FiChevronLeft className="w-6 h-6" />
                    </button>
                    
                    <div className="flex-1 flex items-center gap-3 bg-white px-6 py-3 rounded-lg shadow-sm justify-center">
                        <button 
                            onClick={() => setIsDatePickerOpen(true)} 
                            className="flex items-center gap-3"
                        >
                            <FiCalendar className="w-5 h-5 text-brand-500" />
                            <span className="text-gray-700 font-medium text-lg">
                                {moment(filter.date).format('DD MMM YYYY')}
                            </span>
                        </button>
                        <DatePicker
                            selected={filter.date}
                            onChange={handleDateChange}
                            maxDate={new Date()}
                            open={isDatePickerOpen}
                            onClickOutside={() => setIsDatePickerOpen(false)}
                            customInput={<div style={{ display: 'none' }} />}
                            popperClassName="shadow-lg"
                            popperPlacement="bottom"
                            showPopperArrow={false}
                            dateFormat="dd MMM yyyy"
                            calendarClassName="shadow-xl"
                        />
                    </div>

                    <button
                        onClick={handleNextDay}
                        className={`flex items-center justify-center w-12 h-12 rounded-lg shadow-md transition-all duration-200 ${
                            moment(filter.date).isSame(moment(), 'day')
                                ? 'bg-gray-300 text-gray-400 cursor-not-allowed'
                                : 'bg-brand-500 text-white hover:bg-brand-600'
                        }`}
                        disabled={moment(filter.date).isSame(moment(), 'day')}
                    >
                        <FiChevronRight className="w-6 h-6" />
                    </button>
                </div>
            </div>

            {/* Add Attendance Button */}
            <div className="mb-4">
                <button
                    onClick={() => navigate('/parent/attendance/add')}
                    className="w-full bg-brand-500 text-white rounded-xl p-3 flex items-center justify-center gap-2 shadow-md hover:bg-brand-600 transition-all duration-200"
                >
                    <FiPlus className="w-5 h-5" />
                    <span>Add Attendance</span>
                </button>
            </div>

            {/* Periods List */}
            <div className='p-2 bg-white rounded-xl h-[74%] space-y-3'>
                {periods.length === 0 && (
                    <div className='flex flex-col items-center justify-center h-full text-gray-500'>
                        <p>No periods found for this date</p>
                    </div>
                )}
                {periods.map((period, index) => (
                    <Link
                        key={period.id}
                        to={`/parent/attendance/${period.id}`}
                        className='block'
                    >
                        <div className='bg-white border-2 border-brand-500 shadow-sm hover:shadow-md transition-all duration-200 rounded-xl p-4'>
                            <div className='flex items-center justify-between'>
                                <div className='flex-1'>
                                    <h3 className='text-lg font-medium text-gray-800'>
                                        {period.name}
                                    </h3>
                                    <div className='flex  justify-between items-center mt-1 w-[60%]'>
                                        <div>
                                            <p className='text-sm text-gray-500'>
                                                Total Present: {period?.attendances?.filter(a => a.status === 'PRESENT').length || 0}
                                            </p>
                                            <p className='text-sm text-gray-500'>
                                                Total Absent: {period?.attendances?.filter(a => a.status === 'ABSENT').length || 0}
                                            </p>
                                        </div>
                                        <div className='flex items-center  justify-between gap-2'>
                                            <div className='w-12 h-12 rounded-full bg-brand-50 flex items-center justify-center border-2 border-brand-200'>
                                                <p className='text-sm font-medium text-brand-500'>
                                                    {period?.attendances?.length > 0 
                                                        ? `${Math.round((period?.attendances?.filter(a => a.status === 'PRESENT').length / period?.attendances?.length) * 100)}%`
                                                        : '0%'
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <FiChevronRight className='w-5 h-5 text-brand-500' />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default Classes;
