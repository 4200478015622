import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { createHeaders } from './headers';
const apiUrl = process.env.REACT_APP_URL

export const getProfile = createAsyncThunk("profile/get", async () => {
    console.log("CALLED BRO")
    let response = await fetch(`${process.env.REACT_APP_URL}user/api/user`, {
        headers: createHeaders()
    });
    let json = await response.json();
    return json;
});

export const updateProfile = createAsyncThunk("profile/update", async (data) => {
    let response = await fetch(`${process.env.REACT_APP_URL}user/api/user`, {
        method: "PUT",
        headers: createHeaders(),
        body: JSON.stringify({
            ...data
        }),
    });
    let json = await response.json();
    return json;
});

export const requestOtp = createAsyncThunk("auth/login", async (data) => {
    let response = await fetch(`${apiUrl}user/open/auth/loginotp`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...data
        }),
    });
    let json = await response.json();
    return json
});

export const login = createAsyncThunk("auth/login", async (data) => {
    let response = await fetch(`${apiUrl}user/open/auth/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...data
        }),
    });
    let json = await response.json();
    return json
});

export const updateFCMtoken = createAsyncThunk("auth/fcmtoken", async (data) => {
    let response = await fetch(`${apiUrl}user/api/user/updatefcmtoken`, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify({
            ...data
        }),
    });
    let json = await response.json();
    return json
});

export const checkEntity = createAsyncThunk("auth/checkentity", async (data) => {
    let response = await fetch(`${apiUrl}user/open/auth/checkentity`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            ...data
        }),
    });
    let json = await response.json();
    return json
});

export const validateToken = createAsyncThunk("auth/validate", async (data) => {
    const token = localStorage.getItem("x_access_token")
    let response = await fetch(`${apiUrl}user/open/auth/validatetoken`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "x-access-token": token,
            "fcm-token": "fcmToken"
        }
    });
    let validation = await response.json();
    if (!validation.auth) {
        // thunkAPI.dispatch(updateToken(null));
        localStorage.setItem("x_access_token", null)
        window.location.href = "/auth/sign-in"
    } else {
        if (validation.user.role.name == "Parent" || validation.user.role.name == "Staff") {
            if (window.location.href.split("/")[3] == "admin") window.location.href = "/parent/home"
        } else {
            if (window.location.href.split("/")[3] == "parent") window.location.href = "/admin/default"
        }
    }
    return validation
});

export const sendUserVerificationLink = createAsyncThunk(
    'userVerification/sendUserVerificationLink',
    async (email) => { 
      try {
        const response = await fetch(`${apiUrl}admin/open/auth/sendemailverificationlink`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        console.log("api call res data :"+data)
        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  );

export const verifyUserVerificationToken = createAsyncThunk(
    'userVerification/verifyUserVerificationToken',
    async (token) => {
      try {
        const response = await fetch(`${apiUrl}admin/open/auth/verifyuserverificationtoken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
        const data = await response.json();
        console.log("api call res data :"+data)
        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  );

  export const signupWelcomeMail = createAsyncThunk("signup/welcomeMail", async (data) => {
    const response = await fetch(`${process.env.REACT_APP_URL}admin/open/auth/signup-welcome-email`, {
      method: "POST",
      headers: createHeaders(),
      body: JSON.stringify(data),
    });
    const json = await response.json();
    return json;
  });

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        value: {
            isLoggedIn: false,
            user: null,
            profile: {},
            status: "",
            access_token: null,
            permissions: null,
            activeStudent:{}
        }
    },
    reducers: {
        updateToken: (state, action) => {
            state.access_token = action.payload;
        },
        setActiveStudentRedux: (state, action) => {
            state.activeStudent = action.payload;
          },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.fulfilled, (state, action) => {

                state.value.status = action.payload.message
                if (action.payload.auth == true) {
                    state.value.isLoggedIn = true
                    state.value.user = action.payload.user
                    state.value.access_token = action.payload.token
                    localStorage.setItem("x_access_token", action.payload.token)
                } else {
                    state.value.isLoggedIn = false
                    state.value.user = null
                    state.value.access_token = null
                    localStorage.setItem("x_access_token", null)
                }
            })
            .addCase(validateToken.fulfilled, (state, action) => {

                state.value.user = action.payload.user
                state.value.user.code = action.payload.code

            })
            .addCase(getProfile.fulfilled, (state, action) => {

                state.value.profile = action.payload.user
            })
            .addCase(login.pending, (state) => {
                state.value.status = "Fetching"
            })
            .addCase(login.rejected, (state) => {
                state.status = "Failed to  fetch data..."
            })
            .addCase(checkEntity.fulfilled, (state, action) => {
                state.status = action.payload
            })
            .addCase(verifyUserVerificationToken.fulfilled, (state, action) => {
                state.status = action.payload
            })
            .addCase(sendUserVerificationLink.fulfilled, (state, action) => {
                state.status = action.payload
            })
    }
})

export const { updateToken } = authSlice.actions;
export const { setActiveStudentRedux } = authSlice.actions;



export default authSlice.reducer